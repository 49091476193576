/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import React from 'react';
import Top from './Top';
import Login from './Login';
import LoggedIn from './LoggedIn';
import { Router, Route, Switch } from 'react-router-dom';
import * as ROUTES from '../constants/routes';
import Firebase from '../providers/Firebase/firebase';
import FirebaseContext from '../providers/Firebase/context';
import AddEvent from './Events/AddEvent';
import { createBrowserHistory } from "history";
import FinishProfile from './FinishProfile';
import { ApiBackend } from '../providers/apibackend';
import { Profile } from '../model/Profile';
import EditEvent from './Events/EditEvent';
import Grid from '@material-ui/core/Grid/Grid';
import { Theme, createStyles, withStyles, Link, Paper, Typography } from '@material-ui/core';
import ManageCodes from './Events/ManageCodes';
import AddCode from './Events/AddCode';
import EditCode from './Events/EditCode';
import ManageOrders from './Orders/ManageOrders';
import ManageEntries from './Entries/Entry/ManageEntries';
import ManageProducts from './Products/ManageProducts';
import AddProduct from './Products/AddProduct';
import EditProduct from './Products/EditProduct';
import OrderScanner from './Orders/OrderScanner';
import ManageDelivery from './Orders/ManageDelivery';
import OrderOverview from './Orders/OrderOverview';
import ManageSettings from './ManageSettings';
import EntryOverview from './Entries/Entry/EntryOverview';
import Reservations from './Entries/Reservations';
import ManagePersons from './Entries/Person/ManagePersons';
import EditPerson from './Entries/Person/EditPerson';
import ManageFields from './Products/ManageFields';
import EditEntry from './Entries/Entry/EditEntry';
import moment from 'moment';
import ManageB2bCompanies from './B2b/Company/ManageB2bCompanies';
import EditB2bCompany from './B2b/Company/EditB2bCompany';
import CompanyEntryOverview from './B2b/Entries/CompanyEntryOverview';
import ManageCampaigns from './Campaigns/ManageCampaigns';
import EditCampaign from './Campaigns/EditCampaign';
import ManageClubs from './Events/ManageClubs';

export interface MainStateValue {
    firebase: Firebase;
    isSignedIn: boolean;
    profile: Profile;
    onUpdateProfile: (profile: Profile) => void;
}

interface State {
    value: MainStateValue;
    curView: string;
}

/** Main Page is the glue of everything
 * - Also keep the instance of firebase (Provider Context)
 * - Keep the route mapping to components
 */

const history = createBrowserHistory();
class MainPage extends React.Component<any, State> {

    constructor(props: any) {
        super(props);
 
        history.listen((location, action) => {
            // location is an object like window.location
            this.setState({ curView: location.pathname });
            //console.log(action, location.pathname, location.state);
        });
        this.state = {
            curView: history.location.pathname,
            value: {
                firebase: new Firebase(), isSignedIn: false, profile: null, onUpdateProfile: (profile: Profile) => {
                    this.setState({ value: { ...this.state.value, profile: profile } });
                }
            }
        };
        ApiBackend.initialize(this.state.value.firebase);

        this.state.value.firebase.auth().onAuthStateChanged((user) => {
            if (user) {

                let backend = new ApiBackend();
                backend.getProfile().then((profile) => {
                    if (profile == null || !profile.isComplete) {
                        this.setState({ value: { ...this.state.value, isSignedIn: user != null, profile: profile } });
                    }
                    else {
                        this.setState({ value: { ...this.state.value, isSignedIn: user != null, profile: profile } });
                    }
                });
            }
            else {
                this.setState({ value: { ...this.state.value, isSignedIn: user != null, profile: null } });
            }
        });
    }

    setProfile = (profile: Profile) => {
        this.setState({ value: { ...this.state.value, profile: profile } });
    }

    setView = (view: string) => () => {
        history.push(view);
    }



    render() {
        const { classes } = this.props;

        const offset = moment().utcOffset();
        const build = moment(BUILD).utcOffset(offset).format("YYYY-MM-DD HH:mm");
        const menu = [
            ["/", "Startsidan"],
            ["category", "Grunddata"],
            ["/products/manage", "Hantera produkter", "manageproducts"],
            ["/codes/manage", "Hantera rabattkoder", "managecodes"],
            ["/codes/customers", "Hantera kunder", "managecustomers"],
            ["/fields/manage", "Hantera anmälningsfält", "manageproducts"],
            ["/campaigns/manage", "Hantera kampanjer", "managecampaigns"],
            ["/sourcedata/clubs", "Hantera klubbar", "admin"],
            ["category", "Anmälningar"],
            ["/entries/overview", "Anmälningsöversikt", "manageentries"],
            ["/persons/manage", "Hantera personer", "manageentries"],
            ["/entries/manage", "Hantera anmälningar", "manageentries"],
            ["category", "Orderhantering"],
            ["/orders/manage", "Hantera orders", "manageorders"],
            ["/orders/overview", "Orderöversikt", "manageorders"],
            ["/orders/delivery", "Hantera utlämning", "manageorders"],
            ["/orders/scanner", "QR Scanner", "qrscan"],
            ["category", "Företag"],
            ["/companies/manage", "Hantera företag", "manageb2b"],
            ["/companies/entries/overview", "Anmälningsöversikt", "manageb2b"],
            ["category", "Inställningar"],
            ["/users/manage", "Inställningar","admin"]
        ];

        return <FirebaseContext.Provider value={this.state.value}>
            <Router history={history}>
                <Top />
                {this.state.value.isSignedIn ?
                    this.state.value.profile.isComplete ?
                        <Grid container className={classes.root} spacing={2} style={{ marginTop:5,
                            margin: 0,
                            width: '100%',
                          }}>
                            <Grid item xs={3}>
                                <Paper elevation={3} style={{ background: '#2772b6', paddingTop: 10, paddingBottom: 10, paddingRight: 10 }}>
                                    {menu.map((val, idx) => {
                                        if (val[0] == "category")
                                        {
                                            return <div key={idx} style={{color:'#fff',borderBottom:'1px solid #aaa', fontSize:12, paddingLeft:15, marginTop:10}}>{val[1]}</div>
                                        }
                                        else if (val.length == 2 || this.state.value.profile.isInRole(val[2]))
                                        {
                                            if (this.state.curView == val[0]) {
                                                return <div key={idx} className={"menuOpt active"}><Link onClick={this.setView(val[0])}>{val[1]}</Link></div>;
                                            }
                                            else if (val[0] != "/" && this.state.curView.substr(0,val[0].length) == val[0]) {
                                                return <div key={idx} className={"menuOpt active"}><Link onClick={this.setView(val[0])}>{val[1]}</Link></div>;
                                            }
                                            else {
                                                return <div key={idx} className={"menuOpt"}>
                                                    <Link color="textPrimary" onClick={this.setView(val[0])}>{val[1]}</Link>
                                                </div>;
                                            }
                                        }
                                    })}
                                </Paper>
                                <Typography variant="body2">Bygge: {build}</Typography>
                            </Grid>
                            <Grid item xs={9}>

                                <div style={{}}>
                                    <Switch>
                                        {this.state.value.profile.isInRole('manageentries') ? <Route exact path="/persons/manage"><ManagePersons /></Route> : null}
                                        {this.state.value.profile.isInRole('manageentries') ? <Route exact path="/persons/manage/:id"><EditPerson /></Route> : null}
                                        {this.state.value.profile.isInRole('manageentries') ? <Route exact path="/entries/manage"><ManageEntries /></Route> : null}
                                        {this.state.value.profile.isInRole('manageentries') ? <Route exact path="/entries/manage/:id"><EditEntry /></Route> : null}
                                        {this.state.value.profile.isInRole('manageentries') ? <Route exact path="/entries/overview"><EntryOverview /></Route> : null}
                                        {this.state.value.profile.isInRole('manageentries') ? <Route exact path="/entries/overview/:id"><EntryOverview /></Route> : null}
                                        {this.state.value.profile.isInRole('manageentries') ? <Route exact path="/entries/reservations"><Reservations /></Route> : null}
                                        {this.state.value.profile.isInRole('admin') ? <Route exact path="/sourcedata/clubs"><ManageClubs /></Route> : null}
                                        {this.state.value.profile.isInRole('manageproducts') ?<Route exact path="/fields/manage"><ManageFields /></Route> : null}
                                        {this.state.value.profile.isInRole('manageproducts') ?<Route exact path="/products/manage"><ManageProducts /></Route> : null}
                                        {this.state.value.profile.isInRole('manageproducts') ?<Route exact path="/products/manage/:id"><EditProduct /></Route> : null}
                                        {this.state.value.profile.isInRole('manageproducts') ?<Route exact path="/products/create"><AddProduct /></Route> : null}
                                        {this.state.value.profile.isInRole('manageorders') ?<Route exact path="/orders/manage"><ManageOrders /></Route> :null}
                                        {this.state.value.profile.isInRole('manageorders') ?<Route exact path="/orders/overview"><OrderOverview /></Route> :null}
                                        {this.state.value.profile.isInRole('manageorders') ?<Route exact path="/orders/delivery"><ManageDelivery /></Route> :null}
                                        {this.state.value.profile.isInRole('managecampaigns') ?<Route exact path="/campaigns/manage"><ManageCampaigns /></Route> :null}
                                        {this.state.value.profile.isInRole('managecampaigns') ?<Route exact path="/campaigns/manage/:id"><EditCampaign /></Route> :null}
                                        {this.state.value.profile.isInRole('managecampaigns') ?<Route exact path="/campaigns/create"><EditCampaign /></Route> :null}
                                        {this.state.value.profile.isInRole('qrscan') ?<Route exact path="/orders/scanner"><OrderScanner /></Route> : null}
                                        {this.state.value.profile.isInRole('managecodes') ?<Route exact path="/codes/manage"><ManageCodes /></Route> : null}
                                        {this.state.value.profile.isInRole('managecodes') ?<Route exact path="/codes/manage/:id"><EditCode /></Route> : null}
                                        {this.state.value.profile.isInRole('managecodes') ?<Route exact path="/codes/create"><AddCode /></Route> : null}
                                        {this.state.value.profile.isInRole('manageevents') ?<Route exact path="/events/manage/:id"><EditEvent /></Route> : null}
                                        {this.state.value.profile.isInRole('manageevents') ?<Route exact path="/events/create"><AddEvent /></Route> : null}
                                        {this.state.value.profile.isInRole('manageb2b') ?<Route exact path="/companies/manage"><ManageB2bCompanies /></Route> : null}
                                        {this.state.value.profile.isInRole('manageb2b') ?<Route exact path="/companies/manage/:id"><EditB2bCompany /></Route> : null}
                                        {this.state.value.profile.isInRole('manageb2b') ?<Route exact path="/companies/entries/overview"><CompanyEntryOverview /></Route> : null}
                                        {this.state.value.profile.isInRole('admin') ?<Route exact path="/users/manage"><ManageSettings /></Route> : null}
                                        <Route path="/profile"><FinishProfile /></Route>
                                        <Route path="/"><LoggedIn /></Route>
                                    </Switch>
                                </div>
                            </Grid></Grid> :
                        <Route path="/"><FinishProfile /></Route>
                    :
                    <Route path={ROUTES.LANDING} component={Login} />
                }



            </Router></FirebaseContext.Provider>;
    }
}

const useStyles = ({ palette, spacing }: Theme) =>
    createStyles({
        cardHeader: {
            background: palette.secondary.main,
            color: palette.secondary.contrastText,
            padding: 3
        },
        root: {
            flexGrow: 1
        }
    });
export default withStyles(useStyles)(MainPage);