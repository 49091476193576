import { Card, CardContent, CardHeader, createStyles, Grid, LinearProgress, Theme, Typography, WithStyles, withStyles } from "@material-ui/core";
import { ColDef, DataGrid, ValueGetterParams } from "@material-ui/data-grid";
import React from "react";
import { B2bCompany, B2bCompanyProduct } from "../../../model/B2bCompany";
import { CompanyEntry } from "../../../model/Entry";
import { Product } from "../../../model/Product";
import { ApiBackend } from "../../../providers/apibackend";

interface IState {
    loading: boolean;
    startDate: string;
    entries: CompanyEntry[];
    extraHeaders: { key: string; name: string; }[];
}

interface IProps {
    companyFilter: {company: B2bCompany; companyProduct: B2bCompanyProduct; } | undefined;
}

class CompanyEntriesForm extends React.Component<IProps & WithStyles, IState> {
    state: IState;

    private readonly api: ApiBackend;
    
    constructor(props: IProps & WithStyles) {
        super(props);

        this.state = { loading: false, startDate: "---", entries: [], extraHeaders: [] };
        this.api = new ApiBackend();
    }

    async componentDidUpdate(prevProps: IProps): Promise<void> {
        if (this.props.companyFilter !== prevProps.companyFilter) {
            await this.fetchEntries();
        }
    }

    render(): JSX.Element {
        if (!this.props.companyFilter) {
            return null;
        }

        const { startDate } = this.state;
        const { classes } = this.props;
        const { company, companyProduct } = this.props.companyFilter;
        const numRemaining = companyProduct.maxNumRegistrations ? Math.max(companyProduct.maxNumRegistrations - companyProduct.numRegistred, 0) : "---";

        return ( <Card>
            <CardHeader
                className={classes.cardHeader}
                title={
                    <Typography
                        variant="h5"
                        style={{ display: 'inline' }}>
                            Anmälningsöversikt {company.name} - {companyProduct.name}
                    </Typography>
                }
            />
        <CardContent>
            <Grid container spacing={2}>
                <Grid item xs={5}>
                    Maximalt antal deltagare:
                </Grid>
                <Grid item xs={7}>
                    <b>{companyProduct.maxNumRegistrations ?? "---"}</b>
                </Grid>
                <Grid item xs={5}>
                    Antal anmälda deltagare:
                </Grid>
                <Grid item xs={7}>
                    <b>{companyProduct.numRegistred ?? "---"}</b>
                </Grid>
                <Grid item xs={5}>
                    Platser kvar
                </Grid>
                <Grid item xs={7}>
                    <b>{numRemaining}</b>
                </Grid>
                <Grid item xs={5}>
                    Datum
                </Grid>
                <Grid item xs={7}>
                    <b>{startDate}</b>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {this.renderEntries()}
                </Grid>
            </Grid>
        </CardContent>
        </Card>);
    }

    private renderEntries = (): JSX.Element => {
        const { loading, entries, extraHeaders } = this.state;

        let columns: ColDef[] = [
            { field: 'firstName', headerName: 'Förnamn', width: 170 },
            { field: 'lastName', headerName: 'Efternamn', width: 170 },
            {
                field: 'sex', headerName: 'Kön', width: 170,
                valueGetter: (params: ValueGetterParams) => {
                    const entry = params.data as CompanyEntry;
                    return entry.sex === "M" ? "Man" : "Kvinna";
                },
                sortable: false
            },
            {
                field: 'startTime', headerName: 'Starttid', width: 170,
                valueGetter: (params: ValueGetterParams) => {
                    const entry = params.data as CompanyEntry;
                    return this.formatTime(entry.startTime);
                },
                sortable: false
            },
            { field: 'startGroupName', headerName: 'Startled', width: 170 },
            {
                field: '', headerName: 'Tillval', width: 250,
                valueGetter: (params: ValueGetterParams) => {
                    const entry = params.data as CompanyEntry;
                    if (!entry.addOns || entry.addOns.length === 0) {
                        return "---";
                    }

                    return entry.addOns.map(x => x.productVariantName).join(", ");
                },
                sortable: false
            }
        ];

        if (extraHeaders && extraHeaders.length > 0) {
            const cols: ColDef[] = extraHeaders.map(h => {
                return {
                    field: h.name,
                    headerName: h.name,
                    width: 120,
                    valueGetter: (params: ValueGetterParams) => {
                        const entry = params.data as CompanyEntry;
                        const value = entry.customValues.find(x => x.key === h.key);
                        return value?.value ?? "---";
                    },
                    sortable: false
                };
            });

            columns.splice(5, 0, ...cols);
        }

        return (
            <Card style={{ marginTop: 10 }}>
                <CardHeader 
                    title="Anmälningar"
                />
                <CardContent>
                    <div style={{ height: 500, width: '100%' }}>
                        {loading && 
                            <LinearProgress variant="indeterminate" color="secondary" />
                        }
                        <DataGrid
                            loading={this.state.loading}
                            rows={entries}
                            columns={columns}
                            pageSize={10}
                        />
                    </div>
                </CardContent>
            </Card>
        );
    }

    private fetchEntries = async() => {
        const { companyFilter } = this.props;

        if (!companyFilter) {
            return;
        }

        this.setState({ loading: true });

        const res = await this.api.listCompanyProductEntries(companyFilter.company.id, companyFilter.companyProduct.id);
        const sourceProduct = await this.api.getProduct(companyFilter.companyProduct.productId);
        const startDate = this.mapStartDate(sourceProduct, companyFilter.companyProduct);
        this.setState({ entries: res.entries, extraHeaders: res.customHeaders, loading: false, startDate: startDate });
    };

    private mapStartDate = (product: Product, companyProduct: B2bCompanyProduct): string => {
        if (!product.startGroups || product.startGroups.length === 0) {
            return "---";
        }

        if (!companyProduct.enforcedStartGroupId) {
            return this.formatDate(product.startGroups[0].startTime);
        }

        const match = product.startGroups.find(x => x.id === companyProduct.enforcedStartGroupId);
        if (!match) {
            return "---";
        }

        return this.formatDate(match.startTime);
    };

    private formatDate = (date: any) => {
        let d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    };

    private formatTime = (date: any) => {
        if (!date) {
            return "---";
        }

        let d = new Date(date),
            hours = "" + d.getHours(),
            minutes = "" + d.getMinutes();

        if (hours.length < 2) hours = "0" + hours;
        if (minutes.length < 2) minutes = "0" + minutes;

        return [hours, minutes].join(":");
    };
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    }
});

export default withStyles(useStyles)(CompanyEntriesForm);