/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

 import { Checkbox, FormControlLabel } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import { CustomField } from '../../model/CustomField';
 
 
 interface Props {
     onPropChanged : (prop: string, newValue : any) => void;
     item: CustomField;
 }
 
 
 
 /** Edit  -- Edits an existing a new event
  * - Also keep the instance of firebase (Provider Context)
  * - Keep the route mapping to components
  */
 class EditCustomField extends React.Component<Props, any> {
 
     render() {
         return <Grid container spacing={2}>
         <Grid item xs={12}>
             <TextField label="Namn" value={this.props.item.name} onChange={(ev) => {
                this.props.onPropChanged("name",ev.target.value);
             }} fullWidth/>
         </Grid>
         <Grid item xs={12}>
             <TextField label="Label" value={this.props.item.label} onChange={(ev) => {
                this.props.onPropChanged("label",ev.target.value);
             }} fullWidth/>
         </Grid>
         <Grid item xs={12}>
             <TextField label="Label ENG" value={this.props.item.label_en} onChange={(ev) => {
                this.props.onPropChanged("label_en",ev.target.value);
             }} fullWidth/>
         </Grid>
         <Grid item xs={12}>
             <TextField label="Beskrivning" value={this.props.item.description} onChange={(ev) => {
                this.props.onPropChanged("description",ev.target.value);
             }} fullWidth/>
         </Grid>
         <Grid item xs={12}>
             <TextField label="Beskrivning ENG" value={this.props.item.description_en} onChange={(ev) => {
                this.props.onPropChanged("description_en",ev.target.value);
             }} fullWidth/>
         </Grid>
         <Grid item xs={12}>
            <FormControlLabel label={"Hantera som klubbnamn"} control={<Checkbox value={this.props.item.handleAsClubName} onChange={(ev) => {
                this.props.onPropChanged('handleAsClubName',ev.target.checked);
            }}/>}/>
         </Grid>
         <Grid item xs={12}>
             <Select label="Typ" value={this.props.item.type} onChange={(ev) => {
               this.props.onPropChanged("type",ev.target.value as string);
             }} fullWidth>
                 <MenuItem value="text">Text</MenuItem>
                 <MenuItem value="number">Heltal</MenuItem>
                 <MenuItem value="ssn">Personnummer</MenuItem>
                 <MenuItem value="choice">Flerval</MenuItem>
             </Select>
         </Grid>
         {this.props.item.type=="choice" &&
            <Grid item xs={12}>
                 <TextField label="Giltiga värden (separera värden med ;)" value={this.props.item.validvalues ? this.props.item.validvalues.join(";") : ''} onChange={(ev) => {
                this.props.onPropChanged("validvalues",ev.target.value.split(";"));
             }} fullWidth/>
                </Grid>
            }
     </Grid>
     }
 }
 
 
 export default (EditCustomField);