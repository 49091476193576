/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import React, { Fragment } from 'react';
import { Theme, createStyles, Grid, WithStyles, Card, CardHeader, CardContent, TextField, Button, withStyles, CardActionArea, Table, TableContainer, TableRow, Paper, TableCell, TableBody, Dialog, DialogActions, DialogTitle, DialogContent, FormControl, InputLabel, Select } from '@material-ui/core';
import uuid from 'react-uuid';
import TableHead from '@material-ui/core/TableHead/TableHead';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Product } from '../../model/Product';
import moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { StartGroup } from '../../model/StartGroup';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

interface Props {
    product: Product;
    groupsChanged: () => void;
    editLocale: string;
}

interface State {
    haveChanges: boolean;
    addEditGroup: StartGroup;
    addEditStartGroupStartTime: string;
    addEditStartGroupStartTimeEnd: string;
    showDeleteConfirmation: StartGroup;
}

class StartGroups extends React.Component<WithStyles & Props, State> {

    state = {
        haveChanges: false,
        addEditGroup: null as StartGroup,
        showDeleteConfirmation: null as StartGroup,
        addEditStartGroupStartTime: null as string,
        addEditStartGroupStartTimeEnd: null as string
    };

    

    render() {
        let { classes } = this.props;
        return <Fragment><Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
                <Card>
                    <CardHeader className={classes.cardHeader} title="Tillgängliga startled" />
                    <CardContent>
                        <TableContainer component={Paper}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Namn</StyledTableCell>
                                        <StyledTableCell>Beskrivning</StyledTableCell>
                                        <StyledTableCell>Max antal deltagare</StyledTableCell>
                                        <StyledTableCell>Antal säljbara platser</StyledTableCell>
                                        <StyledTableCell>Startledstilldelning</StyledTableCell>
                                        <StyledTableCell>Öppen för anmälan</StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.props.product.startGroups && this.props.product.startGroups.map((group, idx) => {
                                        return <TableRow>
                                            <StyledTableCell>{this.props.editLocale == "EN" ? group.name : group.name}</StyledTableCell>
                                            <StyledTableCell>{this.props.editLocale == "EN" ? group.description : group.description}</StyledTableCell>
                                            <StyledTableCell>{group.maxParticipants}</StyledTableCell>
                                            <StyledTableCell>{group.numberOfSellablePlaces}</StyledTableCell>
                                            <StyledTableCell>{this.getStartGroupAllocationType(group)}</StyledTableCell>
                                            <StyledTableCell><Checkbox checked={group.openForEntry} disabled /></StyledTableCell>
                                            <StyledTableCell align="right">
                                            {idx > 0 &&
                                                <ExpandLess style={{ cursor: 'pointer' }} onClick={this.handleMoveUp(idx)} />
                                            }
                                            {idx < this.props.product.startGroups.length - 1 &&
                                                <ExpandMore style={{ cursor: 'pointer' }} onClick={this.handleMoveDown(idx)} />
                                            }
                                                <EditIcon onClick={this.editGroup(group)} className={classes.icon} style={{ cursor: 'pointer' }} />
                                                <DeleteIcon onClick={this.removeGroup(group)} className={classes.icon} style={{ cursor: 'pointer' }} />
                                            </StyledTableCell>
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table></TableContainer>

                        {this.state && this.state.addEditGroup &&
                            this.renderEditVariantDialog()
                        }
                    </CardContent>
                    <CardActionArea>
                        <Button onClick={this.addGroup}>Lägg till startled</Button>
                    </CardActionArea>
                </Card>
            </Grid>
        </Grid>
            {this.state && this.state.showDeleteConfirmation &&
                this.renderConfirmationDialog()
            }
        </Fragment>
    }

    private getStartGroupAllocationType = (group: StartGroup) => {
        switch (group.startGroupAllocationMethod)
        {
            case "seeded":
                return "Seedad tilldelning";
            case "userchoice":
                return "Startledsväljaren";
            case "extern":
                return "Extern tilldelning";
            case "none":
                return "Ingen tilldelning";
            default:
                return "Framifrån vid anmälan";
        }
    }

    private handleMoveUp = (idx: number) => (): void => {
        const { product, groupsChanged } = this.props;

        if (idx > 0) {
            let startGroups = product.startGroups;
            const rem = startGroups.splice(idx, 1);
            startGroups.splice(idx - 1, 0, rem[0]);
            groupsChanged();
        }
    };

    private handleMoveDown = (idx: number) => (): void => {
        const { product, groupsChanged } = this.props;

        if (idx < product.startGroups.length - 1) {
            let startGroups = product.startGroups;
            const rem = startGroups.splice(idx, 1);
            startGroups.splice(idx + 1, 0, rem[0]);
            groupsChanged();
        }
    };

    removeGroup = (group: StartGroup) => () => {
        this.setState({
            showDeleteConfirmation: group
        });
    }

    editGroup = (group: StartGroup) => () => {
        this.setState({
            addEditGroup: group,
            addEditStartGroupStartTime: moment(group.startTime).startOf('minute').format("YYYY-MM-DD HH:mm"),
            addEditStartGroupStartTimeEnd: group.startTimeEnd ? moment(group.startTimeEnd).startOf('minute').format("YYYY-MM-DD HH:mm") : ""
        });
    }

    addGroup = () => {
        let g = {
            name: 'Nytt startled',
            description: '',
            startTime: new Date(),
            bibNumberFrom: 1,
            bibNumberTo: 1000,
            openForEntry: true
        } as StartGroup;
        this.setState({
            addEditGroup: g,
            addEditStartGroupStartTime: moment(g.startTime).startOf('minute').format("YYYY-MM-DD HH:mm"),
            addEditStartGroupStartTimeEnd: ""
        });
    }

    renderEditVariantDialog() {
        let { classes } = this.props;
        return <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">{this.state.addEditGroup ? "Lägg till startled" : "Editera startled"}</DialogTitle>
            <DialogContent>
                <Grid container className={classes.root} spacing={2} style={{ width: '100%', padding: 3 }}>
                    <Grid item xs={10}>
                        <TextField value={this.props.editLocale == "EN" ? this.state.addEditGroup.name : this.state.addEditGroup.name} label="Namn" onChange={this.changeEditField(this.props.editLocale == "EN" ? 'name' : 'name')} fullWidth />
                    </Grid>
                    <Grid item xs={2}>
                    <TextField value={this.state.addEditGroup.externalKey} label="SportsProGroupKey" onChange={this.changeEditField('externalKey')} fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField value={this.props.editLocale == "EN" ? this.state.addEditGroup.description : this.state.addEditGroup.description} label="Beskrivning" onChange={this.changeEditField(this.props.editLocale == "EN" ? 'description' : 'description')} fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField type="numeric" value={this.state.addEditGroup.maxParticipants} label="Max deltagare" onChange={this.changeEditField('maxParticipants')} fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField type="numeric" value={this.state.addEditGroup.numberOfSellablePlaces} label="Säljbara platser" onChange={this.changeEditField('numberOfSellablePlaces')} fullWidth />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl className={classes.formControl} style={{ margin: 0 }}>
                            <InputLabel shrink>
                                Startledstilldelning
                            </InputLabel>
                            <Select native value={this.state.addEditGroup.startGroupAllocationMethod ?? ''} onChange={this.changeEditField('startGroupAllocationMethod')} label="Startledstilldelning">
                            <option value=''></option>
                                <option value='default'>Framifrån vid anmälan</option>
                                <option value='seeded'>Seedad tilldelning</option>
                                <option value='userchoice'>Startledsväljaren</option>
                                <option value='extern'>Extern tilldelning</option>
                                <option value='none'>Ingen tilldelning</option>
                                
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={9}>
                        <FormControlLabel label="Fast starttid" control={<Checkbox checked={this.state.addEditGroup.startTimeType != "interval"} onChange={(ev) => {
                            let s = this.state.addEditGroup;
                            if (ev.target.checked) {
                                s.startTimeType = "fixed";
                            }
                            this.setState({ addEditGroup: s, haveChanges: true });
                        }} />} />
                        <FormControlLabel label="Start inom intervall" control={<Checkbox checked={this.state.addEditGroup.startTimeType == "interval"} onChange={(ev) => {
                            let s = this.state.addEditGroup;
                            if (ev.target.checked) {
                                s.startTimeType = "interval";
                            }
                            this.setState({ addEditGroup: s, haveChanges: true });
                        }} />} />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label="Starttid"
                            type="datetime-local"
                            value={this.state.addEditStartGroupStartTime}
                            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                const startTime = moment(ev.target.value).startOf('minute').format("YYYY-MM-DD HH:mm")
                                this.setState({
                                    addEditStartGroupStartTime: startTime
                                });
                            }}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        {this.state.addEditGroup.startTimeType == "interval" &&
                            <TextField
                                label="Starttid till"
                                type="datetime-local"
                                value={this.state.addEditStartGroupStartTimeEnd}
                                onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                    const startTimeTill = moment(ev.target.value).startOf('minute').format("YYYY-MM-DD HH:mm")
                                    this.setState({
                                        addEditStartGroupStartTimeEnd: startTimeTill
                                    });
                                }}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                            />
                        }
                    </Grid>
                    <Grid item xs={4}>
                        <FormControlLabel control={<Checkbox checked={this.state.addEditGroup.openForEntry} onChange={this.checkChanged("openForEntry")} />} label="Öppen för anmälan" />
                    </Grid>
                    <Grid item xs={4}>
                        Startnummerserie herrar
                    </Grid>
                    <Grid item xs={2}>
                        <TextField value={this.state.addEditGroup.bibPrefix} label="Prefix" onChange={this.changeEditField('bibPrefix')} fullWidth />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField type="numeric" value={this.state.addEditGroup.bibNumberFrom} label="Från nummer" onChange={this.changeEditField('bibNumberFrom')} fullWidth />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField type="numeric" value={this.state.addEditGroup.bibNumberTo} label="Till nummer" onChange={this.changeEditField('bibNumberTo')} fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                        Startnummerserie damer
                    </Grid>
                    <Grid item xs={2}>
                        <TextField value={this.state.addEditGroup.bibPrefixWomen} label="Prefix" onChange={this.changeEditField('bibPrefixWomen')} fullWidth />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField type="numeric" value={this.state.addEditGroup.bibNumberFromWomen} label="Från nummer" onChange={this.changeEditField('bibNumberFromWomen')} fullWidth />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField type="numeric" value={this.state.addEditGroup.bibNumberToWomen} label="Till nummer" onChange={this.changeEditField('bibNumberToWomen')} fullWidth />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" autoFocus onClick={this.handleAddEditCancel} color="primary">
                    Avbryt
                </Button>
                <Button variant="contained" onClick={this.create} color="primary">
                    {this.state.addEditGroup && !this.state.addEditGroup.id ? "Lägg till" : "Spara"}
                </Button>
            </DialogActions>
        </Dialog>;
    }

    checkChanged = (field: string) => (ev: React.ChangeEvent<HTMLInputElement>) => {
        var o = this.state.addEditGroup;
        (o as any)[field] = ev.target.checked;

        this.setState({ addEditGroup: o });
    }

    changeEditField = (field: string) => (ev: React.ChangeEvent<HTMLInputElement>) => {
        var o = this.state.addEditGroup;
        let val = ev.target.value;
        console.log(val);
        (o as any)[field] = val;

        this.setState({ addEditGroup: o });
    }

    renderConfirmationDialog() {
        return <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">Är du säker på att du vill ta bort startledet?</DialogTitle>
            <DialogActions>
                <Button variant="contained" autoFocus onClick={this.handleDeleteCancel} color="primary">
                    Avbryt
                </Button>
                <Button variant="contained" onClick={this.handleDeleteOk} color="primary">
                    Ja, ta bort
                </Button>
            </DialogActions>
        </Dialog>;
    }

    create = () => {
        this.state.addEditGroup.startTime = moment(this.state.addEditStartGroupStartTime).toDate();
        if (this.state.addEditStartGroupStartTimeEnd) {
            this.state.addEditGroup.startTimeEnd = moment(this.state.addEditStartGroupStartTimeEnd).toDate();
        }

        if (!this.state.addEditGroup.id) {
            if (!this.props.product.startGroups) {
                this.props.product.startGroups = [];
            }
            this.state.addEditGroup.id = uuid();
            this.props.product.startGroups.push(this.state.addEditGroup);
        }
        this.props.groupsChanged();
        this.setState({ addEditGroup: null });
    }

    handleAddEditCancel = () => {
        this.setState({ addEditGroup: null });
    }

    handleDeleteCancel = () => {
        this.setState({ showDeleteConfirmation: null });
    }

    handleDeleteOk = () => {
        var e = this.props.product;
        e.startGroups = e.startGroups.filter(x => x.id != this.state.showDeleteConfirmation.id);
        this.setState({ showDeleteConfirmation: null });
        this.props.groupsChanged();
        this.forceUpdate();
    }
}

const tableHeadStyles = ({ palette }: Theme) => createStyles({
    head: {
        background: palette.primary.main,
        color: palette.primary.contrastText,
    }
});

const StyledTableCell = withStyles(tableHeadStyles)(TableCell);

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    icon: {
        verticalAlign: 'middle',
        cursor: 'pointer'
    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withStyles(useStyles)(StartGroups);
