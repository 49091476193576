/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import React, { ChangeEvent, Fragment } from 'react'
import FirebaseContext from '../../providers/Firebase/context';
import { Card, CardHeader, CardContent, Theme, withStyles, Button, createStyles, Grid, TableCell, Dialog, DialogTitle, DialogActions, LinearProgress, TextField, Typography, Input, InputLabel, Select, MenuItem, Chip } from '@material-ui/core';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { ApiBackend } from '../../providers/apibackend';
import { DataGrid, ColDef, ValueGetterParams, ValueFormatterParams } from '@material-ui/data-grid';
import moment from 'moment';
import RefreshIcon from '@material-ui/icons/Refresh';
import FormControl from '@material-ui/core/FormControl/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import { ProductDelivery, ProductToDeliver } from '../../model/ProductToDeliver';
import { Product } from '../../model/Product';
import Tooltip from '@material-ui/core/Tooltip';
import { PanoramaFishEyeSharp } from '@material-ui/icons';

interface State {
    loading : boolean;
    search:string;
    productsToDeliver: ProductToDeliver[];
    product: Product;
    productVariants : {value:string, name:string}[];
    selectedProductVariants: {value: string, name:string}[];
    showDelivered: boolean;
    products: Product[];
}

class ManageDelivery extends React.Component<RouteComponentProps, State> {
    constructor(props : RouteComponentProps)
    {
        super(props);
        this.state = {loading: true, productsToDeliver : null, search:"", product: null, productVariants: null, selectedProductVariants: null, showDelivered: false, products: null};
    }

    componentDidMount() {
        let back = new ApiBackend();
        this.setState({loading:true});
        back.listProducts().then((products : Product[]) => {
            this.setState({products : products, loading: false}, () => {
                this.setProduct(products[0].id);
            });
        });
    }

    setProduct(productId: string)
    {
        let back = new ApiBackend();
        this.setState({loading:true});
        back.getProduct(productId).then((product : Product) => {
            let variants : {value:string, name: string}[] = [];
            
                for (let i = 0; i < product.variants.length; i++)
                {
                    if (product.type == "skipass")
                    {
                        for (let pg = 0; pg < product.variants[i].priceGroups.length; pg++)
                        {
                            
                            variants.push({value: product.variants[i].priceGroups[pg].id, name: product.variants[i].Name + " - " +product.variants[i].priceGroups[pg].Name});
                        }
                    }
                    else 
                    {
                        variants.push({value:product.variants[i].Id,name: product.variants[i].Name});
                        
                    }
                }
        // let variants = product.type=="skipass" ? product.variants[0].priceGroups.map(x=>{ return {value: x.id, name:x.Name};}) : product.variants.map(x=>{return {value: x.Id, name: x.Name};});
            this.setState({product: product, productVariants: variants, selectedProductVariants: variants.map(x=>x),loading:false},() =>
            {this.refresh();}
            );
        });
    }

    refresh = () => {
        let back = new ApiBackend();
        this.setState({loading:true});
        back.listProductsToDeliver(this.state.search, this.state.product.id, this.state.selectedProductVariants.map(x=>x.value), this.state.showDelivered).then((orders) => {
            this.setState({ productsToDeliver: orders, loading:false});
        });
    }

    changeValProduct = (ev: React.ChangeEvent<HTMLSelectElement>)=>{
        this.setProduct(ev.target.value);

    }
    changeValProductIds = (ev : React.ChangeEvent<{value: {name: string ,value: string}[]}>) =>{
        this.setState({selectedProductVariants: ev.target.value}, () => {
            this.refresh();
        });
    }

    onSearchChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({search: ev.target.value}, () => {
            this.refresh();
        });
    }

    deliverProduct = (orderId: string, productId : string , productVariantId: string, productVariantPriceGroupId: string) => async () => {
        let back = new ApiBackend();
        let res = await back.deliverProduct(orderId,productId,productVariantId,productVariantPriceGroupId,1);
        if (!res)
        {
            alert("Kunde inte leverera product!");
        }

        this.refresh();
    }

    onCheckChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({showDelivered: ev.target.checked}, () => {
            this.refresh();
        });
    }

    render() {
        let { classes } = this.props as any;
        const columns: ColDef[] = [
            {
                field: 'orderId',
                headerName: ' ',
                width: 130,
                renderCell: (params : ValueFormatterParams) => { 
                  return <Button variant="contained" onClick={this.deliverProduct(params.data.id.toString(), params.data.productId,params.data.productVariantId, params.data.productVariantPriceGroupId)} disabled={params.data.numberDelivered-params.data.numberOrdered == 0}>Lämna ut</Button>}
            },
            { field: 'orderId', headerName: 'Orderid', width: 170},
            { field: 'numberOrdered', headerName: 'Antal beställda', width: 70},
            { field: 'numberDelivered', headerName: 'Utlämnade', width: 70, renderCell: (params: ValueFormatterParams) => {
                return params.data.numberDelivered > 0 ?  <Tooltip
                title={
                      <table>
                      {params.data.deliveries && params.data.deliveries.map((del: ProductDelivery)=> {
                          return <tr><td><Typography variant="body2">{moment(del.deliveredWhen).format("YYYY-MM-DD HH:mm:ss")} ({del.deliveredBy}):</Typography></td><td><Typography variant="body2">{del.numDelivered}st</Typography></td></tr>;
                      })}
                    </table>
                }
              ><span>{params.data.numberDelivered}</span></Tooltip> : <Typography>0</Typography>;
            }},
            { field: 'productName', headerName: 'Produkt', width: 170},
            { field: 'productVariantName', headerName: 'Variant', width: 170},
            { field: 'name', headerName: 'Namn beställare', width: 170},
            { field: 'orderDate', type:"datetime", headerName: 'Orderdatum', width: 170, valueGetter: (params) => {return moment(params.data.orderDate).format("YYYY-MM-DD HH:mm")} },
            {
              field: 'Delivered',
              headerName: 'Utlämnad',
              width: 130,
              renderCell: (params : ValueFormatterParams) => { 
                return <Checkbox disabled={true} checked={params.data.numberDelivered-params.data.numberOrdered == 0}/>}
            }
          ];


        return <Fragment><Grid container className={classes.root} spacing={2}>
             <Grid item xs={12}>
             {this.state.loading &&
                        <LinearProgress color="secondary"/>
            }      
             <Card>
                <CardHeader className={classes.cardHeader} title={<Fragment><Typography variant="h5" style={{display:'inline'}}>Filtrera </Typography></Fragment>} />
                <CardContent>
                {this.state && <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={3}>
                        <FormControl>
                            <InputLabel htmlFor="fromDate">Produkt</InputLabel>
                            
                            <Select native value={this.state.product ? this.state.product.id : null} onChange={this.changeValProduct}>
                                {this.state.products && this.state.products.map((product) => {
                                    return <option value={product.id}>{product.name}</option>;
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                    <FormControl>
                            <InputLabel htmlFor="fromDate">Produktvariant</InputLabel>
                            {this.state.product && <Select style={{maxWidth:300}} multiple={true}  onChange={this.changeValProductIds}
                            value={this.state.selectedProductVariants}
                            renderValue={(selected) => (
                                <div className={classes.chips}>
                                {(selected as {name : string, value: string}[]).map((value) => (
                                    <Chip key={value.value} label={value.name} className={classes.chip}/>
                                ))}
                                </div>
                            )}>
                                {this.state.productVariants.map((s) => {
                                    return <MenuItem value={s as any} style={{fontWeight : this.state.selectedProductVariants.filter(x=>x.value == s.value).length > 0 ? 'bold' : 'normal'}}>{s.name}</MenuItem>;
                                })}
                            </Select>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl>
                            <InputLabel htmlFor="my-input">Visa utdelade</InputLabel>
                            <Checkbox checked={this.state.showDelivered} onChange={this.onCheckChange}/>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField autoComplete="off" style={{ flex: 1, margin: '0 3px' }} id="standard-basic" label="Namn / OrderId" value={this.state.search} onChange={this.onSearchChange} fullWidth />
                    </Grid>
                </Grid>}
                </CardContent>
             </Card>
             </Grid>
            
            <Grid item xs={12}>
                
                <Card>

                    <CardHeader className={classes.cardHeader} title={<Fragment><Typography variant="h5" style={{display:'inline'}}>Beställningar </Typography>
                    <RefreshIcon style={{display:'inline', verticalAlign:'middle', cursor:'pointer'}} onClick={this.refresh}/></Fragment>} />
                    <CardContent>
                         <div style={{ height: 400, width: '100%' }}>
                        
                         <DataGrid loading={this.state.loading} rows={ this.state.productsToDeliver ?? []} columns={columns} pageSize={10} />
                        
                        </div>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
        </Fragment>;
    }

}



const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
}
);
export default withRouter(withStyles(useStyles)(ManageDelivery));