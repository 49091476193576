import { Card, CardHeader, IconButton } from "@material-ui/core";
import { FormikProps } from "formik";
import moment from "moment";
import React from "react";
import { B2bCompany, B2bCompanyProduct } from "../../../model/B2bCompany";
import FormCbxField from "../../Common/FormCbxField";
import EditB2bCompanyProductDialog from "./EditB2bCompanyProductDialog";
import EditIcon from '@material-ui/icons/Edit';
import { Product } from "../../../model/Product";

interface IProps extends FormikProps<B2bCompany> {
    idx: number;
    product: B2bCompanyProduct;
    sourceProduct: Product;
    onDelete: (idx: number) => void;
    onSave: (product: B2bCompanyProduct) => void;
}

interface IState {
    editProduct: B2bCompanyProduct;
}

class EditB2bCompanyProduct extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            editProduct: null
        };
    }

    render(): JSX.Element {
        const { product, idx } = this.props;

        return (
            <Card style={{ marginTop: 10 }}>
                <CardHeader
                    title={this.cardTitle()}
                    subheader={this.cardSubHeader()}
                    action={<>
                        <FormCbxField {...this.props}
                            propName={`products[${idx}].isActive`}
                            label="Aktiv"
                            valueGetter={() => product.isActive} />
                        <IconButton
                            onClick={() => this.editProduct(product)}>
                            <EditIcon />
                        </IconButton>
                    </>}
                />
                {this.state.editProduct && <div>
                    <EditB2bCompanyProductDialog {...this.props}
                        productIdx={this.props.idx}
                        product={this.state.editProduct}
                        startGroups={this.props.sourceProduct.startGroups}
                        onSave={(product: B2bCompanyProduct) => {
                            this.props.onSave(product);
                            this.resetEditProduct();
                        }}
                        onClose={() => {
                            this.resetEditProduct();
                        }}
                        onDelete={() => {
                            this.props.onDelete(idx);
                            this.resetEditProduct();
                        }}
                    />
                </div>
                }
            </Card>
        )
    }

    private editProduct = (product: B2bCompanyProduct): void => {
        this.setState({
            editProduct: product
        });
    }

    private resetEditProduct = (): void => {
        this.setState({
            editProduct: null
        });
    }

    private cardTitle = (): string => {
        const product = this.props.product;
        let desc = product.name ? product.name : "Produkt";

        if (product.description) {
            desc += " - ";
            desc += (product.description.length > 40) ? product.description.slice(0, 40 - 1) + '...' : product.description;
        }

        return desc;
    };

    private cardSubHeader = (): string => {
        const startGroups = this.props.sourceProduct.startGroups;

        const product = this.props.product;
        const sourceProduct = this.props.sourceProduct;
        const sourceProductVariant = sourceProduct.variants.find(x => x.Id === product.productVariantId);
        const sourceProductVariantPriceGroup = sourceProductVariant.priceGroups.find(x => x.id === product.productVariantPriceGroupId);

        const sourceProductName = sourceProduct.name;
        const sourceProductVariantName = sourceProductVariant.Name;
        const sourceProductVariantPriceGroupName = sourceProductVariantPriceGroup.Name;

        let desc = `produkt: ${sourceProductName}, variant: ${sourceProductVariantName}, prisgrupp: ${sourceProductVariantPriceGroupName}`;

        const append = (str: string) => {
            if (desc) {
                desc += ", ";
            }

            desc += str;
        };

        if (product.validFrom) {
            append(`från: ${this.formatDate(product.validFrom)}`);
        }

        if (product.validTo) {
            append(`till: ${this.formatDate(product.validTo)}`);
        }

        if (product.maxNumRegistrations) {
            append(`max-antal: ${product.maxNumRegistrations}`);
        }

        if (product.enforcedStartGroupId && startGroups) {
            const startGroup = startGroups.find(x => x.id === product.enforcedStartGroupId);
            if (startGroup) {
                append(`startgrupp: ${startGroups.find(x => x.id === product.enforcedStartGroupId).name}`);
            }
        }

        if (product.extraFields && product.extraFields.length) {
            append(`extra-fält: ${product.extraFields.length} st`);
        }

        return desc;
    };

    private formatDate = (dateStr: string) => {
        return dateStr ? moment(dateStr).format("YYYY-MM-DD") : "";
    };
}

export default EditB2bCompanyProduct;
