/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import React from 'react';
import { withRouter, RouteChildrenProps } from 'react-router-dom';
import { Theme, createStyles, Grid, WithStyles, Card, CardHeader, CardContent, TextField, Button, withStyles, CardActionArea } from '@material-ui/core';
import moment from 'moment';
import { ApiBackend } from '../../providers/apibackend';
import uuid from 'react-uuid';
import { Code } from '../../model/Code';
import { CodeHolder } from '../../model/CodeHolder';
import { CodeProductImplementation } from '../../model/CodeProductImplementation';

interface State {
    code: CodeHolder;
}

/** AddEvent -- Creates a new event
 * - Also keep the instance of firebase (Provider Context)
 * - Keep the route mapping to components
 */
class AddCode extends React.Component<WithStyles & RouteChildrenProps, State> {

    constructor(props: any) {
        super(props);
        this.state = { code: { id: uuid(), name: '', ValidFrom : moment().toDate(), ValidTo: moment().toDate(), Codes: [] as Code[], 
        Implementations: [] as CodeProductImplementation[], Created: null, IsArchived:false } };
    }

    onChange = (prop: string) => (ev : React.ChangeEvent<HTMLInputElement>) => 
    {
        var e = this.state.code as any;
        e[prop] = ev.target.value;
        this.setState({code: e});
    }

    createCode = () => {
        let back = new ApiBackend();
        back.createOrUpdateCode(this.state.code).then((res) => {
            if (res)
            {
                this.props.history.push('/codes/manage');            
            }
            else 
            {
                alert("Kunde inte skapa kod!");
            }
        });
    }

    render() {
        let { classes } = this.props;
        return <div>
            <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader className={classes.cardHeader} title="Skapa ny kod" />
                        <CardContent>
                            <form className={classes.form} noValidate autoComplete="off">
                                <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                                    <Grid item xs={12}>
                                        <TextField style={{ flex: 1, margin: '0 3px' }} id="standard-basic" label="Namn" value={this.state.code.name} onChange={this.onChange('name')} fullWidth />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField type="date" style={{ flex: 1, margin: '0 3px' }} id="standard-basic" value={moment(this.state.code.ValidFrom).format("YYYY-MM-DD")} onChange={this.onChange('ValidFrom')} label="Giltig från" fullWidth />
                                    </Grid>
                                    <Grid item xs={12} sm={6} >
                                        <TextField type="date" style={{ flex: 1, margin: '0 3px' }} id="standard-basic" value={moment(this.state.code.ValidTo).format("YYYY-MM-DD")} onChange={this.onChange('ValidTo')} label="Giltig till" fullWidth />
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                        <CardActionArea>
                            <Button onClick={this.createCode}>Skapa kod</Button>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid></div>;
    }
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
}
);


export default withStyles(useStyles)(withRouter(AddCode));