import { Box, Button, Card, CardContent, CardHeader, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress, TextField, Theme, Typography, withStyles, WithStyles } from '@material-ui/core';
import React, { Fragment } from 'react';
import { Order } from '../../model/Order';
import { ApiBackend } from '../../providers/apibackend';
import GetAppIcon from '@material-ui/icons/GetApp';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import Check from '@material-ui/icons/CheckCircle';
import Failed from '@material-ui/icons/Cancel';
import Modal from '@material-ui/core/Modal/Modal';

import moment from 'moment';
import EmailIcon from '@material-ui/icons/Email';
import { Payment } from '../../model/Payment';
import AddPaymentDialog from './AddPaymentDialog';
import ManageOrderContact from './ManageOrderContact';
import OrderMessages from './OrderMessages';
import ManageOrderItems from './ManageOrderItems';

interface Props {
    orderId : string;
    close: () => void;
}
interface State {
    order: Order;
    haveChanges : boolean;
    saving: boolean;
    payments: Payment[];
    isLoadingPaymentHistory: boolean;
    addPayment : boolean;
}

class ManageOrder extends React.Component<Props & WithStyles,State>
{
    constructor(props: Props & WithStyles) {
        super(props);
        this.state = {order: null, haveChanges:false, saving : false,
             payments:null, isLoadingPaymentHistory:true, addPayment:false};
    }

    handleClose = () => {
        this.props.close();
    }


    componentDidMount(){
        this.updateData();
    }

    updateData() {
        let backend = new ApiBackend();
        backend.retreiveOrder(this.props.orderId).then((order) => {
            this.setState({order : order});
        });
      
        backend.getOrderPaymentHistory(this.props.orderId).then((payments) => {
            this.setState({payments : payments, isLoadingPaymentHistory:false});
        });
    }

    downloadPdf = () => {
        window.location.href=ApiBackend.getPdfReceiptUrl(this.props.orderId);
    }
    sendOrderConfirmation = () => {
        let backend = new ApiBackend();
        backend.resendOrderconfirmation(this.props.orderId).then(() => {
            alert("Orderbekräftelse skickad till kunden");
            this.updateData();
        });        
    }

   
    render() {
            let {classes} = this.props;
            let o = this.state.order;
            return <Dialog disableEnforceFocus
            maxWidth="lg"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            
                <DialogTitle id="confirmation-dialog-title">{this.state.order == null ? "Laddar" : "Order: " + o.publicId}</DialogTitle>
                <DialogContent dividers>
                    {this.state.saving ? <Modal
                        open={true}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >
                        <CircularProgress color="primary" />
                    </Modal> : null}
                    {this.state.order == null ? <LinearProgress color="primary" /> :
                        <Grid container spacing={2}>
                            <Grid item xs={2}>OrderId</Grid>
                            <Grid item xs={10}><b>{o.publicId}</b> / {o.id}</Grid>
                            <Grid item xs={2}>Skapad</Grid>
                            <Grid item xs={10}><b>{moment(o.OrderDate).format("YYYY-MM-DD HH:mm:ss")}</b></Grid>
                            <Grid item xs={2}>Uppdaterad</Grid>
                            <Grid item xs={10}><b>{moment(o.LastUpdate).format("YYYY-MM-DD HH:mm:ss")}</b></Grid>
                            <Grid item xs={2}>Status</Grid>
                            <Grid item xs={10}><b>{Order.translateStatus(o.Status)}</b></Grid>
                    <Grid item xs={12}>
                        <Button onClick={this.downloadPdf}><GetAppIcon/> Ladda ner orderbekräftelse (PDF)</Button>
                        <Button onClick={this.sendOrderConfirmation}><EmailIcon/> Skicka orderbekräftelse via mail/sms</Button>
                    </Grid>
                        <Grid item xs={6}>
                            <ManageOrderContact order={this.state.order} dataChanged={()=>{this.updateData();}}/>
                        </Grid>
                        <Grid item xs={6}>
                            <OrderMessages order={this.state.order} orderId={this.state.order.id}/>
                        </Grid>
                        <Grid item xs={12}>
                            <ManageOrderItems order={this.state.order}/>
                        </Grid>
                        
                        <Grid item xs={12}>
                            <Card>
                                <CardHeader className={classes.cardHeader} title={<Fragment>Transaktioner&nbsp; <Button variant="contained" onClick={() => {this.setState({addPayment: true})}}>Lägg till betalning</Button></Fragment>} />
                                <CardContent>
                                {this.state.isLoadingPaymentHistory &&
                                    <CircularProgress color="secondary"/>
                                }
                                {this.state.payments &&
                                    <Grid container spacing={2} style={{fontSize:'0.9em'}}>
                                        <Grid item xs={2}><b>Datum</b></Grid>
                                        <Grid item xs={2}><b>Beskrivning</b></Grid>
                                        <Grid item xs={1}><b>Typ</b></Grid>
                                        <Grid item xs={1}><b>Betalmedel</b></Grid>
                                        <Grid item xs={1}><b>Belopp</b></Grid>
                                        <Grid item xs={2}><b>ID</b></Grid>
                                        <Grid item xs={2}><b>Betalkanal</b></Grid>
                                        <Grid item xs={1}><b>Status</b></Grid>
                                        {this.state.payments.map((item,idx) => {
                                            return <Fragment>
                                                <Grid item xs={2}>{moment(item.created).format("YYYY-MM-DD HH:mm:ss")}</Grid>
                                                <Grid item xs={2}>{item.description}</Grid>
                                                <Grid item xs={1}>{item.operation}</Grid>
                                                <Grid item xs={1}>{item.instrument}</Grid>
                                                <Grid item xs={1}>{item.amount}kr</Grid>
                                                <Grid item xs={2}>{item.id}</Grid>
                                                <Grid item xs={2}>{item.pointOfSale ? item.pointOfSale.name : ""}</Grid>
                                                <Grid item xs={1}>
                                                    {this.renderPaymentStatus(item.status)}
                                                </Grid>
                                            </Fragment>
                                        })}
                                    </Grid>
                                }
                                </CardContent>
                            </Card>
                        </Grid>
                </Grid>}

                {this.state.addPayment &&
                    this.renderAddPaymentDialog()
                }
            </DialogContent>
            <DialogActions>
                <Button variant="contained" autoFocus onClick={this.handleClose} color="primary">
                    Stäng
          </Button>
            </DialogActions>
        </Dialog>;
    }

    renderPaymentStatus(status: string)
    {
        if (status == "Completed")
        {
            return <Check style={{color:'darkgreen'}}/>;
        }
        else if (status== "Failed")
        {
            return <Failed style={{color:'darkred'}}/>;
        }
        else 
        {
            return <div>{status}</div>
        }
    }

    

    renderAddPaymentDialog() {
        return <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">Lägg till betalning</DialogTitle>
            <DialogContent>
                <AddPaymentDialog order={this.state.order} close={() => this.setState({addPayment:false},() => {this.updateData()})}/>
            </DialogContent>
        </Dialog>;
    }

}


const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
}
);

export default withStyles(useStyles)(ManageOrder);