import React from "react";
import { RouteChildrenProps, withRouter } from "react-router-dom";
import { Button, Card, CardActions, CardContent, CardHeader, CircularProgress, createStyles, Theme, withStyles, WithStyles } from "@material-ui/core";
import { B2bCompany } from "../../../model/B2bCompany";
import FirebaseContext from "../../../providers/Firebase/context";
import { ApiBackend } from "../../../providers/apibackend";
import EditB2bCompanyFormContent from "./EditB2bCompanyFormContent";
import { Formik } from "formik";
import DirtyPageChecker from "../../Common/DirtyPageChecker";
import editB2bCompanyValidationSchema from "./EditB2bCompanyValidationSchema";

interface IProps extends RouteChildrenProps<{ id: string }> {
}

interface IState {
    loading: boolean;
    company: B2bCompany;
}

class EditB2bCompany extends React.Component<IProps & WithStyles, IState> {
    static contextType = FirebaseContext;

    private readonly api: ApiBackend;

    constructor(props: IProps & WithStyles) {
        super(props);
        this.state = {
            company: null,
            loading: true
        };
        this.api = new ApiBackend();
    }

    async componentDidMount(): Promise<void> {
        await this.loadCompany();
    }

    render() {
        const { classes } = this.props;
        const { company, loading } = this.state;
        const errorText = "Ett okänt fel har uppstått - ändringar är inte sparade.";
        const cardHeader = (): string => {
            if (company.name) {
                return `Hantera ${company.name}`;
            }

            return `Hantera ${company.id}`
        };

        return (<>
            {loading &&
                <CircularProgress color="primary" />
            }
            {company &&
                <Formik
                    initialValues={company}
                    validationSchema={editB2bCompanyValidationSchema}
                    onReset={(values): void => this.setState({ company: values })}
                    onSubmit={async (values, { resetForm }) => {
                        await this.api.saveB2bCompany(values)
                        .then((response) => {
                            if (typeof response === "string") {
                                alert(`${errorText}\n\n${response}`);
                                console.dir(response);
                                return;
                            }

                            let company = response as B2bCompany;
                            if (!company) {
                                alert(`${errorText}\n\nOgiltigt företagsobjekt`);
                                console.dir(response);
                                return;
                            }

                            this.setState({
                                company: company
                            }, () => {
                                resetForm({
                                    values: company
                                });
                            });
                        })
                        .catch((err) => {
                            alert(`${errorText}\n\nFick felkod från server`);
                            console.error(err);
                        });
                    }}
                >
                    {formik => {
                        const { isValid, dirty, handleSubmit, handleReset, isSubmitting } = formik;
                        const isSaveDisabled = !dirty || !isValid || isSubmitting;

                        return (
                            <DirtyPageChecker isDirty={dirty} getTitle={this.getTitle} {...this.props}>
                                <form autoComplete="off" onSubmit={handleSubmit}>
                                    <Card>
                                        <CardHeader className={classes.cardHeader} title={cardHeader()} />
                                        <CardContent className={classes.form}>
                                            <EditB2bCompanyFormContent {...formik} classes={classes} ></EditB2bCompanyFormContent>
                                        </CardContent>
                                        <CardActions>
                                            <Button type="submit" color="secondary" disabled={isSaveDisabled} variant="contained">Spara</Button>
                                            <Button style={{ marginLeft: 10 }} color="secondary" disabled={!dirty} variant="contained" onClick={handleReset}>Avbryt</Button>
                                        </CardActions>
                                    </Card>
                                </form>
                            </DirtyPageChecker>
                        )
                    }}
                </Formik>
            }
        </>);
    }

    private loadCompany = async (): Promise<void> => {
        const company = await this.api.getB2bCompany(this.props.match.params.id);
        this.setState({
            loading: false,
            company: company
        });
    };

    private getTitle = (): string | undefined => {
        const { company } = this.state;

        if (company?.name) {
            return `Företag: ${company.name}`;
        }
    };
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withRouter(withStyles(useStyles)(EditB2bCompany));
