import React from "react";
import { Box, Button, Card, CardActions, CardContent, CardHeader, Grid, WithStyles } from "@material-ui/core";
import { FieldArray, FormikProps } from "formik";
import { B2bCompany } from "../../../model/B2bCompany";
import FormTextField from "../../Common/FormTextField";
import FormCbxField from "../../Common/FormCbxField";
import EditB2bCompanyAdmins from "./EditB2bCompanyAdmins";
import EditB2bCompanyProducts from "./EditB2bCompanyProducts";
import { createRandomCode } from "../../../utilities/codes";

interface IState {
    loading: boolean;
}

class EditB2bCompanyFormContent extends React.Component<WithStyles & FormikProps<B2bCompany>, IState> {
    constructor(props: WithStyles & FormikProps<B2bCompany>) {
        super(props);
        this.state = {
            loading: true
        };
    }

    render(): JSX.Element {
        const { classes, setFieldValue } = this.props;

        return (
            <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                <Grid item xs={11}>
                    <FormTextField {...this.props}
                        propName="name"
                        label="Namn"
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormCbxField {...this.props}
                        propName="isArchived"
                        label="Arkiverad"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormTextField {...this.props}
                        propName="description"
                        label="Beskrivning"
                    />
                </Grid>
                <Grid item xs={8}>
                    <FormTextField {...this.props}
                        propName="registrationLink"
                        label="Registreringslänk"
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormTextField {...this.props}
                        propName="code"
                        label="Kod"
                    />
                </Grid>
                <Grid item xs={1}>
                    <Button
                        variant="contained"
                        onClick={() => { setFieldValue("code", createRandomCode()) }}>
                        Skapa kod
                    </Button>
                </Grid>
                <Grid item xs={1}>
                    <FormCbxField {...this.props}
                        propName="codeIsActive"
                        label="Kod aktiv"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <FieldArray
                            name="admins"
                            render={arrayHelpers => (
                                <>
                                    <CardHeader
                                        className={classes.cardHeader}
                                        title="Hantera administratörer"
                                    />
                                    <CardContent>
                                        <EditB2bCompanyAdmins {...this.props} />
                                    </CardContent>
                                    <CardActions>
                                        <Box mb={1}>
                                            <Button
                                                variant="contained"
                                                onClick={() => { arrayHelpers.push({}) }}>
                                                Lägg till administratör
                                            </Button>
                                        </Box>
                                    </CardActions>
                                </>
                            )} />
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <EditB2bCompanyProducts {...this.props} />
                </Grid>
            </Grid>
        );
    }
}

export default EditB2bCompanyFormContent;
