/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import React from 'react'
import FirebaseContext from '../providers/Firebase/context';
import firebase from 'firebase/app';
import Firebase from '../providers/Firebase/firebase';
import firebaseui from 'firebaseui';
import { StyledFirebaseAuth } from 'react-firebaseui';

/**
 * The login-component handles the firebaseui setup and display of login-providers
 * Uses the FirebaseContext.Consumer to get access to the firebase instace setup in MainPage
 */
class Login extends React.Component {

    private  vasaloppetProvider =   new firebase.auth.OAuthProvider('microsoft.com');
    constructor(props : any)
    {
        super(props);
        this.vasaloppetProvider.setCustomParameters({
            // Optional "tenant" parameter in case you are using an Azure AD tenant.
            // eg. '8eaef023-2b34-4da1-9baa-8bc8c9d6a490' or 'contoso.onmicrosoft.com'
            // or "common" for tenant-independent tokens.
            // The default value is "common".
            tenant: 'vasaloppetmora.onmicrosoft.com'
          });

          
    }
    fixLoginButton =() => {
        setTimeout(() => {
            this.int_fixLoginButton();
        },10);
    }
    int_fixLoginButton(){
        var btn = document.querySelector('[data-provider-id="microsoft.com"]') as HTMLButtonElement;
        if (btn)
        {
            btn.style.background="#EFEFEF";
            btn.style.maxWidth="350px";
            btn.children[0].innerHTML="";
            (btn.children[0] as HTMLElement).style.width="30px";
            (btn.children[0] as HTMLElement).style.height="20px";
            btn.children[0].className += " asset asset-logotype";
            (btn.children[1] as HTMLElement).style.color="#000";
            btn.children[1].innerHTML="Logga in med ditt Vasaloppet konto";
            btn.children[2].innerHTML="";
            (btn.children[2] as HTMLElement).style.color="#000";
        }
    }

    render() {
        return <FirebaseContext.Consumer>
             {(value : { firebase: Firebase, isSignedIn: boolean})  => {
            // Configure FirebaseUI.
            const uiConfig : firebaseui.auth.Config = {
                siteName: "Vasaloppet Mina Sidor",
                signInFlow: 'redirect',
                callbacks: {
                    uiShown: () => {
                        this.fixLoginButton();
                    },
                    signInFailure : (error) : Promise<void> => {
                        this.fixLoginButton();
                        return;
                    },

                },
                signInOptions: [
                { provider: this.vasaloppetProvider.providerId,
                   customParameters: {
                    tenant: 'vasaloppetmora.onmicrosoft.com',
                    domain_hint: 'vasaloppet.se'
                   }
                },
                firebase.auth.EmailAuthProvider.PROVIDER_ID
                ]
            };
            
            return <div>
               <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={value.firebase.auth()}/>
            </div>
             }}
        </FirebaseContext.Consumer>
    }
}

export default Login;