/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import React, { Fragment } from 'react';
import { withRouter, RouteChildrenProps } from 'react-router-dom';
import { Theme, createStyles, Grid, WithStyles, Card, CardHeader, CardContent, TextField, Button, withStyles, CardActionArea, LinearProgress, Typography, Modal, Dialog, DialogTitle, DialogActions } from '@material-ui/core';
import moment from 'moment';
import { ApiBackend } from '../../providers/apibackend';
import uuid from 'react-uuid';
import { Product } from '../../model/Product';
import { ProductVariant } from '../../model/ProductVariant';
import { Order } from '../../model/Order';
import OrderSummary from './OrderSummary';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';

interface State {
    orderId : string;
    order : Order;
    loading: boolean;
}

/** Add Product -- Create a product
 */
class OrderScanner extends React.Component<WithStyles & RouteChildrenProps, State> {

    state = { orderId :'', loading:false, order : null as Order};

    constructor(props: any) {
        super(props);
    }

    html5QrcodeScanner : Html5QrcodeScanner;
    componentDidMount() {
        let box = 250;
        if (document.body.offsetWidth < 500)
        {
            box = undefined;
        }
        let html5QrcodeScanner = new Html5QrcodeScanner(
            "reader", { fps: 10, qrbox: box }, /* verbose= */ true);
        html5QrcodeScanner.render(this.onScanSuccess, this.onScanFailure);
    }

    onScanSuccess = (qrMessage : any) => {
        // handle the scanned code as you like
        if (qrMessage != this.state.orderId)
        {
            this.setState({orderId : qrMessage, loading:true});
            let back = new ApiBackend();
            back.retreiveOrder(qrMessage).then((order) => {
                this.setState({order: order, loading:false});
            });
        }
        //console.log(`QR matched = ${qrMessage}`);
    }
    
    onScanFailure = (error : any) => {
        // handle scan failure, usually better to ignore and keep scanning
        console.warn(`QR error = ${error}`);
    }

    handleClose = () => {
        this.setState({orderId:null,order:null});
    }

    render() {
        let { classes } = this.props;
        return <div>
            <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader className={classes.cardHeader} title="Scanna QR-kod" />
                        <CardContent>
                                    <div id="reader" style={{width:'100%'}}></div>

                        <Grid container spacing={2} style={{marginTop:20}}>
                            {this.state && this.state.loading &&
                            <Fragment>
                            <LinearProgress color="primary"/><br/>
                            <Typography variant="h6">
                                Hämtar order... {this.state.orderId}</Typography>
                                </Fragment>
                            }
                                {this.state && this.state.order &&
                                <Dialog open={this.state.order != null} onClose={this.handleClose}>
                                     <DialogTitle>Order</DialogTitle>
                                     <DialogContent dividers>
                                      
                                            <OrderSummary order={this.state.order}/>
                                    </DialogContent>
                                        <DialogActions>
                                            <Button onClick={this.handleClose}>Stäng</Button>
                                        </DialogActions>
                                </Dialog>
                                }
                        </Grid>

                        </CardContent>
                        <CardActionArea>
                        
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid></div>;
    }
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
}
);


export default withStyles(useStyles)(withRouter(OrderScanner));