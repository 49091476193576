import * as yup from "yup";

const mandatoryMessage = "Obligatoriskt";
const invalidEmailMessage = "Felaktig epost-adress";

const editB2bCompanyAdminsValidationSchema = yup
    .object()
    .shape(
        {
            "firstName": yup
                .string()
                .required(mandatoryMessage),
            "lastName": yup
                .string()
                .required(mandatoryMessage),
            "email": yup
                .string()
                .email(invalidEmailMessage)
                .required(mandatoryMessage)
        }
    );

const editB2bCompanyValidationSchema = yup
    .object()
    .shape(
        {
            "name": yup
                .string()
                .required(mandatoryMessage),
            "description": yup
                .string()
                .notRequired()
                .nullable(),
            "isArchived": yup
                .boolean()
                .required(mandatoryMessage),
            "code": yup
                .string()
                .notRequired()
                .max(36, "Koden får max vara 36 tecken")
                .nullable(),
            "registrationLink": yup
                .string()
                .notRequired()
                .nullable(),
            "admins": yup
                .array()
                .of(editB2bCompanyAdminsValidationSchema)
                .nullable()
        });

export default editB2bCompanyValidationSchema;
