/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import React, { Fragment } from 'react';
import { withRouter, RouteChildrenProps, RouteComponentProps } from 'react-router-dom';
import { Theme, createStyles, Grid, WithStyles, Card, CardHeader, CardContent, TextField, Button, withStyles, CardActionArea, LinearProgress, Select, FormControlLabel, FormControl, InputLabel, Typography, Checkbox, Dialog, DialogTitle, DialogContent, DialogActions, MenuItem } from '@material-ui/core';
import moment from 'moment';
import { ApiBackend } from '../../providers/apibackend';
import uuid from 'react-uuid';
import { Product } from '../../model/Product';
import ProductVariants from './ProductVariants';
import Departures from './Departures';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ProductVariantsRace from './ProductVariantsRace';
import { Event } from '../../model/Event';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import StartGroups from './StartGroups';


interface Props extends RouteChildrenProps<{id: string}> {
}

interface State {
    product: Product;
    addOnProducts: Product[];
    events : Event[];
    loading: boolean;
    haveChanges: boolean;
    editLocale: string;
    showAddAddon: boolean;
    selectedAddon: string;
}




/** Edit  -- Edits an existing a new event
 * - Also keep the instance of firebase (Provider Context)
 * - Keep the route mapping to components
 */
class EditProduct extends React.Component<WithStyles & Props, State> {



    constructor(props: WithStyles & Props) {
        super(props);
        this.state = { loading: true, haveChanges: false, product: null, editLocale: "SV", events: null,showAddAddon: false, addOnProducts: null, selectedAddon: null as string};

        let backend = new ApiBackend();
        let p1 = backend.getProduct(props.match.params.id);
        let p2 = backend.listProducts();
        let p3 = backend.listEvents();
        
        Promise.all([p1,p2,p3]).then((results) => {
            let product = results[0];
            let addOns = results[1].filter(x=>x.type !="race" && !x.IsArchived);
            addOns.sort((x,y) => x.name < y.name ? -1 : 1);
            let events = results[2];

            this.setState({product: product, events:events, addOnProducts: addOns, loading:false, selectedAddon: addOns[0].id});
        });
    }

    onChange = (prop: string) => (ev: React.ChangeEvent<HTMLInputElement>) => {
        var e = this.state.product as any;
        if (prop.indexOf(".") >=0)
        {
            var p = prop.split(".");
            e[p[0]][p[1]] = ev.target.value;
        }
        else 
        {
            e[prop] = ev.target.value;
        }

        if (prop == "type" && this.state.product.variants) {
            for (let v = 0; v < this.state.product.variants.length; v++) {
                if (ev.target.value == "skipass") {
                    this.state.product.variants[v].priceGroups = [
                        { id: uuid(), Name: "Dagkort",Name_En:'Day Pass', LongName_En : '', Description_En:'', LongName:'',Description:'', price: 0,type:'1', validFrom: null, validTo: null, validOnlineOnly:false, validPOSOnly:false, maxAge: null, isPublic: true },
                        { id: uuid(), Name: "Veckokort", Name_En: 'Week Pass', LongName_En:'',Description_En:'', LongName:'', Description:'', price: 0, type:'7', validFrom: null, validTo: null, validOnlineOnly:false, validPOSOnly:false, maxAge: null, isPublic: true },
                        { id: uuid(), Name: "Säsongskort", Name_En: 'Season Pass', LongName_En:'', Description_En:'', LongName:'', Description:'', price: 0, type:null,validFrom: null, validTo: null, validOnlineOnly:false, validPOSOnly:false, maxAge: null, isPublic: true }
                    ];
                }
                else {
                    this.state.product.variants[v].priceGroups = [
                        { id: uuid(), Name: "Normal", Name_En : 'Normal', LongName_En:'', Description_En:'',LongName:'',Description:'', price: 0, type:null,validFrom: null, validTo: null, validOnlineOnly:false, validPOSOnly:false, maxAge: null, isPublic: true }
                    ]
                }

            }
        }

        this.setState({ product: e, haveChanges: true });
    }

    checkChanged = (prop: string) => (ev: React.ChangeEvent<HTMLInputElement>) => {
        var e = this.state.product as any;
        e[prop] = ev.target.checked;
        this.setState({ product: e, haveChanges: true });
    }

    addAddon = () => {
        this.setState({showAddAddon:true });
    }
    
    save = () => {
        let back = new ApiBackend();
        back.updateProduct(this.state.product).then((res) => {
            if (res) {
                this.setState({ haveChanges: false });
            }
            else {
                alert("Kunde inte spara ändringar!");
            }
        });
    }
    back = () => {
        this.props.history.push("/products/manage");
    }

    setChanged = () => {
        this.setState({haveChanges:true});
    }

    handleEditLocale = (event: React.MouseEvent<HTMLElement>, newLocale: string | null) => {
        this.setState({editLocale: newLocale});
      };
    

    render() {
        let { classes } = this.props;
        return <div>{this.state.loading &&
            <LinearProgress />
        }
            <Grid container className={classes.root} spacing={0}>
                <Grid item xs={12}>
                    {this.state && this.state.product &&
                    <Card>
                        <CardHeader className={classes.cardHeader} title={this.state.product.name} />
                        <CardContent>
                            <form className={classes.form} noValidate autoComplete="off">
                                <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                                    <Grid item xs={12}>
                                    <ToggleButtonGroup
                                    value={this.state.editLocale}
                                    exclusive
                                    onChange={this.handleEditLocale}
                                    aria-label="text alignment"
                                    >
                                    <ToggleButton value="SV" aria-label="left aligned">
                                        SV
                                    </ToggleButton>
                                    <ToggleButton value="EN" aria-label="left aligned">
                                        EN
                                    </ToggleButton>
                                    </ToggleButtonGroup>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField style={{ flex: 1, margin: '0 3px' }}  label="Namn" value={(this.state.editLocale == "EN" ? this.state.product.name_en : this.state.product.name) ?? ''} onChange={this.onChange(this.state.editLocale == "EN" ? 'name_en' : 'name')} fullWidth />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField style={{ flex: 1, margin: '0 3px' }} label="Publikt ID" value={this.state.product.publicId ?? ''} onChange={this.onChange('publicId')} fullWidth />
                                    </Grid>
                                    
                                    <Grid item xs={12}>
                                        <TextField rows={4} multiline={true} style={{ flex: 1, margin: '0 3px' }}  value={(this.state.editLocale == "EN" ? this.state.product.description_en : this.state.product.description) ?? ''} onChange={this.onChange(this.state.editLocale == "EN" ? 'description_en' : 'description')} label="Beskrivning" fullWidth />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <FormControl className={classes.formControl} style={{margin:0}}>
                                            <InputLabel shrink>
                                                Produktkategori
                                            </InputLabel>
                                            <Select native value={this.state.product.type} onChange={this.onChange('type')} label="Produkttyp">
                                                {ApiBackend.getProductTypes().map((item) => {
                                                    return <option key={item.code} value={item.code}>{item.name}</option>
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl className={classes.formControl} style={{margin:0}}>
                                            <InputLabel shrink>
                                                Evenemang
                                            </InputLabel>
                                            <Select native value={this.state.product.EventId ?? ''} onChange={this.onChange('EventId')} label="Evenemang">
                                              <option key={"none"} value=''>Inget</option>
                                                {this.state && this.state.events && this.state.events.map((item) => {
                                                    return <option key={item.id} value={item.id}>{item.name}</option>
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                    <TextField InputLabelProps={{shrink: true}} style={{ flex: 1, margin: '0 3px' }} label="Symplify Journey för biljett" value={this.state.product.symplifyJourneyForTicket ?? ''} onChange={this.onChange('symplifyJourneyForTicket')} fullWidth />
                                    </Grid>
                                    <Grid item xs={3}>
                                    <TextField  InputLabelProps={{shrink: true}} style={{ flex: 1, margin: '0 3px' }} label="Symplify Journey för biljett(ENG)" value={this.state.product.symplifyJourneyForTicketEN ?? ''} onChange={this.onChange('symplifyJourneyForTicketEN')} fullWidth />
                                    </Grid>
                                    <Grid item xs={12}>
                                    <FormControlLabel control={<Checkbox checked={this.state.product.active} onChange={this.checkChanged("active")} />} label="Publicerad" />
                                    <FormControlLabel control={<Checkbox checked={this.state.product.IsArchived} onChange={this.checkChanged("IsArchived")} />} label="Arkiverad" />
                                    </Grid>
                                    <Grid item xs={12}>
                                    <FormControlLabel control={<Checkbox checked={this.state.product.productRequiresSocialSecurityNumber} onChange={this.checkChanged("productRequiresSocialSecurityNumber")} />} label="Produkten kräver personnummer" />
                                    <FormControlLabel control={<Checkbox checked={this.state.product.preRaceEntryOpen} onChange={this.checkChanged("preRaceEntryOpen")} />} label="Föranmälan aktiv" />
                                    <FormControlLabel control={<Checkbox checked={this.state.product.raceEntryOpen} onChange={this.checkChanged("raceEntryOpen")} />} label="Anmälan öppnad" />
                                    <FormControlLabel control={<Checkbox checked={this.state.product.raceEntriesCanBeChanged} onChange={this.checkChanged("raceEntriesCanBeChanged")} />} label="Anmälningar kan ändras" />
                                    <FormControlLabel control={<Checkbox checked={this.state.product.raceStartCertificateAvailable} onChange={this.checkChanged("raceStartCertificateAvailable")} />} label="Startbevis tillgängliga" />
                                    </Grid>
                                    

                                    

                                    <Grid item xs={12}>
                                    <Card>
                                        <CardHeader className={classes.cardHeader} title={"Villkorshantering"} />
                                        <CardContent>
                                        <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                                            <Grid item xs={6}>
                                                <TextField fullWidth style={{ flex: 1, margin: '0 3px' }}  label="Text acceptera anmälningsvillkor" value={this.state.product.textAcceptTerms ?? '' } onChange={this.onChange('textAcceptTerms')} />
                                                <Typography variant="body2" style={{color:'#555'}}>Om ifylld visas texten med obligatorisk kryssruta för att acceptera villkor. Lägg med länken från fältet länk anmälningsvillkor genom att skriva ##link##</Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField fullWidth  style={{ flex: 1, margin: '0 3px' }}  label="Länk anmälningsvillkor " value={this.state.product.linkAcceptTerms ?? '' } onChange={this.onChange('linkAcceptTerms')} />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField fullWidth  style={{ flex: 1, margin: '0 3px' }}  label="Rubrik för länk " value={this.state.product.labelLinkAcceptTerms ?? '' } onChange={this.onChange('labelLinkAcceptTerms')} />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField fullWidth style={{ flex: 1, margin: '0 3px' }}  label="Text acceptera GDPR" value={this.state.product.textAcceptGDPR ?? '' } onChange={this.onChange('textAcceptGDPR')} />
                                                <Typography variant="body2" style={{color:'#555'}}>Om ifylld visas texten med obligatorisk kryssruta för att acceptera GDPR. Lägg med länken från fältet länk GDRP genom att skriva ##link##</Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField fullWidth  style={{ flex: 1, margin: '0 3px' }}  label="Länk GDPR " value={this.state.product.linkAcceptGDPR ?? '' } onChange={this.onChange('linkAcceptGDPR')} />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField fullWidth  style={{ flex: 1, margin: '0 3px' }}  label="Rubrik för länk " value={this.state.product.labelLinkAcceptGDPR ?? '' } onChange={this.onChange('labelLinkAcceptGDPR')} />
                                            </Grid>
                                        </Grid>
                                        </CardContent>
                                    </Card>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Card>
                                        <CardHeader className={classes.cardHeader} title={"Bokföring"} />
                                        <CardContent>
                                        <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                                            <Grid item xs={3}>
                                                <TextField type="number" style={{ flex: 1, margin: '0 3px' }}  label="Konto" value={this.state.product.accountingInfo ? (this.state.product.accountingInfo.AccountingAccount ?? ''): ''} onChange={this.onChange('accountingInfo.AccountingAccount')} />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField  type="number" style={{ flex: 1, margin: '0 3px' }}  label="Kostnadsställe" value={this.state.product.accountingInfo ? (this.state.product.accountingInfo.AccountingDebitPlace??'') : ''} onChange={this.onChange('accountingInfo.AccountingDebitPlace')} />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField type="number"  style={{ flex: 1, margin: '0 3px' }}  label="Arrangemang" value={this.state.product.accountingInfo ? (this.state.product.accountingInfo.AccountingEvent??'') : ''} onChange={this.onChange('accountingInfo.AccountingEvent')} />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField style={{ flex: 1, margin: '0 3px' }}  label="Projekt" value={this.state.product.accountingInfo ? (this.state.product.accountingInfo.AccountingProject ?? '') : ''} onChange={this.onChange('accountingInfo.AccountingProject')} />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField inputProps={{ maxLength: 4 }} style={{ flex: 1, margin: '0 3px' }}  label="Betalningsprefix" value={this.state.product.PaymentRefPrefix ?? ''} onChange={this.onChange('PaymentRefPrefix')} />
                                            </Grid>
                                        </Grid>
                                        </CardContent>
                                    </Card>
                                    </Grid>

{this.state.product.type=="race" &&
                                        <Grid item xs={12}>
                                            <Card>
                                                <CardHeader className={classes.cardHeader} title={"Loppinställningar"} />
                                                <CardContent>
                                                    <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                                                        <Grid item xs={3}><FormControlLabel label="Startledstilldelning aktiv" control={<Checkbox checked={this.state.product.startGroupAllocationActive} onChange={(ev) => {
                                                            let s = this.state.product;
                                                            s.startGroupAllocationActive = ev.target.checked;
                                                            this.setState({product: s, haveChanges:true});
                                                            }}
                                                            />}/></Grid>
                                                        <Grid item xs={3}>Max deltagare <TextField value={this.state.product.maxParticipants ?? ''} onChange={this.onChange('maxParticipants')} fullWidth/></Grid>
                                                        <Grid item xs={3}></Grid>
                                                        <Grid item xs={12}>                                                            
                                                                <StartGroups product={this.state.product} groupsChanged={() => {this.setState({product : this.state.product, haveChanges:true});}} editLocale={this.state.editLocale} />
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>
    }

                                    <Grid item xs={12}>
                                        {this.state.product.type == "race" ?
                                            <ProductVariantsRace
                                                product={this.state.product}
                                                variantsChanged={this.setChanged}
                                                editLocale={this.state.editLocale}
                                                haveChanges={this.state.haveChanges}
                                            />
                                            :
                                            <ProductVariants
                                                product={this.state.product}
                                                variantsChanged={this.setChanged}
                                                editLocale={this.state.editLocale}
                                            />
                                        }
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Card>
                                            <CardHeader className={classes.cardHeader} title={"Tillgängliga tillval"} />
                                            <CardContent>
                                                <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                                                    {this.state.product && this.state.product.addOnProducts && this.state.product.addOnProducts.map((addon) => {
                                                        let p = this.state.addOnProducts.filter(x=>x.id == addon);
                                                        if (p && p.length > 0)
                                                        {
                                                            return <Fragment><Grid item xs={8}><DeleteIcon style={{cursor: 'pointer'}} onClick={() => {
                                                                this.removeAddon(addon);
                                                            }} />  {p[0].name}</Grid><Grid item xs={4}></Grid></Fragment>;
                                                        }
                                                    })
                                                    }
                                                </Grid>
                                            </CardContent>
                                            <CardActionArea component="a">
                                                <Button onClick={this.addAddon}>Lägg till tillval</Button>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>

                                </Grid>
                            </form>
                           {/* <Grid item xs={12}>
                                <Typography variant="h6">QR kod för direktköp av produkt</Typography>
                                    <img src={ApiBackend.getProductQrCodeUrl(this.state.product.id)}/>
                                    </Grid>*/}
                            
                        </CardContent>
                        <CardActionArea component="a">
                            <Button color="secondary" variant="contained" style={{backgroundColor:this.state.haveChanges ?'red' : null}} disabled={!this.state.haveChanges} onClick={this.save}>Spara ändringar</Button>
                            <Button color="secondary" onClick={this.back}>Tillbaka</Button>
                        </CardActionArea>
                    </Card>
                    }
                </Grid>
                    {this.state.showAddAddon && this.renderAddAddon()}
            </Grid>
        </div>;
    }

    removeAddon = (id: string) => {
        let p = this.state.product;
        if (!p.addOnProducts)
        {
            return;
        }
        
        p.addOnProducts = p.addOnProducts.filter(x => x != id);
        this.setState({product: p, haveChanges: true});
    }

    create = () => {
        let p = this.state.product;
        if (!p.addOnProducts)
            p.addOnProducts = [];
        
        p.addOnProducts.push(this.state.selectedAddon);
        
        this.setState({product: p, showAddAddon: false, haveChanges: true});
    }
    handleAddEditCancel = () => {
        this.setState({showAddAddon: false});
    }

    renderAddAddon() {
        let { classes } = this.props;
        return <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">{"Lägg till tillval"}</DialogTitle>
            <DialogContent>
                <Grid container className={classes.root} spacing={2} style={{ width: '100%', padding: 3 }}>
                    <Grid item xs={12}>
                        <Select value={this.state.selectedAddon} onChange={(ev : React.ChangeEvent<HTMLSelectElement>) => {
                            this.setState({selectedAddon: ev.target.value });
                        }}>
                            {this.state.addOnProducts.map((x)=> {
                              return <MenuItem value={x.id}>{x.name}</MenuItem> 
                            })}
                        </Select>
                    </Grid>
                    
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" autoFocus onClick={this.handleAddEditCancel} color="primary">
                    Avbryt
          </Button>
                <Button variant="contained" onClick={this.create} color="primary">
                   Lägg till
                </Button>
            </DialogActions>
        </Dialog>;
    }
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    formControl: {
        margin: spacing(1),
        minWidth: 120,
      },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
}
);


export default withRouter(withStyles(useStyles)(EditProduct));