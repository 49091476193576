import React from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { Field, FormikErrors, FormikTouched } from "formik";

interface IProps {
    disabled?: boolean;
    propName: string;
    label: string;
    errors: FormikErrors<any>;
    touched: FormikTouched<any>;
    values: any;
    handleBlur: {
        (e: React.FocusEvent<any>): void;
        <T = string | any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
    };
    handleChange: {
        (e: React.ChangeEvent<any>): void;
        <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
    };
    valueGetter?: () => any;
}

class FormCbxField extends React.Component<IProps> {

    render(): JSX.Element {
        const { disabled, propName, label, values, touched, errors, handleChange, handleBlur, valueGetter } = this.props;
        return (
            <label>
                <Field
                    id={propName}
                    disabled={disabled}
                    as={FormControlLabel}
                    type="checkbox"
                    label={label}
                    checked={valueGetter ? valueGetter() : values[propName]}
                    control={<Checkbox />}
                    onChange={handleChange(propName)}
                    onBlur={handleBlur(propName)}
                    error={(touched[propName] && Boolean(errors[propName]) ? (touched[propName] && Boolean(errors[propName])) : undefined)}
                />
            </label>
        )
    }
}

export default FormCbxField;
