import React from "react";
import { B2bCompanyProduct } from "../../../../model/B2bCompany";
import { Product } from "../../../../model/Product";
import { ApiBackend } from "../../../../providers/apibackend";
import ProductVariantPriceGroupSelector, { IProductVariantPriceGroupSelectorResult } from "../../../Common/ProductVariantPriceGroupSelector";

interface IProps {
    onChange: (product: B2bCompanyProduct) => void;
}

interface IState {
    products?: Product[];
}

class SelectProductPopover extends React.Component<IProps, IState> {
    private readonly api: ApiBackend;
    
    constructor(props: IProps) {
        super(props);
        this.state = {
            products: undefined
        };
        this.api = new ApiBackend();
    }

    async componentDidMount(): Promise<void> {
        const allProducts = await this.api.listProducts();
        this.setState({
            products: allProducts
                .filter(product => product.type === "race" || product.type === "other")
                .sort((product1, product2) => product1.name.localeCompare(product2.name, "sv"))
        });
    }
    
    render() {
        const { products } = this.state;

        if (!products) {
            return (<div style={{ display: "block", height: 200 }}></div>);
        }

        return (
            <ProductVariantPriceGroupSelector
                headerText="Välj produkt"
                buttonText="Ok"
                products={products}
                includePriceGroups={true}
                onChange={this.onChange} 
            />
        )
    }

    private onChange = (res: IProductVariantPriceGroupSelectorResult) => {
        const newProduct: B2bCompanyProduct = {
            productId: res.product.id,
            productVariantId: res.productVariant.Id,
            productVariantPriceGroupId: res.productVariantPriceGroup.id,
            name: res.product.name,
            description: res.product.description,
            isActive: false
        };

        this.props.onChange(newProduct);
    }
}

export default SelectProductPopover;
