import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Box, Button, Card, CardContent, CardHeader, createStyles, debounce, Grid, IconButton, LinearProgress, Theme, Typography, withStyles, WithStyles } from "@material-ui/core";
import RefreshIcon from '@material-ui/icons/Refresh';
import FirebaseContext from "../../providers/Firebase/context";
import { ApiBackend } from "../../providers/apibackend";
import { Campaign } from "../../model/Campaign";
import { OpenInBrowser, ArrowForward } from "@material-ui/icons";
import { DataGrid, ColDef, ValueFormatterParams, CellParams } from '@material-ui/data-grid';

interface IState {
    loading: boolean;
    campaigns: Campaign[];
    popupOpen: boolean;
    anchorEl: HTMLButtonElement;
    showArchived: boolean;
}

type EditMode = "in-app" | "new-tab";

class ManageCampaigns extends React.Component<RouteComponentProps & WithStyles, IState> {
    static contextType = FirebaseContext;

    private readonly api: ApiBackend;

    constructor(props: RouteComponentProps & WithStyles) {
        super(props);
        this.state = {
            loading: true,
            campaigns: null,
            popupOpen: false,
            anchorEl: undefined,
            showArchived: false
        };
        this.api = new ApiBackend();
    }

    componentDidMount() {
        this.refresh();
    }

    private performSearch = debounce(async () => {
        const campaigns = await this.api.listCampaigns();
        this.setState({
            campaigns: campaigns,
            loading: false
        });
    }, 500);

    private refresh = () => {
        this.setState({ loading: true });
        this.performSearch();
    };

    private editCampaign = (campaign: Campaign, mode: EditMode): void => {
        switch (mode) {
            case "in-app":
                this.props.history.push(`manage/${campaign.id}`);
                break;
            case "new-tab":
                window.open(`manage/${campaign.id}`);
                break;
        }
    };

    private handleAddCampaign = () => {
        this.props.history.push(`create`);
    };

    private onCellClick = (param: CellParams): void => {
        if (param.field === "name") {
            this.editCampaign(param.data as Campaign, "in-app");
        }
    }

    render(): JSX.Element {
        const { classes } = this.props;
        const columns: ColDef[] = [
            {
                field: '',
                headerName: '',
                width: 150,
                renderCell: (params: ValueFormatterParams) => {
                    return <>
                        <IconButton color="default" onClick={() => { this.editCampaign(params.data as Campaign, "new-tab") }}>
                            <OpenInBrowser fontSize="default" />
                        </IconButton>
                        <IconButton color="default" onClick={() => { this.editCampaign(params.data as Campaign, "in-app") }}>
                            <ArrowForward fontSize="default" />
                        </IconButton>
                    </>
                }
            },
            {
                field: 'name',
                headerName: 'Namn',
                width: 500,
                cellClassName: 'cursor-pointer'
            },
            {
                field: 'totalNumCodes',
                headerName: 'Antal koder',
                width: 150
            },
            {
                field: 'usedNumCodes',
                headerName: 'Använda koder',
                width: 200
            }
        ];
        
        return (<>
            <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader
                            className={classes.cardHeader}
                            title={
                                <>
                                    <Typography
                                        variant="h5"
                                        style={{ display: 'inline' }}>
                                        Kampanjer
                                    </Typography>
                                    <RefreshIcon
                                        style={{ display: 'inline', verticalAlign: 'middle' }}
                                        onClick={this.refresh}
                                    />
                                </>
                            }
                        />
                        <CardContent>
                            <Box mb={1}>
                                <Button variant="contained" onClick={this.handleAddCampaign}>Lägg till kampanj</Button>
                            </Box>

                            {this.state.loading &&
                                <LinearProgress color="secondary" />
                            }

                            <div style={{ height: 400, width: '100%' }}>
                                <DataGrid
                                    loading={this.state.loading}
                                    rows={this.state.campaigns ?? []}
                                    columns={columns}
                                    pageSize={10}
                                    onCellClick={this.onCellClick}
                                />
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>);
    }
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withRouter(withStyles(useStyles)(ManageCampaigns));
