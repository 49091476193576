import { Button, Card, CardContent, CardHeader, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress, TextField, Theme, Typography, withStyles, WithStyles } from '@material-ui/core';
import React, { Fragment } from 'react';
import { Order } from '../../model/Order';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import OrderRowMetadata from './OrderRowMetadata';
import { ApiBackend } from '../../providers/apibackend';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import Modal from '@material-ui/core/Modal/Modal';
import { OrderItem } from '../../model/OrderItem';
import EditOrderItem from './EditOrderItem';
import OrderRowEntry from './OrderRowEntry';
import EditEntryDialog from '../Entries/Entry/EditEntryDialog';

interface Props {
    order: Order;
}

interface State {
    order: Order;
    orderItemsChanged: boolean;
    saving: boolean;
    editOrderItem: OrderItem;
    addOrderItem: OrderItem;
    deleteOrderItem: OrderItem;
}

class ManageOrderItems extends React.Component<Props & WithStyles, State> {
    constructor(props: Props & WithStyles) {
        super(props);
        this.state = { order: this.props.order, orderItemsChanged: false, saving: false, editOrderItem: null, addOrderItem: null, deleteOrderItem: null };
    }

    saveOrderItemChanges = () => {
        let back = new ApiBackend();
        this.setState({ saving: true });
        back.updateOrderItems(this.props.order.id, this.state.order.items).then((order) => {
            this.setState({ saving: false, order: order, orderItemsChanged: false });
        });
    }

    editOrderRow = (row: OrderItem) => {
        this.setState({ editOrderItem: row });
    }

    deleteOrderRow = (row: OrderItem) => {
        this.setState({ deleteOrderItem: row });
    }

    addOrderRow = () => {
        let row: OrderItem = { numberOfProducts: 1 } as OrderItem;
        this.setState({ addOrderItem: row });
    }

    render() {
        let { classes } = this.props;
        let o = this.state.order;
        return <Card>
            <CardHeader className={classes.cardHeader} title={"Beställning"} />
            <CardContent>
                {this.state.saving ? <Modal
                    open={true}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <CircularProgress color="primary" />
                </Modal> : null}
                <Grid container spacing={2}>

                    <Grid item xs={1}></Grid>
                    <Grid item xs={2}>Antal</Grid>
                    <Grid item xs={5}>Produkt</Grid>
                    <Grid item xs={2}>à pris</Grid>
                    <Grid item xs={2}>Delsumma</Grid>
                    {o.items.map((orderItem, idx) => {
                        let name = orderItem.productName + " (" + orderItem.variantName + " / " + orderItem.priceGroupName + ")";
                        if (orderItem.occationName) {
                            name = orderItem.productName;
                        }
                        return <Fragment>
                            <Grid item xs={1}><EditIcon style={{ cursor: 'pointer' }} onClick={() => { this.editOrderRow(orderItem) }} />
                                <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => { this.deleteOrderRow(orderItem) }} /></Grid>
                            <Grid item xs={2}>{orderItem.numberOfProducts} st</Grid>
                            <Grid item xs={5}>{name}</Grid>
                            <Grid item xs={2}>{orderItem.itemPrice} kr</Grid>
                            <Grid item xs={2}>{orderItem.itemPrice * orderItem.numberOfProducts} kr</Grid>
                            {(orderItem.metadata || orderItem.occationName) &&
                                <OrderRowMetadata item={orderItem} />
                            }
                            {(orderItem.entry) &&
                                <OrderRowEntry item={orderItem} />
                            }
                        </Fragment>
                    })}
                    {o.discountCodes && o.discountCodes.map((code, idx) => {
                        return <Fragment>
                            <Grid item xs={3}>{1}</Grid>
                            <Grid item xs={5}>{code.code} ({code.description})</Grid>
                            <Grid item xs={2}>-{code.value} kr</Grid>
                            <Grid item xs={2}>-{code.value} kr</Grid>
                        </Fragment>
                    })}
                    <hr className="thin black" style={{ margin: '15px 0', width: '100%' }} />
                    <Grid item xs={2}>
                        <AddCircleIcon onClick={this.addOrderRow} style={{ verticalAlign: 'middle', cursor: 'pointer' }} />
                    </Grid>
                    <Grid item xs={8}>
                        {this.state.orderItemsChanged &&
                            <Button variant="contained" style={{ background: 'red' }} onClick={this.saveOrderItemChanges}>Spara ändringar</Button>}
                    </Grid>
                    <Grid item xs={2}>Totalt: <b>{o.totalSum} kr</b></Grid>
                </Grid>
                {this.state.editOrderItem &&
                    (this.state.editOrderItem.entry ? <EditEntryDialog entryId={this.state.editOrderItem.entry.id} onSave={() => { }} onAbortEdit={() => { this.setState({ editOrderItem: null }) }} /> : this.renderEditOrderRow())
                }
                {this.state.addOrderItem &&
                    this.renderAddOrderRow()
                }
                {this.state.deleteOrderItem &&
                    this.renderDeleteOrderRow()
                }
            </CardContent>
        </Card>
    }

    renderEditOrderRow() {
        return <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">Editera orderrad</DialogTitle>
            <DialogContent>
                <EditOrderItem item={this.state.editOrderItem} save={() => {
                    this.setState({ order: this.state.order, editOrderItem: null, orderItemsChanged: true });

                }} cancel={() => {
                    this.setState({ editOrderItem: null });
                }} />
            </DialogContent>
        </Dialog>;
    }

    renderAddOrderRow() {
        return <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">Lägg till orderrad</DialogTitle>
            <DialogContent>
                <EditOrderItem item={this.state.addOrderItem} save={() => {
                    let o = this.state.order;
                    o.items.push(this.state.addOrderItem);
                    this.setState({ order: o, addOrderItem: null, orderItemsChanged: true });

                }} cancel={() => {
                    this.setState({ addOrderItem: null });
                }} />
            </DialogContent>
        </Dialog>;
    }

    renderDeleteOrderRow() {
        return <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">Ta bort orderrad</DialogTitle>
            <DialogContent>
                <Typography variant="body2">Är du säker att du vill ta bort raden?</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.confirmDeleteRow}>Ja</Button><Button onClick={this.abortDelete}>Nej</Button>
            </DialogActions>
        </Dialog>;
    }

    abortDelete = () => {
        this.setState({ deleteOrderItem: null });
    }

    confirmDeleteRow = () => {
        let o = this.state.order;
        o.items = o.items.filter(x => x != this.state.deleteOrderItem);
        this.setState({ order: o, deleteOrderItem: null, orderItemsChanged: true });
    }
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withStyles(useStyles)(ManageOrderItems);
