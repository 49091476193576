import uuid from 'react-uuid';
import { ProductVariantAgeCategory } from './ProductVariantAgeCategory';
import { ProductOccation } from './ProductVariantOccation';
import { ProductVariantPriceGroup } from './ProductVariantPriceGroup';
import { ProductVariantRaceData } from './ProductVariantRaceData';
import { ProductVariantTimingPartner } from './ProductVariantTimingPartner';
export class ProductVariant {
    Id: string;
    Name: string;
    Description: string;
    Name_En: string;
    Description_En: string;
    itemsAvailable?: number;
    priceGroups: ProductVariantPriceGroup[];
    ageCategories?: ProductVariantAgeCategory[];
    occations? : ProductOccation[];
    productVariantTiming? : ProductVariantTimingPartner;
    productVariantRaceData?: ProductVariantRaceData;
    static createNew() : ProductVariant {
        return {Id: uuid(),Name: '', Description: '',Name_En: '', Description_En: '', itemsAvailable: null, priceGroups: [{id : uuid(), Name:'Normal', LongName_En:'', Name_En:'Normal',Description_En:'',price:0, LongName:'',Description:'',type:'', validFrom: null, validOnlineOnly: false, validPOSOnly: false, validTo: null, maxAge: 999, isPublic: false}]};
    }
}