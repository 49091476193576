/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import React, { Fragment } from 'react'
import FirebaseContext from '../../../providers/Firebase/context';
import { Card, CardHeader, CardContent, Theme, withStyles, Button, createStyles, Grid, Dialog, DialogTitle, DialogActions, LinearProgress, TextField, Typography, IconButton } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ApiBackend } from '../../../providers/apibackend';
import { Order } from '../../../model/Order';
import { DataGrid, ColDef, ValueFormatterParams, PageChangeParams } from '@material-ui/data-grid';
import { Entry } from '../../../model/Entry';
import RefreshIcon from '@material-ui/icons/Refresh';
import EditEntryDialog from './EditEntryDialog';
import { Edit, OpenInBrowser, ArrowForward } from '@material-ui/icons';
import { debounce } from 'lodash';

interface State {
    loading: boolean;
    entries: Entry[],
    search: string;
    deleteConfirmation: Order;
    showDeleteConfirmation: boolean;
    editEntry: Entry;
    page: number;
    pageSize: number;
    rowCount: number;
}

type editEntryMode = "dialog" | "in-app" | "new-tab";

class ManageEntries extends React.Component<RouteComponentProps, State> {
    static contextType = FirebaseContext;

    private readonly api: ApiBackend;

    constructor(props: RouteComponentProps) {
        super(props);
        this.state = { editEntry: null, loading: true, entries: null, deleteConfirmation: null, showDeleteConfirmation: false, search: "", page: 1, pageSize: 10, rowCount: 0 };
        this.api = new ApiBackend();
    }

    componentDidMount() {
        this.refresh();
    }

    onSearchChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ search: ev.target.value, page: 1, loading: true }, () => {
            this.performSearch();
        });
    }

    refresh = () => {
        this.setState({ loading: true });
        this.performSearch();
    }

    editEntry = (entry: Entry, mode: editEntryMode): void => {
        switch (mode) {
            case "dialog":
                this.setState({ editEntry: entry });
                break;
            case "in-app":
                this.props.history.push(`manage/${entry.id}`);
                break;
            case "new-tab":
                window.open(`manage/${entry.id}`);
                break;
        }
    };

    render() {
        const { classes } = this.props as any;

        const columns: ColDef[] = [
            {
                field: '', headerName: '', width: 200,
                renderCell: (params: ValueFormatterParams) => {
                    return <Fragment>
                        <IconButton color="default" onClick={() => { this.editEntry(params.data as any, "dialog") }}>
                            <Edit fontSize="default" />
                        </IconButton>
                        <IconButton color="default" onClick={() => { this.editEntry(params.data as any, "new-tab") }}>
                            <OpenInBrowser fontSize="default" />
                        </IconButton>
                        <IconButton color="default" onClick={() => { this.editEntry(params.data as any, "in-app") }}>
                            <ArrowForward fontSize="default" />
                        </IconButton>
                    </Fragment>
                }
            },
            { field: 'eventName', headerName: 'Lopp', width: 170 },
            { field: 'raceName', headerName: 'Klass', width: 170 },
            { field: 'bibtext', headerName: 'Nr.lapp', width: 170 },
            { field: 'startgroupName', headerName: 'Startled', width: 170 },
            { field: 'firstName', headerName: 'Förnamn', width: 170 },
            { field: 'lastName', headerName: 'Efternamn', width: 170 },
            { field: 'clubName', headerName: 'Klubb', width: 170 },
            { field: 'email', headerName: 'E-post', width: 170 },
            { field: 'phone', headerName: 'Telefon', width: 170 },
            { field: 'status', headerName: 'Status', width: 170 },
            { field: 'isPayedInFull', headerName: 'Betald', width: 170 },
            { field: 'orderDate', headerName: 'Orderdatum', width: 170 }
        ];

        return <Fragment>
            <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12}>
                    <TextField 
                        autoComplete="off"
                        style={{ flex: 1, margin: '0 3px' }}
                        id="standard-basic"
                        label="Sök"
                        value={this.state.search}
                        onChange={this.onSearchChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader className={classes.cardHeader} title={<Fragment><Typography variant="h5" style={{ display: 'inline' }}>Anmälningar </Typography><RefreshIcon style={{ display: 'inline', verticalAlign: 'middle' }} onClick={this.refresh} /></Fragment>} />
                        <CardContent>
                            {this.state.loading &&
                                <LinearProgress color="secondary" />
                            }
                            <div style={{ height: 400, width: '100%' }}>
                                <DataGrid
                                    loading={this.state.loading}
                                    rows={this.state.entries ?? []}
                                    columns={columns}
                                    paginationMode="server"
                                    pageSize={this.state.pageSize}
                                    page={this.state.page}
                                    rowCount={this.state.rowCount}
                                    onPageChange={(newPage) => this.setPage(newPage)}
                                />
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            {this.state && this.state.showDeleteConfirmation && this.renderConfirmationDialog()}
            {this.state && this.state.editEntry &&
                <EditEntryDialog
                    entryId={this.state.editEntry.id}
                    onAbortEdit={() => { this.setState({ editEntry: null }) }}
                    onSave={() => {
                        this.setState({ editEntry: null });
                        this.refresh();
                    }}
                />
            }
        </Fragment>;
    }

    renderConfirmationDialog() {
        return <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">Är du säker på att du vill ta bort ordern?</DialogTitle>
            <DialogActions>
                <Button variant="contained" autoFocus onClick={this.handleDeleteCancel} color="primary">
                    Avbryt
                </Button>
                <Button variant="contained" onClick={this.handleDeleteOk} color="primary">
                    Ja, ta bort
                </Button>
            </DialogActions>
        </Dialog>;
    }

    handleDeleteCancel = () => {
        this.setState({ deleteConfirmation: null, showDeleteConfirmation: false });
    }

    handleDeleteOk = () => {
        this.setState({ loading: true });
    }

    private setPage(pageParams: PageChangeParams): void {
        const { page } = this.state;

        if (pageParams.page === page) {
            // mui is trigger happy
            return;
        }

        this.setState({ page: pageParams.page, loading: true }, () => {
            this.performSearch();
        });
    }

    private performSearch = debounce(async () => {
        const { page, pageSize } = this.state;
        const response = await this.api.listEntries(this.state.search, (page-1) * pageSize, pageSize);
        this.setState({
            entries: response?.entries,
            rowCount: response?.count ?? 0,
            loading: false 
        });
    }, 500);
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withRouter(withStyles(useStyles)(ManageEntries));
