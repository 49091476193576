/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import React, { ChangeEvent, Fragment } from 'react'
import FirebaseContext from '../../providers/Firebase/context';
import { Card, CardHeader, CardContent, Theme, WithStyles, withStyles, Button, createStyles, Grid, CardActionArea, TableContainer, Paper, Table, TableHead, TableRow, TableBody, Hidden, TableCell, Dialog, DialogTitle, DialogActions, LinearProgress } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ApiBackend } from '../../providers/apibackend';
import { Event } from '../../model/Event';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

interface State {
    loading : boolean;
    events: Event[],
    deleteConfirmation : Event;
    showDeleteConfirmation: boolean;

}

class ManageEvents extends React.Component<RouteComponentProps, State> {
    static contextType = FirebaseContext;
    constructor(props : RouteComponentProps)
    {
        super(props);
        this.state = {loading: true, events : null, deleteConfirmation: null, showDeleteConfirmation:false};
    }

    componentDidMount() {
        this.setState({loading:true});
        let back = new ApiBackend();
        back.listEvents().then((events) => {
            this.setState({ events: events, loading:false });
        });
    }

    addEvent = () => {
        this.props.history.push("/events/create");
    }

    editEvent = (event : Event) => () =>{
        this.props.history.push("/events/manage/" + event.id);
    }
    deleteEvent = (event : Event) => () =>{
        this.setState({deleteConfirmation: event, showDeleteConfirmation:true});
    }

    render() {
        let { classes } = this.props as any;
        return <Fragment><Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
                
                <Card>

                    <CardHeader className={classes.cardHeader} title="Evenemang" />
                    <CardContent>
                    {this.state.loading &&
                        <LinearProgress color="secondary"/>
                        }
                        <TableContainer component={Paper}>
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Namn</StyledTableCell>
                                        <StyledTableCell>Startdatum</StyledTableCell>
                                        <StyledTableCell>Slutdatum</StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state && this.state.events && this.state.events.map((event) => {
                                        return <TableRow>
                                            <StyledTableCell>{event.name}</StyledTableCell>
                                            <StyledTableCell>{event.startDate}</StyledTableCell>
                                            <StyledTableCell>{event.endDate}</StyledTableCell>
                                            <StyledTableCell align="right">
                                                <EditIcon onClick={this.editEvent(event)} className={classes.icon} style={{cursor:'pointer'}}  />
                                            <DeleteIcon onClick={this.deleteEvent(event)} className={classes.icon} style={{cursor:'pointer'}} /></StyledTableCell>
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table></TableContainer>
                    </CardContent>
                    <CardActionArea>
                        <Button onClick={this.addEvent}>Lägg till evenemang</Button>
                    </CardActionArea>
                </Card>
            </Grid>
        </Grid>
        {this.state && this.state.showDeleteConfirmation && this.renderConfirmationDialog()}
        </Fragment>;
    }

    renderConfirmationDialog() {
        return <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">Är du säker på att du vill ta bort arrangemanget?</DialogTitle>
            <DialogActions>
                <Button variant="contained" autoFocus onClick={this.handleDeleteCancel} color="primary">
                    Avbryt
          </Button>
                <Button variant="contained" onClick={this.handleDeleteOk} color="primary">
                    Ja, ta bort
          </Button>
            </DialogActions>
        </Dialog>;
    }
    handleDeleteCancel = () => {
        this.setState({ deleteConfirmation: null, showDeleteConfirmation: false });
    }

    handleDeleteOk = () => {
        this.setState({loading:true});
        var back = new ApiBackend();
        back.deleteEvent(this.state.deleteConfirmation).then((res) => {
            if (res){
                back.listEvents().then((events) => {
                    this.setState({deleteConfirmation: null, showDeleteConfirmation: false, events: events, loading:false });
                });
            }
            else 
            {
                alert("Kunde inte ta bort arrangemang!");
            }
        })
        
    }
}



const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
}
);

const tableHeadStyles = ({ palette, spacing }: Theme) => createStyles({
    head: {
        background: palette.primary.main,
        color: palette.primary.contrastText,
    }
});

const StyledTableCell = withStyles(tableHeadStyles)(TableCell);

export default withRouter(withStyles(useStyles)(ManageEvents));