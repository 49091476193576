/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import React, { ChangeEvent, Fragment } from 'react'
import FirebaseContext from '../providers/Firebase/context';
import { Typography, Card, CardHeader, CardContent, Theme, WithStyles, withStyles, createStyles, TextField, Button, Grid, CardActionArea, LinearProgress, CircularProgress } from '@material-ui/core';
import { Profile } from '../model/Profile';
import { ApiBackend } from '../providers/apibackend';
import { EntryStatistics } from '../model/EntryStatistics';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Firebase from '../providers/Firebase/firebase';
import BarChart from './Common/BarChart';
import { OrderStatistics } from '../model/OrderStatistics';

interface State {
    entryStatistics : EntryStatistics;
    orderStatistics : OrderStatistics;

    entryStatisticsLoading: boolean;
    orderStatisticsLoading: boolean;
}

/**
 * Dashboard for logged in users
 */
class LoggedIn extends React.Component<RouteComponentProps,State> {
    static contextType = FirebaseContext;

    constructor (props:RouteComponentProps) {
        super(props);
        this.state={entryStatistics:null,entryStatisticsLoading:true, orderStatistics: null, orderStatisticsLoading:true};
    }

    componentDidMount() {
        let back = new ApiBackend();
        this.setState({entryStatisticsLoading:true});
        back.getEntryStatistics().then((data)=>{
            this.setState({entryStatistics: data,entryStatisticsLoading:false});
        });
        back.getOrderStatistics().then((data)=>{
            this.setState({orderStatistics: data,orderStatisticsLoading:false});
        });
    }

    saveProfileChanges = () => {
    }

    onchange = (prop: string) => (ev: React.ChangeEvent<HTMLInputElement>) => {
        let o = {} as any;
        o[prop] = ev.target.value;
        this.setState(o);
    }

    goto = (page : string) => () => {
        this.props.history.push(page);
    }


    render() {
        let { classes } = this.props as any;
        return <FirebaseContext.Consumer>
            {(value: { firebase: Firebase, isSignedIn: boolean, profile : Profile}) => {
                return <div>
                    {value.isSignedIn && 
                    <Fragment>
                        <Typography variant="h4">
                        Välkommen {value.profile.firstName + " " + value.profile.lastName}
                    </Typography>
                    <Typography variant="body2">Hittar inte du det du söker efter, kontakta Sporten för att få tillgång</Typography></Fragment>
                    }

                    <Grid container className={classes.root} spacing={2}>
                        {value.profile.isInRole('manageentries') &&
                        <Grid item xs={12} sm={6}>
                            <Card style={{height:'100%'}}>
                                <CardHeader className={classes.cardHeader} title="Anmälningar" />
                                <CardContent>
                                    {this.state.entryStatisticsLoading ? <LinearProgress color="primary"/> : 
                                    <Fragment>
                                    Anmälningar idag: <b>{this.state.entryStatistics.entriesToday}</b><br/>
                                    Totalt antal anmälningar: <b>{this.state.entryStatistics.totalEntries}</b></Fragment>}
                                </CardContent>
                                <CardActionArea>
                                    <Button onClick={this.goto('/entries/manage')}>Hantera anmälningar</Button>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        }
                        {value.profile.isInRole('manageorders') &&
                        <Grid item xs={12} sm={6}>
                            <Card>
                                <CardHeader className={classes.cardHeader} title="Orders" />
                                <CardContent>
                                    {this.state.orderStatisticsLoading ? <CircularProgress color="secondary"/> : <BarChart id="orderChart" data={this.state.orderStatistics} />}
                                </CardContent>
                                <CardActionArea>
                                    <Button  onClick={this.goto('/orders/manage')}>Hantera orders</Button>
                                </CardActionArea>
                            </Card>
                        </Grid>}
                        {value.profile.isInRole('admin') &&
                        <Grid item xs={12} sm={6}>
                            <Card>
                                <CardHeader className={classes.cardHeader} title="Konton" />
                                <CardContent>
                                    Hantera konton och användare
                                </CardContent>
                                <CardActionArea>
                                    <Button  onClick={this.goto('/users/manage')}>Hantera konton</Button></CardActionArea>
                            </Card>
                        </Grid>}
                    </Grid>
                </div>

            }}
        </FirebaseContext.Consumer>
    }
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
}
);

export default withRouter(withStyles(useStyles)(LoggedIn));