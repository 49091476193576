import moment from "moment";
import * as yup from "yup";

const mandatoryMessage = "Obligatoriskt";

const editCampaignValidationSchema = yup
    .object()
    .shape(
        {
            "name": yup
                .string()
                .required(mandatoryMessage),
            "type": yup
                .string()
                .required(mandatoryMessage),
            "campaignStart": yup
                .date()
                .required(mandatoryMessage),
            "campaignEnd": yup
                .date()
                .test("is-greather", "Inte större än 'aktiv från'", function(value) {
                    const { campaignStart } = this.parent;
                    return moment(value).startOf('day').isSameOrAfter(moment(campaignStart).startOf('day'));
                })
                .required(mandatoryMessage),
        });

export default editCampaignValidationSchema;
