/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

 import React, {Fragment } from 'react'
 import FirebaseContext from '../../providers/Firebase/context';
 import { Card, CardHeader, Theme, createStyles, withStyles, Grid, CardActionArea, TableContainer, Paper, Table, TableHead, TableRow, TableBody, Hidden, TableCell, Dialog, DialogTitle, DialogActions, LinearProgress, Typography, TextField, CardContent } from '@material-ui/core';
 import { withRouter, RouteComponentProps } from 'react-router-dom';
 import { ApiBackend } from '../../providers/apibackend';
 import { Club } from '../../model/Club';
 import Checkbox from '@material-ui/core/Checkbox';
 import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ColDef, DataGrid, ValueFormatterParams } from '@material-ui/data-grid';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditClub from './EditClub';
 
 interface State {
     loading : boolean;
     clubs : Club[];
     editClub: Club;
     includeArchived : boolean;
     displayClubs : Club[];
     search: string;
 }
 
 class ManageClubs extends React.Component<RouteComponentProps, State> {
     static contextType = FirebaseContext;
     constructor(props : RouteComponentProps)
     {
         super(props);
         this.state = {loading: true, clubs: null, includeArchived: false, displayClubs: null, search: "", editClub: null};
     }
 
     componentDidMount() {
         this.setState({loading:true});
         let back = new ApiBackend();
         back.listClubs(this.state.includeArchived).then((clubs) => {
             this.setState({clubs : clubs, displayClubs: clubs, loading:false});
         });
     }

     reload = () => {
        this.setState({loading:true}, () => {
            let back = new ApiBackend();
            back.listClubs(this.state.includeArchived).then((clubs) => {
                this.setState({clubs : clubs, displayClubs: this.filterClubs(this.state.search, clubs), loading:false});
            });
        });
     }
 
     toggleArchived = () => {
         this.setState({includeArchived: !this.state.includeArchived, loading:true}, () => {
             let back = new ApiBackend();
             back.listClubs(this.state.includeArchived).then((clubs) => {
                 this.setState({clubs : clubs, displayClubs: clubs, loading:false});
             });
         });
     }
     addClub = () => {
        const club = {
            id: null,
            name:'',
            extRef:0,
            groupkey: '',
            groupname:'',
            isArchived: false,
            comment:''
        } as Club;

        this.setState({editClub: club});
     }

     editClub = (club: Club) => () => {
        this.setState({editClub: club});
     }

     onSearchChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (!ev.target.value || ev.target.value.length == 0) {
            this.setState({ displayClubs: this.state.clubs, search: '' });
        }

        else {
            this.setState({ displayClubs: this.filterClubs(ev.target.value,this.state.clubs), search: ev.target.value });
        }
     }
 
     private filterClubs(filter : string, clubs: Club[]) {
        if (!filter || filter.length==0)
        {
            return clubs;
        }
        else 
        {
            return clubs.filter(x => x.name.toLowerCase().startsWith(filter.toLowerCase()));
        }
     }

     render() {
         let { classes } = this.props as any;            
            const columns: ColDef[] = [
            { field: 'name', headerName: 'Namn', width: 300,             
            renderCell: (params : ValueFormatterParams) => { 
                return <Typography style={{textDecoration: 'underline', cursor:'pointer'}} onClick={this.editClub(params.data as any)}>{params.data.name}</Typography> }
            },
            {field: 'extRef', headerName: 'Ext Ref.', width: 100},
            {field: 'groupname', headerName: 'Distrikt', width: 250}
          ];


        return <Fragment><Grid container className={classes.root} spacing={2}>
             <Grid item xs={12}>
             <Card>
                <CardHeader className={classes.cardHeader} title={<Fragment><Typography variant="h5" style={{display:'inline'}}>Filtrera </Typography></Fragment>} />
                <CardContent>
                <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={6}>
                        <TextField autoComplete="off" style={{ flex: 1, margin: '0 3px' }} id="standard-basic" label="Sök" value={this.state.search} onChange={this.onSearchChange} fullWidth />
                    </Grid>
                    <Grid item xs={3}>
                    <FormControlLabel
                        control={<Checkbox checked={this.state.includeArchived} onChange={this.toggleArchived} name="includearchived" />}
                        label="Inkludera arkiverade"
                    />
                    </Grid>
                </Grid>
                </CardContent>
             </Card>
             </Grid>
            
            <Grid item xs={12}>
                <Card>
                    <CardHeader className={classes.cardHeader} title={<Fragment><Typography variant="h5" style={{display:'inline'}}>Klubbar </Typography>
                    <AddCircleIcon style={{display:'inline',cursor:'pointer', verticalAlign:'middle'}} onClick={this.addClub}/></Fragment>} />
                    <CardContent>
                    {this.state.loading &&
                        <LinearProgress color="secondary"/>
                        }                        
                         <div style={{ height: 400, width: '100%' }}>
                        
                         <DataGrid loading={this.state.loading} rows={ this.state.displayClubs ?? []} columns={columns} pageSize={100} />
                        
                        </div>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
        {this.state.editClub && this.renderEditClub()}
         </Fragment>;
     }

     renderEditClub() {
        return <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="lg"
        aria-labelledby="confirmation-dialog-title"
        open={true}
    >
        <EditClub club={this.state.editClub} close={() => {this.setState({editClub: null});this.reload();}}  />
    </Dialog>;
     }
 }
 
 
 
 const useStyles = ({ palette, spacing }: Theme) => createStyles({
     cardHeader: {
         background: palette.secondary.main,
         color: palette.secondary.contrastText,
         padding: 3
     },
     photo: {
         height: '30px',
         verticalAlign: 'middle',
         borderRadius: '10px'
     },
     root: {
 
     },
     form: {
         '& > *': {
             margin: spacing(1),
             width: '25ch',
         },
         '& label.Mui-focused': {
             color: palette.secondary.main,
         },
         '& .MuiInput-underline:after': {
             borderBottomColor: palette.secondary.main,
         },
     }
 }
 );
 
 const tableHeadStyles = ({ palette, spacing }: Theme) => createStyles({
     head: {
         background: palette.primary.main,
         color: palette.primary.contrastText,
     }
 });
 
 const StyledTableCell = withStyles(tableHeadStyles)(TableCell);
 
 export default withRouter(withStyles(useStyles)(ManageClubs));