/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import React from 'react';
import { withRouter, RouteChildrenProps } from 'react-router-dom';
import { Theme, createStyles, Grid, WithStyles, Card, CardHeader, CardContent, TextField, Button, withStyles, CardActionArea } from '@material-ui/core';
import moment from 'moment';
import {Event} from '../../model/Event';
import { ApiBackend } from '../../providers/apibackend';
import uuid from 'react-uuid';

interface State {
    event: Event;
}

/** AddEvent -- Creates a new event
 * - Also keep the instance of firebase (Provider Context)
 * - Keep the route mapping to components
 */
class AddEvent extends React.Component<WithStyles & RouteChildrenProps, any> {

    state = { event: { id: uuid(), name: '', startDate: moment().format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD') } };

    constructor(props: any) {
        super(props);
    }

  

    onChange = (prop: string) => (ev : React.ChangeEvent<HTMLInputElement>) => 
    {
        var e = this.state.event as any;
        e[prop] = ev.target.value;
        this.setState({event: e});
    }
 
    createEvent = () => {
        let back = new ApiBackend();
        back.createOrUpdateEvent(this.state.event).then((res) => {
            if (res)
            {
                this.props.history.push('/events/manage');            
            }
            else 
            {
                alert("Kunde inte skapa event!");
            }
        });
    }

    render() {
        let { classes } = this.props;
        return <div>
            <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader className={classes.cardHeader} title="Skapa nytt evenemang" />
                        <CardContent>
                            <form className={classes.form} noValidate autoComplete="off">
                                <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                                    <Grid item xs={12}>
                                        <TextField style={{ flex: 1, margin: '0 3px' }} id="standard-basic" label="Evenemangsnamn" value={this.state.event.name} onChange={this.onChange('name')} fullWidth />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField type="date" style={{ flex: 1, margin: '0 3px' }} id="standard-basic" value={this.state.event.startDate} onChange={this.onChange('startDate')} label="Startdatum" fullWidth />
                                    </Grid>
                                    <Grid item xs={12} sm={6} >
                                        <TextField type="date" style={{ flex: 1, margin: '0 3px' }} id="standard-basic" value={this.state.event.endDate} onChange={this.onChange('endDate')} label="Slutdatum" fullWidth />
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                        <CardActionArea>
                            <Button onClick={this.createEvent}>Skapa evenemang</Button>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid></div>;
    }
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
}
);


export default withStyles(useStyles)(withRouter(AddEvent));