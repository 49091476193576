/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import React from 'react';
import { Theme, createStyles, Grid, WithStyles, Card, CardHeader, CardContent, TextField, Button, withStyles, CardActionArea, Table, TableContainer, TableRow, Paper, TableCell, TableBody, Dialog, DialogActions, DialogTitle, DialogContent, Typography } from '@material-ui/core';
import uuid from 'react-uuid';
import TableHead from '@material-ui/core/TableHead/TableHead';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { ProductVariant } from '../../model/ProductVariant';
import { Product } from '../../model/Product';
import { ProductOccation } from '../../model/ProductVariantOccation';
import moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import EditPriceGroups from './EditPriceGroups';
import { ProductVariantPriceGroup } from '../../model/ProductVariantPriceGroup';

interface Props {
    product: Product;
    variantsChanged: () => void;
    editLocale: string;
}
interface State {
    loading: boolean;
    haveChanges: boolean;
    showDeleteConfirmation: boolean;
    deleteConfirmation: ProductVariant;
    deleteOccationConfirmation: ProductOccation;
    addVariant: boolean;
    addEditVariant: ProductVariant;
    addVariantOccation: boolean;
    addEditVariantOccation: ProductOccation;
}

/** 
 * Lists variants for product
 */
class ProductVariants extends React.Component<WithStyles & Props, State> {

    state = { deleteOccationConfirmation: null as ProductOccation, addEditVariantOccation: null as ProductOccation, addVariantOccation: false, loading: false, haveChanges: false, showDeleteConfirmation: false, addEditVariant: null as ProductVariant, deleteConfirmation: null as ProductVariant, addVariant: false };

    render() {
        const { classes } = this.props;

        return <>
            <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader className={classes.cardHeader} title="Produktvarianter" />
                        <CardContent>
                            <TableContainer component={Paper}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>Namn</StyledTableCell>
                                            <StyledTableCell>Beskrivning</StyledTableCell>
                                            {this.props.product.variants && this.props.product.variants.length > 0 && this.props.product.variants[0].priceGroups.map((pg, idx) => {
                                                return <StyledTableCell key={pg.id}>{this.props.editLocale == "EN" ? pg.Name_En : pg.Name}</StyledTableCell>
                                            })}
                                            {this.props.product.type != "busticket" && this.props.product.type != "skipass" &&
                                                <StyledTableCell>Antal kvar att nyttja</StyledTableCell>
                                            }
                                            <StyledTableCell></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.props.product.variants && this.props.product.variants.map((variant, idx) => {
                                            return <TableRow key={"productvariant_"+idx}>
                                                <StyledTableCell>{this.props.editLocale == "EN" ? variant.Name_En : variant.Name}</StyledTableCell>
                                                <StyledTableCell>{this.props.editLocale == "EN" ? variant.Description_En : variant.Description}</StyledTableCell>
                                                {variant.priceGroups.map((pg, idx) => {
                                                    return <StyledTableCell key={pg.id}>{pg.price}</StyledTableCell>
                                                })}
                                                {this.props.product.type != "busticket" && this.props.product.type != "skipass" &&
                                                    <StyledTableCell>
                                                        {variant.itemsAvailable}</StyledTableCell>
                                                }
                                                <StyledTableCell align="right">
                                                    <EditIcon onClick={this.editVariant(variant)} className={classes.icon} style={{ cursor: 'pointer' }} />
                                                    <DeleteIcon onClick={this.removeVariant(idx)} className={classes.icon} style={{ cursor: 'pointer' }} />
                                                </StyledTableCell>
                                            </TableRow>
                                        })}
                                    </TableBody>
                                </Table></TableContainer>

                            {this.state && this.state.addEditVariant &&
                                this.renderEditVariantDialog()
                            }
                            {this.state && this.state.addEditVariantOccation &&
                                this.renderEditVariantOpportunityDialog()
                            }
                        </CardContent>
                        <CardActionArea component="a">
                            <Button onClick={this.addVariant}>Lägg till variant</Button>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
            {this.state && this.state.showDeleteConfirmation &&
                this.renderConfirmationDialog()
            }
            {this.state && this.state.deleteOccationConfirmation &&
                this.renderConfirmationDeleteOccationDialog()
            }
        </>
    }

    private renderEditVariantDialog() {
        let { classes } = this.props;

        let hasVariantPurchases = false;

        return <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            fullWidth
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">{this.state.addVariant ? "Lägg till produktvariant" : "Editera produktvariant"}</DialogTitle>
            <DialogContent>
                <Grid container className={classes.root} spacing={2} style={{ width: '100%', padding: 3 }}>
                    <Grid item xs={12}>
                        <TextField value={this.props.editLocale == "EN" ? this.state.addEditVariant.Name_En : this.state.addEditVariant.Name} label="Namn" onChange={this.changeEditField(this.props.editLocale == "EN" ? 'Name_En' : 'Name')} fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField value={this.props.editLocale == "EN" ? this.state.addEditVariant.Description_En : this.state.addEditVariant.Description} label="Beskrivning" onChange={this.changeEditField(this.props.editLocale == "EN" ? 'Description_En' : 'Description')} fullWidth />
                    </Grid>
                    <Card style={{ marginTop: 10 }}>
                        <CardHeader className={classes.cardHeader} title={"Prisgrupper"} />
                        <CardContent>
                            <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                                <EditPriceGroups {...this.props}
                                    forRace={false}
                                    onChange={this.handlePriceGroupChange}
                                    priceGroups={this.state.addEditVariant.priceGroups}>
                                </EditPriceGroups>
                            </Grid>
                        </CardContent>
                        <CardActionArea component="a">
                            <Button onClick={() => {
                                let variant = this.state.addEditVariant;
                                let pgs = variant.priceGroups;
                                pgs.push({ Name: '(Ny)', Name_En: '', price: 0, Description: '', Description_En: '', LongName: '', LongName_En: '', id: uuid(), type: '', validFrom: null, validTo: null, validOnlineOnly: false, validPOSOnly: false, maxAge: null, isPublic: true });
                                variant.priceGroups = pgs;
                                this.setState({ addEditVariant: variant });
                            }} color="primary" variant="contained">Lägg till prisgrupp</Button>
                            <Button onClick={() => {
                                for (let i = 0; i < this.props.product.variants.length; i++) {
                                    if (this.props.product.variants[i].Id != this.state.addEditVariant.Id) {
                                        let pgs = [];
                                        for (let pg = 0; pg < this.state.addEditVariant.priceGroups.length; pg++) {
                                            let matchingPricegroupsByName = this.props.product.variants[i].priceGroups.filter(x => x.Name == this.state.addEditVariant.priceGroups[pg].Name);
                                            //Does it exist already?

                                            if (matchingPricegroupsByName && matchingPricegroupsByName.length > 0) {
                                                //Match, copy properties
                                                let priceGroup = matchingPricegroupsByName[0];
                                                priceGroup.Name_En = this.state.addEditVariant.priceGroups[pg].Name_En;
                                                priceGroup.LongName = this.state.addEditVariant.priceGroups[pg].LongName;
                                                priceGroup.LongName_En = this.state.addEditVariant.priceGroups[pg].LongName_En;
                                                priceGroup.Description = this.state.addEditVariant.priceGroups[pg].Description;
                                                priceGroup.Description_En = this.state.addEditVariant.priceGroups[pg].Description_En;
                                                priceGroup.price = this.state.addEditVariant.priceGroups[pg].price;
                                                priceGroup.type = this.state.addEditVariant.priceGroups[pg].type;
                                                priceGroup.validFrom = this.state.addEditVariant.priceGroups[pg].validFrom;
                                                priceGroup.validTo = this.state.addEditVariant.priceGroups[pg].validTo;
                                                priceGroup.validOnlineOnly = this.state.addEditVariant.priceGroups[pg].validOnlineOnly;
                                                priceGroup.validPOSOnly = this.state.addEditVariant.priceGroups[pg].validPOSOnly;
                                                priceGroup.maxAge = this.state.addEditVariant.priceGroups[pg].maxAge;
                                                priceGroup.isPublic = this.state.addEditVariant.priceGroups[pg].isPublic;
                                                pgs.push(priceGroup);
                                            }
                                            else {
                                                let priceGroup = {
                                                    Name: this.state.addEditVariant.priceGroups[pg].Name,
                                                    Name_En: this.state.addEditVariant.priceGroups[pg].Name_En,
                                                    LongName: this.state.addEditVariant.priceGroups[pg].LongName,
                                                    LongName_En: this.state.addEditVariant.priceGroups[pg].LongName_En,
                                                    Description: this.state.addEditVariant.priceGroups[pg].Description,
                                                    Description_En: this.state.addEditVariant.priceGroups[pg].Description_En,
                                                    price: this.state.addEditVariant.priceGroups[pg].price,
                                                    type: this.state.addEditVariant.priceGroups[pg].type,
                                                    validFrom: this.state.addEditVariant.priceGroups[pg].validFrom,
                                                    validTo: this.state.addEditVariant.priceGroups[pg].validTo,
                                                    validOnlineOnly: this.state.addEditVariant.priceGroups[pg].validOnlineOnly,
                                                    validPOSOnly: this.state.addEditVariant.priceGroups[pg].validPOSOnly,
                                                    maxAge: this.state.addEditVariant.priceGroups[pg].maxAge,
                                                    isPublic: this.state.addEditVariant.priceGroups[pg].isPublic,
                                                    id: uuid()
                                                };
                                                pgs.push(priceGroup);
                                            }
                                        }

                                        this.props.product.variants[i].priceGroups = pgs;
                                    }
                                }

                                this.props.variantsChanged();

                            }} color="primary" variant="contained" disabled={hasVariantPurchases}>Kopiera dessa prisgrupper till övriga varianter</Button>
                        </CardActionArea>
                    </Card>
                    <Card style={{ margin: 10 }}>
                        <CardHeader className={classes.cardHeader} title="Tillfällen" />
                        <CardContent>
                            <TableContainer component={Paper}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>Namn</StyledTableCell>
                                            <StyledTableCell>Beskrivning</StyledTableCell>
                                            <StyledTableCell>Tidpunkt</StyledTableCell>
                                            <StyledTableCell>Antal kvar att nyttja</StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.addEditVariant.occations && this.state.addEditVariant.occations.map((occ, idx) => {
                                            return <TableRow>
                                                <StyledTableCell>{this.props.editLocale == "EN" ? occ.name_EN : occ.name}</StyledTableCell>
                                                <StyledTableCell>{this.props.editLocale == "EN" ? occ.description_EN : occ.description}</StyledTableCell>
                                                <StyledTableCell>{moment(occ.time).format('YYYY-MM-DD HH:mm')}</StyledTableCell>
                                                <StyledTableCell>{occ.seatsAvailable - occ.seatsTaken}</StyledTableCell>
                                                <StyledTableCell align="right">
                                                    <EditIcon onClick={this.editVariantOccation(occ)} className={classes.icon} style={{ cursor: 'pointer' }} />
                                                    <DeleteIcon onClick={this.removeOccation(occ)} className={classes.icon} style={{ cursor: 'pointer' }} />
                                                </StyledTableCell>
                                            </TableRow>
                                        })}
                                    </TableBody>
                                </Table></TableContainer>
                        </CardContent>
                        <CardActionArea component="a">
                            <Button onClick={this.addVariantOccation}>Lägg till tillfälle</Button>
                            <Button onClick={() => {
                                for (let i = 0; i < this.props.product.variants.length; i++) {
                                    if (this.props.product.variants[i].Id != this.state.addEditVariant.Id) {
                                        let occations = [];
                                        for (let pg = 0; pg < this.state.addEditVariant.occations.length; pg++) {
                                            let sourcePgNames = this.state.addEditVariant.occations[pg].availablePriceGroups && this.state.addEditVariant.occations[pg].availablePriceGroups.length > 0 ?
                                                this.state.addEditVariant.priceGroups.filter(x => this.state.addEditVariant.occations[pg].availablePriceGroups.indexOf(x.id) >= 0).map(x => x.Name) : null;

                                            let matchingOccationsByName = this.props.product.variants[i].occations ? this.props.product.variants[i].occations.filter(x => x.name == this.state.addEditVariant.occations[pg].name) : null;
                                            let mappedPriceGroups = this.props.product.variants[i].priceGroups && sourcePgNames && sourcePgNames.length > 0 ?
                                                this.props.product.variants[i].priceGroups.filter(x => sourcePgNames.indexOf(x.Name) >= 0).map(x => x.id) : null;


                                            //Does it exist already?
                                            if (matchingOccationsByName && matchingOccationsByName.length > 0) {


                                                //Match, copy properties
                                                let occation = matchingOccationsByName[0];
                                                occation.name_EN = this.state.addEditVariant.occations[pg].name_EN;
                                                occation.description = this.state.addEditVariant.occations[pg].description;
                                                occation.description_EN = this.state.addEditVariant.occations[pg].description_EN;
                                                occation.availablePriceGroups = mappedPriceGroups;
                                                occation.seatsAvailable = this.state.addEditVariant.occations[pg].seatsAvailable;
                                                occation.seatsTaken = this.state.addEditVariant.occations[pg].seatsTaken;
                                                occation.time = this.state.addEditVariant.occations[pg].time;
                                                occations.push(occation);
                                            }
                                            else {
                                                let occation = {
                                                    name: this.state.addEditVariant.occations[pg].name,
                                                    name_EN: this.state.addEditVariant.occations[pg].name_EN,
                                                    description: this.state.addEditVariant.occations[pg].description,
                                                    description_EN: this.state.addEditVariant.occations[pg].description_EN,
                                                    availablePriceGroups: mappedPriceGroups,
                                                    seatsAvailable: this.state.addEditVariant.occations[pg].seatsAvailable,
                                                    seatsTaken: this.state.addEditVariant.occations[pg].seatsTaken,
                                                    time: this.state.addEditVariant.occations[pg].time,
                                                    id: uuid()
                                                };
                                                occations.push(occation);
                                            }
                                        }

                                        this.props.product.variants[i].occations = occations;
                                    }
                                }
                                alert("Tillfällen kopierade till övriga varianter");

                                this.props.variantsChanged();

                            }} color="primary" variant="contained" disabled={hasVariantPurchases}>Kopiera dessa tillfällen till övriga varianter</Button>
                        </CardActionArea>
                    </Card>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" autoFocus onClick={this.cancelAddEditVariant} color="primary">
                    Avbryt
                </Button>
                <Button variant="contained" onClick={this.create} color="primary">
                    {this.state.addVariant ? "Lägg till" : "Spara"}
                </Button>
            </DialogActions>
        </Dialog>;
    }

    private renderEditVariantOpportunityDialog() {
        const { classes } = this.props;

        return <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">{this.state.addVariantOccation ? "Lägg till tillfälle" : "Editera tillfälle"}</DialogTitle>
            <DialogContent>
                <Grid container className={classes.root} spacing={2} style={{ width: '100%', padding: 3 }}>
                    <Grid item xs={12}>
                        <TextField value={this.props.editLocale == "EN" ? this.state.addEditVariantOccation.name_EN : this.state.addEditVariantOccation.name} label="Namn" onChange={this.changeOccationEditField(this.props.editLocale == "EN" ? 'name_EN' : 'name')} fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField value={this.props.editLocale == "EN" ? this.state.addEditVariantOccation.description_EN : this.state.addEditVariantOccation.description} label="Beskrivning" onChange={this.changeOccationEditField(this.props.editLocale == "EN" ? 'description_EN' : 'description')} fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField type="datetime-local" value={moment(this.state.addEditVariantOccation.time).format('YYYY-MM-DDTHH:mm')} label="Tidpunkt"
                            onChange={this.changeOccationEditField('time')} fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField type="number" value={this.state.addEditVariantOccation.seatsAvailable} label="Tillgängliga platser" onChange={this.changeOccationEditFieldNumber('seatsAvailable')} fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2">Tillgängliga prisgrupper (ingen = alla)</Typography>
                        {this.state.addEditVariant.priceGroups.map((pg, idx) => {
                            return <FormControlLabel
                                control={
                                    <Checkbox key={pg.id} checked={this.isPriceGroupAvailable(this.state.addEditVariantOccation, pg.id)} onChange={this.togglePriceGroupAvailable(pg.id)} name="gilad" />
                                }
                                label={pg.Name}
                            />
                        })}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" autoFocus onClick={this.cancelAddEditVariantOccation} color="primary">
                    Avbryt
                </Button>
                <Button variant="contained" onClick={this.createOccation} color="primary">
                    {this.state.addVariant ? "Lägg till" : "Spara"}
                </Button>
            </DialogActions>
        </Dialog>;
    }

    private renderConfirmationDialog() {
        return <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">Är du säker på att du vill ta bort produktvarianten?</DialogTitle>
            <DialogActions>
                <Button variant="contained" autoFocus onClick={this.handleDeleteCancel} color="primary">
                    Avbryt
                </Button>
                <Button variant="contained" onClick={this.handleDeleteOk} color="primary">
                    Ja, ta bort
                </Button>
            </DialogActions>
        </Dialog>;
    }

    private renderConfirmationDeleteOccationDialog() {
        return <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">Är du säker på att du vill ta bort tillfället?</DialogTitle>
            <DialogActions>
                <Button variant="contained" autoFocus onClick={() => { this.setState({ deleteOccationConfirmation: null }) }} color="primary">
                    Avbryt
                </Button>
                <Button variant="contained" onClick={this.handleDeleteOccationOk} color="primary">
                    Ja, ta bort
                </Button>
            </DialogActions>
        </Dialog>;
    }

    private isPriceGroupAvailable(occation: ProductOccation, priceGroupId: string) {
        if (!occation.availablePriceGroups || occation.availablePriceGroups.length == 0) {
            return false;
        }

        return occation.availablePriceGroups.indexOf(priceGroupId) >= 0;
    }

    private editVariant = (variant: ProductVariant) => () => {
        this.setState({ addEditVariant: variant, addVariant: false });
    };

    private editVariantOccation = (occation: ProductOccation) => () => {
        this.setState({ addEditVariantOccation: occation, addVariantOccation: false });
    };

    private removeVariant = (idx: number) => () => {
        this.setState({ deleteConfirmation: this.props.product.variants[idx], showDeleteConfirmation: true });
    };

    private removeOccation = (occation: ProductOccation) => () => {
        this.setState({ deleteOccationConfirmation: occation });
    };

    private addVariant = () => {
        const newVar = ProductVariant.createNew();
        if (this.props.product.type == "skipass") {
            newVar.priceGroups = [
                { id: uuid(), Name: "Dagkort", Name_En: "Day Pass", LongName_En: '', Description_En: '', LongName: '', Description: '', price: 0, type: '1', validFrom: null, validTo: null, validOnlineOnly: false, validPOSOnly: false, maxAge: null, isPublic: true },
                { id: uuid(), Name: "Veckokort", Name_En: 'Week Pass', LongName_En: '', Description_En: '', LongName: '', Description: '', price: 0, type: '7', validFrom: null, validTo: null, validOnlineOnly: false, validPOSOnly: false, maxAge: null, isPublic: true },
                { id: uuid(), Name: "Säsongskort", Name_En: 'Season Pass', LongName_En: '', Description_En: '', LongName: '', Description: '', price: 0, type: null, validFrom: null, validTo: null, validOnlineOnly: false, validPOSOnly: false, maxAge: null, isPublic: true }
            ];
        }
        else 
        {
            newVar.priceGroups = [
                { id: uuid(), Name: "Normal", Name_En: "Normal", LongName_En: '', Description_En: '', LongName: '', Description: '', price: 0, type: null, validFrom: moment().format("yyyy-mm-dd"), validTo: null, validOnlineOnly: false, validPOSOnly: false, maxAge: null, isPublic: true },
                { id: uuid(), Name: "Direktanmälan", Name_En: 'Direct entry', LongName_En: '', Description_En: '', LongName: '', Description: '', price: 0, type: null, validFrom: moment().format("yyyy-mm-dd"), validTo: null, validOnlineOnly: false, validPOSOnly: false, maxAge: null, isPublic: true },
                { id: uuid(), Name: "Friplats", Name_En: 'Free', LongName_En: '', Description_En: '', LongName: '', Description: '', price: 0, type: null, validFrom: moment().format("yyyy-mm-dd"), validTo: null, validOnlineOnly: false, validPOSOnly: false, maxAge: null, isPublic: false }
            ];
        }
        this.setState({ addVariant: true, addEditVariant: newVar });
    };

    private addVariantOccation = () => {
        const newVar = { id: uuid(), name: '', name_EN: '', description: '', description_EN: '', seatsAvailable: 10000, seatsTaken: 0, time: new Date() } as ProductOccation;
        this.setState({ addVariantOccation: true, addEditVariantOccation: newVar });
    };

    private changeEditField = (prop: string) => (ev: React.ChangeEvent<HTMLInputElement>) => {
        const o = this.state.addEditVariant as any;
        o[prop] = ev.target.value;
        this.setState({ addEditVariant: o });
    };

    private changeOccationEditField = (prop: any) => (ev: React.ChangeEvent<HTMLInputElement>) => {
        const o = this.state.addEditVariantOccation as any;
        o[prop] = ev.target.value;
        this.setState({ addEditVariantOccation: o });
    };

    private changeOccationEditFieldNumber = (prop: any) => (ev: React.ChangeEvent<HTMLInputElement>) => {
        const o = this.state.addEditVariantOccation as any;
        o[prop] = +ev.target.value;
        this.setState({ addEditVariantOccation: o });
    };

    private cancelAddEditVariant = () => {
        this.setState({ addVariant: false, addEditVariant: null, haveChanges: false });
    };

    private  cancelAddEditVariantOccation = () => {
        this.setState({ addVariantOccation: false, addEditVariantOccation: null, haveChanges: false });
    };

    private create = () => {
        const e = this.props.product;
        if (!e.variants) {
            e.variants = [];
        }

        if (this.state.addVariant) {
            e.variants.push(this.state.addEditVariant);
        }

        this.setState({ addVariant: false, addEditVariant: null, haveChanges: true });
        this.props.variantsChanged();
    };

    private createOccation = () => {
        const e = this.state.addEditVariant
        if (!e.occations) {
            e.occations = [];
        }

        if (this.state.addVariantOccation) {
            e.occations.push(this.state.addEditVariantOccation);
        }

        this.setState({ addVariantOccation: false, addEditVariantOccation: null, haveChanges: true });
        this.props.variantsChanged();
    };

    private togglePriceGroupAvailable = (priceGroupId: string) => (ev: React.ChangeEvent<HTMLInputElement>) => {
        const occation = this.state.addEditVariantOccation;
        if (!occation.availablePriceGroups || occation.availablePriceGroups.length == 0 && ev.target.checked) {
            occation.availablePriceGroups = [priceGroupId];
        } else {
            const idx = occation.availablePriceGroups.indexOf(priceGroupId);
            if (ev.target.checked && idx < 0) {
                occation.availablePriceGroups.push(priceGroupId);
            }

            if (!ev.target.checked && idx >= 0) {
                occation.availablePriceGroups.splice(idx, 1);
            }
        }

        this.setState({ addEditVariantOccation: occation });
    };

    private handlePriceGroupChange = (priceGroups: ProductVariantPriceGroup[]) => {
        const o = this.state.addEditVariant;
        o.priceGroups = priceGroups;
        this.setState({ addEditVariant: o, haveChanges: true });
    };

    private handleDeleteCancel = () => {
        this.setState({ deleteConfirmation: null, showDeleteConfirmation: false });
    };

    private handleDeleteOk = () => {
        const e = this.props.product;
        e.variants = e.variants.filter(x => x.Id != this.state.deleteConfirmation.Id);
        this.setState({ deleteConfirmation: null, showDeleteConfirmation: false });
        this.props.variantsChanged();
        this.forceUpdate();
    };

    private handleDeleteOccationOk = () => {
        const variant = this.state.addEditVariant;
        variant.occations = variant.occations.filter(x => x.id != this.state.deleteOccationConfirmation.id);
        this.setState({ deleteOccationConfirmation: null });
        this.props.variantsChanged();
        this.forceUpdate();
    };
}

const tableHeadStyles = ({ palette, spacing }: Theme) => createStyles({
    head: {
        background: palette.primary.main,
        color: palette.primary.contrastText,
    }
});

const StyledTableCell = withStyles(tableHeadStyles)(TableCell);

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    icon: {
        verticalAlign: 'middle',
        cursor: 'pointer'
    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
}
);

export default withStyles(useStyles)(ProductVariants);
