import { Entry } from "./Entry";
import { OrderCode } from "./OrderCode";
import { OrderContact } from "./OrderContact";
import { OrderItem } from "./OrderItem";

export const OrderStatuses = [{name: "Ny", value: "NEW"}, {name: "Ej Betald", value:'PAYMENT_INITIALIZED'}, {name: "Klar", value: "Completed"},{name: "Återbetald", value: "REVERSED"},{name: "Avbruten", value: "Cancelled"},{name: "Borttagen", value: "DELETED"},{name: "SportsProOrder", value: "SPORTSPROSHADOW"}];
export class Order {
    static translateStatus(status: string) {
        let s= OrderStatuses.filter(x=>x.value == status);
        return s.length > 0 ? s[0].name : "OKÄND";
    }
    
    id : string;
    publicId : string;
    items : OrderItem[];
    Status : string;
    contact: OrderContact;
    entries: Entry[];
    discountCodes: OrderCode[];
    totalSum : number;
    OrderDate: Date;
    LastUpdate: Date;

}

export class ListOrder extends Order {
    Created : Date;
    LastChange : Date;
}