import { WithStyles, createStyles, withStyles, Theme, Button, Card, CardContent, CardHeader, CircularProgress, CardActions } from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import { RouteChildrenProps, withRouter } from "react-router-dom";
import { Entry } from "../../../model/Entry";
import { Product } from "../../../model/Product";
import { ApiBackend } from "../../../providers/apibackend";
import FirebaseContext from "../../../providers/Firebase/context";
import DirtyPageChecker from "../../Common/DirtyPageChecker";
import ManageOrder from "../../Orders/ManageOrder";
import EditPersonDialog from "../Person/EditPersonDialog";
import EditEntryFormContent from "./EditEntryFormContent";
import validationSchema from "./EntryValidationSchema";

interface IProps extends RouteChildrenProps<{ id: string }> {
}

interface IState {
    loading: boolean;
    entry: Entry;
    entryProduct: Product;
    showOrder: boolean;
    showPerson: boolean;
}

class EditEntry extends React.Component<WithStyles & IProps, IState> {
    static contextType = FirebaseContext;

    private readonly api: ApiBackend;

    constructor(props: WithStyles & IProps) {
        super(props);
        this.state = { entry: null, entryProduct: null, showOrder: false, showPerson: false, loading: true };
        this.api = new ApiBackend();
        this.loadEntry(this.props.match.params.id);
    }

    render() {
        const { classes } = this.props;
        const { showOrder, showPerson, loading } = this.state;

        return (
            <>
                {loading &&
                    <CircularProgress color="primary" />
                }
                {this.state.entry &&
                    <Formik
                        initialValues={this.state.entry}
                        validationSchema={validationSchema}
                        onReset={(values): void => this.setState({ entry: values })}
                        onSubmit={async (values, { resetForm }) => {
                            const res = await this.api.saveEntry(values);
                            
                            if (res) {
                                await this.loadEntry(this.props.match.params.id);
                                resetForm({ values: this.state.entry });
                            }
                        }}
                    >
                        {formik => {
                            const { isValid, dirty, handleSubmit, handleReset, isSubmitting } = formik;
                            const isSaveDisabled = !dirty || !isValid || isSubmitting;

                            return (
                                <DirtyPageChecker isDirty={dirty} getTitle={this.pageTitle} {...this.props}>
                                    <form autoComplete="off" onSubmit={handleSubmit}>
                                        <Card>
                                            <CardHeader style={{ position: 'relative' }} title={<>
                                                {this.cardHeader()}
                                                <Button style={{ position: 'absolute', right: 8, top: 8 }}
                                                    variant="contained"
                                                    onClick={() => {
                                                        this.setState({ showOrder: true });
                                                    }}>
                                                    Visa order
                                                </Button>
                                            </>} />
                                            <CardContent className={classes.form}>
                                                <EditEntryFormContent {...formik} 
                                                    classes={classes} 
                                                    entryProduct={this.state.entryProduct}
                                                    onShowPerson={() => {
                                                        this.setState({ showPerson: true });
                                                    }}
                                                />
                                            </CardContent>
                                            <CardActions>
                                                <Button type="submit" color="secondary" disabled={isSaveDisabled} variant="contained">Spara</Button>
                                                <Button style={{ marginLeft: 10 }} color="secondary" disabled={!dirty} variant="contained" onClick={handleReset}>Avbryt</Button>
                                            </CardActions>
                                        </Card>
                                    </form>
                                </DirtyPageChecker>
                            )
                        }}

                    </Formik>
                }
                {showOrder &&
                    <ManageOrder orderId={this.state.entry.orderId} close={
                        () => {
                            this.setState({ showOrder: false });
                        }}
                    />
                }
                {showPerson &&
                    <EditPersonDialog
                        personId={this.state.entry.personId}
                        onAbortEdit={() => {
                            this.setState({ showPerson: false });
                        }}
                        onSave={() => {
                            this.setState({ showPerson: false });
                        }}
                    />
                }
            </>
        )
    }

    private loadEntry = async (id: string): Promise<void> => {
        return new Promise(async (resolve) => {
            const entry = await this.api.getEntry(id);
            const entryProduct = await this.api.getProduct(entry.productId);
            this.setState({
                loading: false,
                entry: entry,
                entryProduct: entryProduct
            }, () => {
                resolve();
            });
        });
        
    };

    private pageTitle = (): string => {
        const { entry, entryProduct } = this.state;

        return `Anmälan: ${entry.firstName} ${entry.lastName} - ${entryProduct.name}`;
    };

    private cardHeader = (): string => {
        const { entry, entryProduct } = this.state;

        return `Hantera anmälan: ${entry.firstName} ${entry.lastName} - ${entryProduct.name} / ${entryProduct.variants?.find(x => x.Id === entry.variantId)?.Name}`;
    };
}


const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withRouter(withStyles(useStyles)(EditEntry));
