/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import React, { Fragment } from 'react';
import { withRouter, RouteChildrenProps } from 'react-router-dom';
import { Theme, createStyles, Grid, WithStyles, Card, CardHeader, CardContent, TextField, Button, withStyles, CardActionArea, LinearProgress, Container, Select, MenuItem } from '@material-ui/core';
import moment from 'moment';
import { ApiBackend } from '../../providers/apibackend';
import { CodeHolder } from '../../model/CodeHolder';
import { Code } from '../../model/Code';
import Codes from './Codes';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CodeProducts from './CodeProducts';
import { Product } from '../../model/Product';
import { CodeProductImplementation } from '../../model/CodeProductImplementation';
import uuid from 'react-uuid';


interface Props extends RouteChildrenProps<{id: string}> {
}

interface State {
    code : CodeHolder;
    loading : boolean;
    haveChanges:boolean;
    editCode : Code;
    products : Product[];
}


/** Edit  -- Edits an existing a new event
 * - Also keep the instance of firebase (Provider Context)
 * - Keep the route mapping to components
 */
class EditCode extends React.Component<WithStyles & Props, State> {

    state = { products: null as Product[], 
        code: null as CodeHolder, 
        loading:true,
         haveChanges: false, 
         editCode: null as Code, 
         addCodes: false, addNumCodes:1,addCodesMaxActivations:10};

    constructor(props: WithStyles & Props) {
        super(props);
        this.loadData();
        //this.updateProducts();


    }

    loadData = async () => {
        let backend = new ApiBackend();
        let prods = await backend.listProducts(false);
        let code = await backend.getCode(this.props.match.params.id);

            this.setState({products: prods, code:code , loading: false});

    }

    updateCode = () => {
        let backend = new ApiBackend();
        this.setState({loading: true});
        backend.getCode(this.props.match.params.id).then((code) => {
            this.setState({code: code, loading: false});
        });
    }

   


    addCode = (code: Code) => {
        this.setState({loading:true});
        let backend = new ApiBackend();
        backend.updateCode(this.state.code.id, code).then(resp => {
            if (resp)
            {
                backend.getCode(this.state.code.id).then((code) => {
                    this.setState({code: code, loading: false});
                });
            }
            else 
            {
                alert("Fel vid skapande av tävling!");       
            }

        }).catch((error) => {
            alert("Fel vid skapande av tävling!");
        });      
    }
    removeCode = (idx : number) => {
        this.setState({loading:true});
        var e = this.state.code;
        let backend = new ApiBackend();
        backend.deleteCode(e.id, e.Codes[idx]).then(resp => {
            if (resp)
            {
                backend.getCode(this.state.code.id).then((code) => {
                    this.setState({code: code, loading: false});
                });
            }
            else 
            {
                alert("Fel när kod skulle tas bort!");       
            }

        }).catch((error) => {
            alert("Fel när kod skulle tas bort!");
        });      
    }

    onChange = (prop: string) => (ev : React.ChangeEvent<HTMLInputElement>) => 
    {
        var e = this.state.code as any;
        e[prop] = ev.target.value;
        this.setState({code: e,haveChanges:true});
    }
    onChangeChk = (prop: string) => (ev : React.ChangeEvent<HTMLInputElement>) => 
    {
        var e = this.state.code as any;
        e[prop] = ev.target.checked;
        this.setState({code: e,haveChanges:true});
    }
    onCodeValueChange = (prop: string, codeIndex: number, value: string) => 
    {
        var e = this.state.code.Codes[codeIndex] as any;
        e[prop] = value;
        this.setState({code: e, haveChanges:true});
    }

    saveCode = () => {
        let back = new ApiBackend();
        this.setState({loading: true});
        back.createOrUpdateCode(this.state.code).then((res) => {
            if (res)
            {
                this.setState({haveChanges:false, loading: false});
            }
            else 
            {
                alert("Kunde inte spara kod!");
                this.setState({loading: false});
            }
        });
    }

    onEditCode = (code : Code) => {
        this.setState({editCode: code});
    }

    render() {

        if (this.state && this.state.editCode != null) {
            return this.renderEditCode();
        }
        else {
            return <div>{this.state.loading &&
                <LinearProgress />
            }
                {this.state.code &&
                    this.renderCodeEdit()
                }
            </div>;
        }
    }

    renderEditCode() {
        return <div></div>/*<EditCompetition event={this.state.event} competition={this.state.editCompetition} back={() => {this.setState({editCompetition:null})}}/>*/
    }

    renderCodeEdit() {
        let { classes } = this.props;
        return <Grid container className={classes.root} spacing={0}>
        <Grid item xs={12}>            
            <Card>
                <CardHeader className={classes.cardHeader} title={"Hantera: " + this.state.code.name} />
                <CardContent>
                {this.state.loading &&
                        <LinearProgress color="secondary"/>
                        }
                    <form className={classes.form} noValidate autoComplete="off">
                            <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                                <Grid item xs={12}>
                                    <TextField style={{ flex: 1, margin: '0 3px' }} id="standard-basic" label="Namn" value={this.state.code.name} onChange={this.onChange('name')} fullWidth />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField type="date" style={{ flex: 1, margin: '0 3px' }} id="standard-basic" value={moment(this.state.code.ValidFrom).format("YYYY-MM-DD")} onChange={this.onChange('ValidFrom')} label="Giltig från" fullWidth />
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    <TextField type="date" style={{ flex: 1, margin: '0 3px' }} id="standard-basic" value={moment(this.state.code.ValidTo).format("YYYY-MM-DD")} onChange={this.onChange('ValidTo')} label="Giltig till" fullWidth />
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    <FormControlLabel
                                        control={<Checkbox checked={this.state.code.IsArchived} onChange={this.onChangeChk('IsArchived')} name="Arkiverad" />}
                                        label="Arkiverad"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    <TextField disabled style={{ flex: 1, margin: '0 3px' }} id="standard-basic" value={moment(this.state.code.Created).format("YYYY-MM-DD")} label="Skapad" fullWidth />
                                </Grid>

                                <Grid item xs={12}>
                                    <CodeProducts code={this.state.code} save={(impl : CodeProductImplementation) => 
                                    {
                                        var c = this.state.code;
                                        if (!c.Implementations)
                                        {
                                            c.Implementations = [];
                                        }
                                        
                                        if (!impl.id)
                                        {
                                            //new
                                            impl.id = uuid();
                                            c.Implementations.push(impl);    
                                        }
                                        else 
                                        {
                                            let idx = c.Implementations.findIndex(x=>x.id == impl.id);
                                            c.Implementations.splice(idx,1,impl);
                                        }
                                        this.setState({code: c,haveChanges:true});
                                    }}
                                    delete={(impl : CodeProductImplementation) => 
                                        {
                                            var c = this.state.code;
                                            let idx = c.Implementations.findIndex(x=>x.id == impl.id);
                                            c.Implementations.splice(idx,1);
                                            this.setState({code: c,haveChanges:true});
                                        }}
                                     products={this.state.products}/>
                                </Grid>
                                <Grid item xs={12}>
                                    {this.state.code &&
                                        <Codes code={this.state.code} codesChanged={this.updateCode} />
                        }

                </Grid>
                        </Grid>
                    </form>
                </CardContent>
                <CardActionArea>
                    <Button color="secondary" disabled={!this.state.haveChanges} onClick={this.saveCode}>Spara ändringar</Button>
                </CardActionArea>
            </Card>
            </Grid>
           
    </Grid>;
    }
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
}
);


export default withStyles(useStyles)(withRouter(EditCode));