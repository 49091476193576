import { Checkbox, FormControlLabel, Grid, LinearProgress, MenuItem, TextField } from "@material-ui/core";
import React from "react";
import { B2bCompany, B2bCompanyProduct } from "../../../model/B2bCompany";
import { ApiBackend } from "../../../providers/apibackend";

interface IState {
    loading: boolean;
    includeArchived: boolean;
    companies: B2bCompany[];
    company: B2bCompany | undefined;
    companyProduct: B2bCompanyProduct | undefined;
}

interface IProps {
    onCompanyChange: (companyFilter: {company: B2bCompany; companyProduct: B2bCompanyProduct; } | undefined) => void;
}

class CompanyFilterForm extends React.Component<IProps, IState> {
    state: IState;

    private readonly api: ApiBackend;

    constructor(props: IProps) {
        super(props);

        this.state = { loading: false, includeArchived: false, companies: [], company: undefined, companyProduct: undefined };
        this.api = new ApiBackend();
    }

    async componentDidMount(): Promise<void> {
        await this.fetchCompanies();
    }

    render(): JSX.Element {
        const { loading, companies, company, companyProduct, includeArchived } = this.state;

        return (<>
            <Grid container spacing={2} style={{ width: '100%' }}>
                <Grid item xs={3}>
                    <TextField
                        id="company-select"
                        name="company-select"
                        select
                        style={{ flex: 1, margin: '0 3px' }}
                        type="select"
                        label="Välj företag"
                        InputLabelProps={{ shrink: true }}
                        value={company ? company.id : ""}
                        fullWidth
                        onChange={this.onCompanyChange}
                    >
                        <MenuItem key="-1" value="">----</MenuItem>
                        {companies && companies.map((x) => {
                            return <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>;
                        })}
                    </TextField>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        id="product-select"
                        name="product-select"
                        select
                        style={{ flex: 1, margin: '0 3px' }}
                        type="select"
                        label="Välj produkt"
                        InputLabelProps={{ shrink: true }}
                        value={companyProduct ? companyProduct.id : ""}
                        fullWidth
                        onChange={this.onCompanyProductChange}
                    >
                        <MenuItem key="-1" value="">----</MenuItem>
                        {company && company.products && company.products.map((x) => {
                            return <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>;
                        })}
                    </TextField>
                </Grid>
                <Grid item xs={2}>
                    <FormControlLabel 
                         control= {
                            <Checkbox 
                                value={includeArchived}
                                onChange={this.onCheckedChanged} 
                            />
                        }
                         label="Inkludera arkiverade företag"/>
                </Grid>
            </Grid>
            {loading &&
                <LinearProgress variant="indeterminate" color="secondary" />
            }
        </>);
    }

    private fetchCompanies = async () => {
        const { includeArchived, companyProduct } = this.state;
        const shouldCall = !!companyProduct;

        this.setState({ loading: true });
        const companies = await this.api.listB2bCompanies(includeArchived);

        this.setState({ companies: companies, loading: false, company: undefined, companyProduct: undefined });
        if (shouldCall) {
            this.props.onCompanyChange(undefined)
        }
    };

    private onCheckedChanged = () => {
        this.setState({ includeArchived: !this.state.includeArchived}, () => {
            this.fetchCompanies();
        });
    };

    private onCompanyChange = async(ev: React.ChangeEvent<HTMLInputElement>) => {
        const { companies, companyProduct } = this.state;
        const shouldCall = !!companyProduct;

        const company = companies.find(x => x.id === ev.target.value);
        let nextCompany: B2bCompany;
        let nextCompanyProduct: B2bCompanyProduct;

        if (company) {
            nextCompany = await this.api.getB2bCompany(company.id); // Hämta ett fullständigt company med produkter
        }

        if (nextCompany && nextCompany.products && nextCompany.products.length === 1) {
            nextCompanyProduct = nextCompany.products[0];
        }

        this.setState({ company: nextCompany, companyProduct: nextCompanyProduct });
        
        if (nextCompanyProduct) {
            this.props.onCompanyChange({ company: nextCompany, companyProduct: nextCompanyProduct });
        } else if (shouldCall) {
            this.props.onCompanyChange(undefined);
        }
    };

    private onCompanyProductChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const { company } = this.state;

        const companyProduct = company.products.find(x => x.id === ev.target.value);
        this.setState({ companyProduct: companyProduct });

        if (companyProduct) {
            this.props.onCompanyChange({ company: company, companyProduct: companyProduct })    
        } else {
            this.props.onCompanyChange(undefined)
        }
    };
}

export default CompanyFilterForm;
