import { AccountingInfo } from "./AccountingInfo";
import { Departure } from "./Departure";
import { Event } from "./Event";
import { ProductVariant } from "./ProductVariant";
import { StartGroup } from "./StartGroup";

export class Product {
    name : string;
    name_en : string;
    publicId: string;
    description: string;
    description_en: string;
    type: string;
    active: boolean;
    id : string;
    departures? : Departure[];
    variants?: ProductVariant[];
    startGroupAllocationActive : boolean;
    startGroups? : StartGroup[];
    accountingInfo? : AccountingInfo;
    PaymentRefPrefix : string;
    static createNew() : Product {
        return {name:'',description:'',name_en:'',startGroupAllocationActive: false, description_en:'',type:'',id:'',publicId:'',
        variants:null, active: false, PaymentRefPrefix:null, EventId: null, IsArchived: false, symplifyJourneyForTicket: null,symplifyJourneyForTicketEN: null, 
        addOnProducts: null, accountingInfo: null, departures: null,maxParticipants:99999, startGroups: null,
    textAcceptTerms: null, textAcceptGDPR: null, linkAcceptTerms: null, linkAcceptGDPR: null, labelLinkAcceptGDPR: null, labelLinkAcceptTerms: null};
    }
    EventId: string;
    Event?: Event;
    IsArchived: boolean;
    maxParticipants?: number;
    symplifyJourneyForTicket: string;
    symplifyJourneyForTicketEN: string;
    addOnProducts: string[];
    textAcceptTerms: string;
    linkAcceptTerms: string;
    labelLinkAcceptTerms: string
    textAcceptGDPR: string;
    linkAcceptGDPR: string;
    labelLinkAcceptGDPR: string;

    productRequiresSocialSecurityNumber?: boolean;
    raceStartCertificateAvailable?: boolean;
    raceEntryOpen?: boolean;
    preRaceEntryOpen?: boolean;
    raceEntriesCanBeChanged?: boolean;
}