/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import React, { Fragment } from 'react'
import FirebaseContext from '../../../providers/Firebase/context';
import { Card, CardHeader, CardContent, Theme, withStyles, Button, createStyles, Grid, LinearProgress, TextField, Typography, Box, IconButton } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ApiBackend } from '../../../providers/apibackend';
import { DataGrid, ColDef, ValueFormatterParams, PageChangeParams } from '@material-ui/data-grid';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Person } from '../../../model/Person';
import EditPersonDialog from './EditPersonDialog';
import { OpenInBrowser, ArrowForward, Edit } from '@material-ui/icons';
import { debounce } from 'lodash';

interface State {
    loading: boolean;
    persons: Person[];
    search: string;
    editPerson: Person;
    page: number;
    pageSize: number;
    rowCount: number;
}

type editPersonMode = "dialog" | "in-app" | "new-tab";

class ManagePersons extends React.Component<RouteComponentProps, State> {
    static contextType = FirebaseContext;

    private readonly api: ApiBackend;

    constructor(props: RouteComponentProps) {
        super(props);
        this.state = { editPerson: null, loading: true, persons: null, search: "", page: 1, pageSize: 10, rowCount: 0 };
        this.api = new ApiBackend();
    }

    componentDidMount() {
        this.refresh();
    }

    onSearchChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ search: ev.target.value, page: 1, loading: true }, () => {
            this.performSearch();
        });
    };

    refresh = () => {
        this.setState({ loading: true });
        this.performSearch();
    };

    createPerson = () => {
        this.setState({ editPerson: new Person() });
    }

    editPerson = (person: Person, mode: editPersonMode): void => {
        switch (mode) {
            case "dialog":
                this.setState({ editPerson: person });
                break;
            case "in-app":
                this.props.history.push(`manage/${person.id}`);
                break;
            case "new-tab":
                window.open(`manage/${person.id}`);
                break;
        }
    };

    render() {
        const { classes } = this.props as any;

        const columns: ColDef[] = [
            {
                field: '', headerName: '', width: 200,
                renderCell: (params: ValueFormatterParams) => {
                    return <Fragment>
                        <IconButton color="default" onClick={() => { this.editPerson(params.data as any, "dialog") }}>
                            <Edit fontSize="default" />
                        </IconButton>
                        <IconButton color="default" onClick={() => { this.editPerson(params.data as any, "new-tab") }}>
                            <OpenInBrowser fontSize="default" />
                        </IconButton>
                        <IconButton color="default" onClick={() => { this.editPerson(params.data as any, "in-app") }}>
                            <ArrowForward fontSize="default" />
                        </IconButton>
                    </Fragment>
                }
            },
            { field: 'vasaId', headerName: 'VASA-ID', width: 170 },
            { field: 'firstName', headerName: 'Förnamn', width: 170 },
            { field: 'lastName', headerName: 'Efternamn', width: 170 },
            { field: 'email', headerName: 'E-post', width: 170 },
            { field: 'mobileNumber', headerName: 'Telefon', width: 170 },
            { field: 'clubName', headerName: 'Klubb', width: 170 }
        ];

        return <Fragment>
            <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        autoComplete="off"
                        style={{ flex: 1, margin: '0 3px' }}
                        id="standard-basic"
                        label="Filtrera (förnamn, efternamn, email,...)"
                        value={this.state.search}
                        onChange={this.onSearchChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader
                            className={classes.cardHeader}
                            title={
                                <Fragment>
                                    <Typography
                                        variant="h5"
                                        style={{ display: 'inline' }}>
                                        Personer
                                    </Typography>
                                    <RefreshIcon
                                        style={{ display: 'inline', verticalAlign: 'middle' }}
                                        onClick={this.refresh}
                                    />
                                </Fragment>
                            }
                        />
                        <CardContent>
                            <Box mb={1}>
                                <Button variant="contained" onClick={this.createPerson}>Lägg till person</Button>
                            </Box>

                            {this.state.loading &&
                                <LinearProgress color="secondary" />
                            }

                            <div style={{ height: 400, width: '100%' }}>
                                <DataGrid
                                    loading={this.state.loading}
                                    rows={this.state.persons ?? []}
                                    columns={columns}
                                    paginationMode="server"
                                    pageSize={this.state.pageSize}
                                    page={this.state.page}
                                    rowCount={this.state.rowCount}
                                    onPageChange={(newPage) => this.setPage(newPage)}
                                />
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            {this.state && this.state.editPerson &&
                <EditPersonDialog
                    personId={this.state.editPerson.id}
                    onAbortEdit={() => {
                        this.setState({ editPerson: null });
                    }}
                    onSave={(person) => {
                        this.setState({ editPerson: null });
                        this.refresh();
                    }}
                />
            }
        </Fragment>;
    }

    private setPage(pageParams: PageChangeParams): void {
        const { page } = this.state;

        if (pageParams.page === page) {
            // mui is trigger happy
            return;
        }

        this.setState({ page: pageParams.page, loading: true }, () => {
            this.performSearch();
        });
    }

    private performSearch = debounce(async () => {
        const { page, pageSize } = this.state;
        const response = await this.api.listPersons(this.state.search, (page-1) * pageSize, pageSize);
        this.setState({
            persons: response?.persons,
            rowCount: response?.count ?? 0,
            loading: false 
        });
    }, 500);
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withRouter(withStyles(useStyles)(ManagePersons));
