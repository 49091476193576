import { Grid, MenuItem, WithStyles } from "@material-ui/core";
import { FormikErrors, FormikTouched } from "formik";
import React from "react";
import { Person } from "../../../model/Person";
import FormCbxField from "../../Common/FormCbxField";
import FormDateField from "../../Common/FormDateField";
import FormSelectField from "../../Common/FormSelectField";
import FormTextField from "../../Common/FormTextField";
import FormCountryPickerDropdown from "../../Common/FormCountryPickerDropDown";
import { ApiBackend } from "../../../providers/apibackend";

interface IProps {
    errors: FormikErrors<Person>;
    touched: FormikTouched<Person>;
    values: Person;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    handleBlur: {
        (e: React.FocusEvent<any>): void;
        <T = string | any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
    };
    handleChange: {
        (e: React.ChangeEvent<any>): void;
        <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
    };
}

class EditPersonFormContent extends React.Component<WithStyles & IProps> {
    private previousPostCodeValue: string | undefined;
    private timeout: number;
    private readonly apiBackend: ApiBackend;

    constructor(props: WithStyles & IProps) {
        super(props);

        this.apiBackend = new ApiBackend();
    }

    private getPostCode = () => {
        const { values, setFieldValue } = this.props;
        this.previousPostCodeValue = values.postCode;
        this.apiBackend.getPostCode(values.postCode)
            .then(result => {
                if (result) {
                    setFieldValue("city", result.locality);
                    setFieldValue("county", result.county);
                }
            });
    }

    private shouldGetPostCode(): boolean {
        const { values, touched } = this.props;
        if (!touched.postCode) {
            return false;
        }

        if (values.country !== "SWE") {
            return false;
        }

        const trimmedValue = values?.postCode?.replace(/\s/g, "");
        if (isNaN(trimmedValue as any) || trimmedValue.length !== 5) {
            return false;
        }

        return !this.previousPostCodeValue || this.previousPostCodeValue !== values.postCode;
    }

    render(): JSX.Element {
        const { classes, values } = this.props;
        if (this.shouldGetPostCode()) {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            this.timeout = window.setTimeout(() => {
                this.getPostCode();
            }, 400);
        }

        return (
            <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                <Grid item xs={6}>
                    <FormTextField {...this.props}
                        disabled
                        propName="vasaId"
                        label="Vasa ID"
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormTextField {...this.props}
                        disabled
                        propName="externalId"
                        label="PersonID (SportsPro)"
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormTextField {...this.props}
                        propName="firstName"
                        label="Förnamn"
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormTextField {...this.props}
                        propName="lastName"
                        label="Efternamn"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormTextField {...this.props}
                        propName="email"
                        label="Email"
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormTextField {...this.props}
                        propName="phoneNumber"
                        label="Tel."
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormTextField {...this.props}
                        propName="mobileNumber"
                        label="Mobilnr."
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormDateField {...this.props}
                        propName="birthDay"
                        label="Födelsedatum"
                        inputProps={{ min: '1800-01-01', max: '9999-12-31' }}
                        valueFormatter={() => values.birthDay ?? ''}
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormSelectField {...this.props}
                        propName="gender"
                        label="Kön"
                    >
                        <MenuItem value="male">Man</MenuItem>
                        <MenuItem value="female">Kvinna</MenuItem>
                    </FormSelectField>
                </Grid>
                <Grid item xs={6}>
                    <FormCountryPickerDropdown {...this.props}
                        propName="nationality"
                        label="Nationalitet"
                    >
                    </FormCountryPickerDropdown>
                </Grid>
                <Grid item xs={12}>
                    <FormTextField {...this.props}
                        propName="address"
                        label="Adress"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormTextField {...this.props}
                        propName="address_line2"
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormTextField {...this.props}
                        propName="postCode"
                        label="Postnummer"
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormTextField {...this.props}
                        propName="city"
                        label="Ort"
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormTextField {...this.props}
                        propName="county"
                        label="Län"
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormCountryPickerDropdown {...this.props}
                        propName="country"
                        label="Land"
                    >
                    </FormCountryPickerDropdown>
                </Grid>
                <Grid item xs={6}>
                    <FormTextField {...this.props}
                        propName="veteranId"
                        label="Veteran-ID"
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormTextField {...this.props}
                        propName="fisCode"
                        label="FIS-Kod"
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormCbxField {...this.props}
                        propName="deceased"
                        label="Är avliden"
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormCbxField {...this.props}
                        propName="excludeWeb"
                        label="Anonym på webben?"
                    />
                </Grid>
            </Grid>
        )
    }
}

export default EditPersonFormContent;
