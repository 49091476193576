import { Button, Card, CardActions, CardContent, CardHeader, CircularProgress, createStyles, Grid, Theme, WithStyles, withStyles } from '@material-ui/core';
import { Formik } from 'formik';
import React from 'react';
import { RouteChildrenProps, withRouter } from 'react-router-dom';
import { Person } from '../../../model/Person';
import { ApiBackend } from '../../../providers/apibackend';
import FirebaseContext from '../../../providers/Firebase/context';
import DirtyPageChecker from '../../Common/DirtyPageChecker';
import EditPersonFormContent from './EditPersonFormContent';
import validationSchema from './PersonValidationSchema';

interface IProps extends RouteChildrenProps<{ id: string }> {
}

interface IState {
    loading: boolean;
    person: Person,
}

class EditPerson extends React.Component<WithStyles & IProps, IState> {
    static contextType = FirebaseContext;

    private readonly api: ApiBackend;

    constructor(props: WithStyles & IProps) {
        super(props);
        this.state = { person: null, loading: true };
        this.api = new ApiBackend();
        this.loadPerson();
    }

    render() {
        const { classes } = this.props;
        const { person, loading } = this.state;

        const cardHeader = (): string => {
            if (person.firstName && person.lastName) {
                return `Hantera ${person.firstName} ${person.lastName}`;
            }

            return `Hantera ${person.vasaId}`
        };

        return (
            <>
                {loading &&
                    <CircularProgress color="primary" />
                }
                {person &&
                    <Formik
                        initialValues={person}
                        validationSchema={validationSchema}
                        onReset={(values): void => this.setState({ person: values })}
                        onSubmit={async (values, { resetForm }) => {
                            const person = await this.api.savePerson(values);
                            resetForm({ values: person });
                        }}
                    >
                        {formik => {
                            const { isValid, dirty, handleSubmit, handleReset, isSubmitting } = formik;
                            const isSaveDisabled = !dirty || !isValid || isSubmitting;

                            return (
                                <DirtyPageChecker isDirty={dirty} getTitle={this.getTitle} {...this.props}>
                                    <form autoComplete="off" onSubmit={handleSubmit}>
                                        <Card>
                                            <CardHeader className={classes.cardHeader} title={cardHeader()} />
                                            <CardContent className={classes.form}>
                                                <EditPersonFormContent {...formik} classes={classes} ></EditPersonFormContent>
                                            </CardContent>
                                            <CardActions>
                                                <Button type="submit" color="secondary" disabled={isSaveDisabled} variant="contained">Spara</Button>
                                                <Button style={{ marginLeft: 10 }} color="secondary" disabled={!dirty} variant="contained" onClick={handleReset}>Avbryt</Button>
                                            </CardActions>
                                        </Card>
                                    </form>
                                </DirtyPageChecker>
                            )
                        }}

                    </Formik>
                }
            </>
        )
    }

    private loadPerson = async (): Promise<void> => {
        const person = await this.api.getPerson(this.props.match.params.id);
        this.setState({
            loading: false,
            person: person
        });
    };

    private getTitle = (): string | undefined => {
        const { person } = this.state;

        if (person?.firstName && person?.lastName) {
            return `Kundkort: ${person.firstName} ${person.lastName}`;
        }
    };
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withRouter(withStyles(useStyles)(EditPerson));
