/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import React, { Fragment } from 'react';
import AppBar from '@material-ui/core/AppBar/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme, withStyles, WithStyles, Menu, MenuItem } from '@material-ui/core';
import FirebaseContext from '../providers/Firebase/context';
import Firebase from '../providers/Firebase/firebase';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';

interface State {
    menuAnchorEl: any;
}

/** 
 * TopBar of the application
 * Shows menu and account profile
 */
class Top extends React.Component<WithStyles & RouteComponentProps, State>
{
    state = { menuAnchorEl: null as any };
    render() {
        let { classes } = this.props;
        let title = "Kontrollen";
        if (CONFIG.environment !== "Prod") {
            title += ` - ${CONFIG.environment}`;
        }

        return <AppBar position="static" className="nav-header">
            <Toolbar>
                <FirebaseContext.Consumer>
                    {(value: { firebase: Firebase, isSignedIn: boolean }) => {

                        return <Fragment><div style={{ display: 'inline', textAlign: 'center' }}><a href="/" className="logotype asset asset-logotype" title="Mina sidor">{title}</a></div>
                            <Typography variant="h6" className={classes.title}>
                                <Link to="/">{title}</Link>
                            </Typography>
                            {value.isSignedIn ?
                                <div>
                                    <Button color="inherit" onClick={value.firebase.doSignOut}>Logga ut</Button>
                                    {value.firebase.auth().currentUser.photoURL ? <img className={classes.photo} src={value.firebase.auth().currentUser.photoURL} /> : ''}</div> : <Button color="inherit">Logga in</Button>
                            }</Fragment>;
                    }}
                </FirebaseContext.Consumer>
            </Toolbar>
        </AppBar>
    }
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    root: {
        flexGrow: 1
    },
    title: {
        flexGrow: 1
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    }
});

export default withRouter(withStyles(useStyles)(Top));