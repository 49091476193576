/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

 import React, { ChangeEvent, Fragment } from 'react'
 import FirebaseContext from '../../providers/Firebase/context';
 import { Card, CardHeader, CardContent, Theme, WithStyles, withStyles, Button, createStyles, Grid, CardActionArea, TableContainer, Paper, Table, TableHead, TableRow, TableBody, Hidden, TableCell, Dialog, DialogTitle, DialogActions, LinearProgress, DialogContent, TextField, Typography, Select, MenuItem } from '@material-ui/core';
 import { withRouter, RouteComponentProps } from 'react-router-dom';
 import { ApiBackend } from '../../providers/apibackend';
 import { cloneDeep } from 'lodash';
 import EditIcon from '@material-ui/icons/Edit';
 import DeleteIcon from '@material-ui/icons/Delete';
 import uuid from 'react-uuid';
import { CustomField } from '../../model/CustomField';
import EditCustomField from './EditCustomField';
import CheckCircle from '@material-ui/icons/CheckCircle';
 
 interface State {
     loading : boolean;
     fields: CustomField[],
     deleteConfirmation : CustomField;
     showDeleteConfirmation: boolean;
     duplicateItem : CustomField;
     duplicateItemName: string;
     duplicateItemNameEn: string;
     addItem : CustomField;
     editItem : CustomField;
 }
 
 class ManageFields extends React.Component<RouteComponentProps, State> {
     static contextType = FirebaseContext;
     constructor(props : RouteComponentProps)
     {
         super(props);
         this.state = {loading: true,addItem: null,editItem : null, fields : null, deleteConfirmation: null, showDeleteConfirmation:false, duplicateItem: null, duplicateItemName: null as string,duplicateItemNameEn: null as string};
     }
 
     componentDidMount() {
         this.setState({loading:true});
         let back = new ApiBackend();
 
         back.listCustomFields().then((fields) => {
             this.setState({ fields: fields, loading:false });
         });
     }
 
     addField = () => {
      //   this.props.history.push("/customfield/create");
      this.setState({addItem: {
          id: uuid(),
          name: 'Nytt fält',
          label: 'Fält 1',
          label_en: 'Field 1',
          description: '',
          description_en: '',
          type: 'text',
          validation_type: '',
          handleAsClubName: false,
        validvalues: null
       }});
     }
 
     editField = (field : CustomField) => () =>{
         this.setState({editItem: field});
     }
     duplicateField = (field : CustomField) => () =>{
         this.setState({duplicateItem: field, duplicateItemName : "Kopia av " + field.label,duplicateItemNameEn : "Copy of " + field.label_en});
     }
     deleteField = (field : CustomField) => () =>{
         this.setState({deleteConfirmation: field, showDeleteConfirmation:true});
     }
     updateFieldList = () => {
         this.setState({loading:true});
         let back = new ApiBackend();
         back.listCustomFields().then((fields) => {
             this.setState({ fields: fields, loading:false });
         });
     }
 
     render() {
         let { classes } = this.props as any;
         return <Fragment><Grid container className={classes.root} spacing={2}>
             <Grid item xs={12}>
                 
                 <Card>
 
                     <CardHeader className={classes.cardHeader} title="Fältdefinitioner" />
                     <CardContent>
                     {this.state.loading &&
                         <LinearProgress color="secondary"/>
                         }
                         
                         <Grid container className={classes.root} spacing={2}>
                         <Grid item xs={3}>
                             <Button variant="contained" onClick={this.addField}>Lägg till fält</Button>
                         </Grid>
                         <Grid item xs={5}>
                         </Grid>
                        
                         <Grid item xs={12}>
                         
                         <TableContainer component={Paper}>
                             <Table size="small" >
                                 <TableHead>
                                     <TableRow>
                                         <StyledTableCell>Namn</StyledTableCell>
                                         <StyledTableCell>Label</StyledTableCell>
                                         <StyledTableCell>Label EN</StyledTableCell>
                                         <StyledTableCell>Typ</StyledTableCell>
                                         <StyledTableCell>Validering</StyledTableCell>
                                         <StyledTableCell>Klubbnamn?</StyledTableCell>
                                         <StyledTableCell></StyledTableCell>
                                     </TableRow>
                                 </TableHead>
                                 <TableBody>
                                     {this.state && this.state.fields && this.state.fields.map((x,idx) => {
                                            return <TableRow>
                                            <StyledTableCell>{x.name}</StyledTableCell>
                                            <StyledTableCell>{x.label}</StyledTableCell>
                                            <StyledTableCell>{x.label_en}</StyledTableCell>
                                            <StyledTableCell>{x.type}</StyledTableCell>
                                            <StyledTableCell>{x.validation_type}</StyledTableCell>
                                            <StyledTableCell>{x.handleAsClubName ? <CheckCircle/> : ""}</StyledTableCell>
                                            <StyledTableCell>
                                                <EditIcon style={{cursor:'pointer'}} onClick={this.editField(x)}/>
                                                <DeleteIcon style={{cursor:'pointer'}} onClick={this.deleteField(x)}/>
                                            </StyledTableCell>
                                        </TableRow> 
                                     })}
                                 </TableBody>
                             </Table></TableContainer>
                             </Grid>
                             </Grid>
                     </CardContent>
                 </Card>
             </Grid>
         </Grid>
         {this.state && this.state.showDeleteConfirmation && this.renderConfirmationDialog()}
         {this.state && this.state.duplicateItem && this.renderDuplicateDialog()}
         {this.state && this.state.addItem && this.renderAddFieldDialog()}
         {this.state && this.state.editItem && this.renderEditFieldDialog()}
         </Fragment>;
     }

 
     renderConfirmationDialog() {
         return <Dialog
             disableBackdropClick
             disableEscapeKeyDown
             maxWidth="xs"
             aria-labelledby="confirmation-dialog-title"
             open={true}
         >
             <DialogTitle id="confirmation-dialog-title">Är du säker på att du vill ta bort fältet?</DialogTitle>
             <DialogActions>
                 <Button variant="contained" autoFocus onClick={this.handleDeleteCancel} color="primary">
                     Avbryt
           </Button>
                 <Button variant="contained" onClick={this.handleDeleteOk} color="primary">
                     Ja, ta bort
           </Button>
             </DialogActions>
         </Dialog>;
     }

     renderAddFieldDialog() {
        return <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">Lägg till fält</DialogTitle>
            <DialogContent>
            <EditCustomField item={this.state.addItem} onPropChanged={(prop, val) => {
                     let ai = this.state.addItem;
                     (ai as any)[prop]=val;
                     this.setState({addItem: ai});
                }}/>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" autoFocus onClick={this.handleAddCancel} color="primary">
                    Avbryt
          </Button>
                <Button variant="contained" onClick={this.handleAddOk} color="primary">
                    Skapa
          </Button>
            </DialogActions>
        </Dialog>;
    }

    renderEditFieldDialog() {
        return <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">Editera fält</DialogTitle>
            <DialogContent>
                <EditCustomField item={this.state.editItem} onPropChanged={(prop, val) => {
                     let ai = this.state.editItem;
                     (ai as any)[prop]=val;
                     this.setState({editItem: ai});
                }}/>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" autoFocus onClick={this.handleAddCancel} color="primary">
                    Avbryt
          </Button>
                <Button variant="contained" onClick={this.handleEditOk} color="primary">
                    Spara
          </Button>
            </DialogActions>
        </Dialog>;
    }

    handleAddCancel= () => {
        this.setState({addItem: null,editItem: null});
    }
    handleAddOk = () => {
        this.setState({loading: true});
        let back = new ApiBackend();
        back.addCustomField(this.state.addItem).then((res) => 
        {
            back.listCustomFields().then((res) => {
                this.setState({fields: res, loading: false, addItem: null});
            })
        })
    }
    handleEditOk = () => {
        this.setState({loading: true});
        let back = new ApiBackend();
        back.updateCustomField(this.state.editItem).then((res) => 
        {
            back.listCustomFields().then((res) => {
                this.setState({fields: res, loading: false, editItem: null});
            })
        })
    }
 
     renderDuplicateDialog() {
         return <Dialog
             disableBackdropClick
             disableEscapeKeyDown
             maxWidth="xs"
             aria-labelledby="confirmation-dialog-title"
             open={true}
         >
             <DialogTitle id="confirmation-dialog-title">Skapa kopia av fältet</DialogTitle>
             <DialogContent>
                 <Grid container spacing={2}>
                         <Grid item xs={12}>
                         <Typography variant="body2">Nytt namn</Typography>
                         </Grid>
                         <Grid item xs={12}>
                         <TextField value={this.state.duplicateItemName} onChange={(ev) => {this.setState({duplicateItemName: ev.target.value});}} fullWidth/>
                         </Grid>
 
                         <Grid item xs={12}>
                         <Typography variant="body2">Nytt namn (engelska)</Typography>
                         </Grid>
                         <Grid item xs={12}>
                         <TextField value={this.state.duplicateItemNameEn} onChange={(ev) => {this.setState({duplicateItemNameEn: ev.target.value});}} fullWidth/>
                         </Grid> 
                         </Grid>
                
             </DialogContent>
             <DialogActions>
                 <Button variant="contained" autoFocus onClick={this.handleDuplicateCancel} color="primary">
                     Avbryt
           </Button>
                 <Button variant="contained" onClick={this.handleDuplicateOk} color="primary">
                     Ja, skapa kopia
           </Button>
             </DialogActions>
         </Dialog>;
     }
 
     handleDeleteCancel = () => {
         this.setState({ deleteConfirmation: null, showDeleteConfirmation: false });
     }
     handleDuplicateCancel = () => {
         this.setState({ duplicateItem: null });
     }
 
     handleDeleteOk = () => {
         this.setState({loading:true});
         var back = new ApiBackend();
         back.deleteCustomField(this.state.deleteConfirmation.id).then((res) => {
             if (res){
                 back.listCustomFields().then((fields) => {
                     this.setState({deleteConfirmation: null, showDeleteConfirmation: false, fields: fields, loading:false });
                 });
             }
             else 
             {
                 alert("Kunde inte ta bort product!");
             }
         })
         
     }
     handleDuplicateOk = () => {
         this.setState({loading:true});
         var back = new ApiBackend();
         let newField = new CustomField();
 
         newField = cloneDeep(this.state.duplicateItem);
 
         newField.name = this.state.duplicateItemName;
         newField.label = this.state.duplicateItemName;
         newField.label_en = this.state.duplicateItemNameEn;
         newField.description = this.state.duplicateItem.description;
         newField.description_en = this.state.duplicateItem.description_en;
         newField.type = this.state.duplicateItem.type;
         newField.validation_type = this.state.duplicateItem.validation_type;
         newField.validvalues = this.state.duplicateItem.validvalues;
         newField.id = uuid();
         
 
         back.addCustomField(newField).then((res) => {
             back.listCustomFields().then((fields) => {
                this.setState({duplicateItem: null, fields: fields, loading:false });
            });
         });
 
     }
 }
 
 
 
 const useStyles = ({ palette, spacing }: Theme) => createStyles({
     cardHeader: {
         background: palette.secondary.main,
         color: palette.secondary.contrastText,
         padding: 3
     },
     photo: {
         height: '30px',
         verticalAlign: 'middle',
         borderRadius: '10px'
     },
     root: {
 
     },
     form: {
         '& > *': {
             margin: spacing(1),
             width: '25ch',
         },
         '& label.Mui-focused': {
             color: palette.secondary.main,
         },
         '& .MuiInput-underline:after': {
             borderBottomColor: palette.secondary.main,
         },
     }
 }
 );
 
 const tableHeadStyles = ({ palette, spacing }: Theme) => createStyles({
     head: {
         background: palette.primary.main,
         color: palette.primary.contrastText,
     }
 });
 
 const StyledTableCell = withStyles(tableHeadStyles)(TableCell);
 
 export default withRouter(withStyles(useStyles)(ManageFields));