import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { WithStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import React from 'react';
import { OrderItem } from '../../model/OrderItem';
import { Product } from '../../model/Product';
import { ApiBackend } from '../../providers/apibackend';

interface props {
    save: () => void;
    item: OrderItem;
    cancel: () => void;
}

interface state {
    numItems: number;
    productId: string;
    variantId: string;
    priceGroupId: string;
    occationId: string;
    loading: boolean;
    products: Product[];
    selectedProduct: Product;
    metadata: { key: string, value: string }[]
}

class EditOrderItem extends React.Component<props & WithStyles, state> {
    constructor(props: props & WithStyles) {
        super(props);
        this.state = {
            numItems: props.item.numberOfProducts,
            productId: props.item.productId,
            variantId: props.item.productVariantId,
            priceGroupId: props.item.productVariantPriceGroupId,
            occationId: props.item.productVariantOccationId,
            loading: true,
            products: null,
            selectedProduct: null,
            metadata: props.item.metadata ?? []
        };

        let backend = new ApiBackend();
        backend.listProducts().then((products) => {
            if (props.item.productId) {
                backend.getProduct(props.item.productId).then((product) => {
                    this.setState({ loading: false, selectedProduct: product });
                });
            }
            else {
                backend.getProduct(products[0].id).then((product) => {
                    this.setState({
                        productId: products[0].id,
                        selectedProduct: product,
                        loading: false,
                        variantId: product.variants[0].Id,
                        priceGroupId: product.variants[0].priceGroups[0].id,
                        occationId: product.variants[0].occations ? product.variants[0].occations[0].id : null
                    });
                });
            }

            this.setState({ products: products });
        });
    }

    save = () => {
        this.props.item.numberOfProducts = this.state.numItems;
        this.props.item.productId = this.state.productId;
        this.props.item.product = this.state.selectedProduct;
        this.props.item.productName = this.state.selectedProduct.name;
        this.props.item.productVariantId = this.state.variantId;
        this.props.item.productVariantOccationId = this.state.occationId;
        this.props.item.productVariantPriceGroupId = this.state.priceGroupId;
        this.props.item.variantName = this.state.selectedProduct.variants.filter(x => x.Id == this.state.variantId)[0].Name;
        this.props.item.itemPrice = this.state.selectedProduct.variants.filter(x => x.Id == this.state.variantId)[0].priceGroups.filter(x => x.id == this.state.priceGroupId)[0].price;
        this.props.item.occationName = this.state.occationId ? this.state.selectedProduct.variants.filter(x => x.Id == this.state.variantId)[0].occations.filter(x => x.id == this.state.occationId)[0].name : null;
        this.props.item.priceGroupName = this.state.selectedProduct.variants.filter(x => x.Id == this.state.variantId)[0].priceGroups.filter(x => x.id == this.state.priceGroupId)[0].Name;

        this.props.save();
    }

    onChange = (prop: string) => (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (prop == "productId") {
            this.setState({ loading: true });
            let backend = new ApiBackend();
            backend.getProduct(ev.target.value).then((product) => {
                this.setState({
                    productId: ev.target.value,
                    selectedProduct: product,
                    loading: false,
                    variantId: product.variants[0].Id,
                    priceGroupId: product.variants[0].priceGroups[0].id,
                    occationId: product.variants[0].occations ? product.variants[0].occations[0].id : null
                });
            })
        }
        else if (prop == "variantId") {
            let variant = this.state.selectedProduct.variants.filter(x => x.Id == ev.target.value)[0];
            this.setState({
                variantId: ev.target.value,
                priceGroupId: variant.priceGroups[0].id,
                occationId: variant.occations ? variant.occations[0].id : null
            });

        }
        else {
            var ns = {} as any;
            ns[prop] = ev.target.value;
            this.setState(ns);
        }
    }

    render() {
        let { classes } = this.props;

        let isDaySkiPass = false;
        let isWeekSkiPass = false;
        let isSeasonSkiPass = false;
        let skiPassNameFields = [];
        let skipassvalidfrom = null;
        let skipassvalidto = null;
        let skiPassValidFields = [];

        if (this.state.selectedProduct && this.state.selectedProduct.type == "skipass") {
            let orderedVariant = this.state.selectedProduct ? this.state.selectedProduct.variants.filter(x => x.Id == this.state.variantId)[0] : null;
            let orderedPriceGroup = orderedVariant.priceGroups.filter(x => x.id == this.state.priceGroupId)[0];
            isDaySkiPass = this.state.selectedProduct.type == "skipass" && orderedPriceGroup.Name == "Dagkort";
            isWeekSkiPass = this.state.selectedProduct.type == "skipass" && orderedPriceGroup.Name == "Veckokort";
            isSeasonSkiPass = this.state.selectedProduct.type == "skipass" && orderedPriceGroup.Name == "Säsongskort";
            if (isDaySkiPass || isWeekSkiPass) {
                let vals = this.state.metadata.filter(x => x.key == "skipassvalidfrom");
                skipassvalidfrom = vals && vals.length > 0 ? vals[0].value : "";

                skiPassValidFields.push(<TextField key={"skipassvalidfrom"} value={skipassvalidfrom} onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                    let meta = this.state.metadata;
                    let curItems = meta.filter(x => x.key == "skipassvalidfrom");
                    if (curItems && curItems.length > 0) {
                        curItems[0].value = ev.target.value;
                    }
                    else {
                        meta.push({ key: "skipassvalidfrom", value: ev.target.value });
                    }
                    this.setState({ metadata: meta });
                }} placeholder={"Giltigt från"} />);

                if (isWeekSkiPass) {
                    let vals = this.state.metadata.filter(x => x.key == "skipassvalidto");
                    skipassvalidto = vals && vals.length > 0 ? vals[0].value : "";
                    skiPassValidFields.push(<TextField key={"skipassvalidto"} value={skipassvalidto} onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                        let meta = this.state.metadata;
                        let curItems = meta.filter(x => x.key == "skipassvalidto");
                        if (curItems && curItems.length > 0) {
                            curItems[0].value = ev.target.value;
                        }
                        else {
                            meta.push({ key: "skipassvalidto", value: ev.target.value });
                        }
                        this.setState({ metadata: meta });
                    }} placeholder={"Giltigt till"} />);
                }
            }

            if (isWeekSkiPass || isSeasonSkiPass) {
                for (let i = 0; i < this.state.numItems; i++) {
                    let field = "skipass_name_" + i;
                    let vals = this.state.metadata.filter(x => x.key == field);
                    let name = vals && vals.length > 0 ? vals[0].value : "";
                    skiPassNameFields.push(<TextField key={"skipassname_" + i} value={name} onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                        let meta = this.state.metadata;
                        let curItems = meta.filter(x => x.key == field);
                        if (curItems && curItems.length > 0) {
                            curItems[0].value = ev.target.value;
                        }
                        else {
                            meta.push({ key: field, value: ev.target.value });
                        }
                        this.setState({ metadata: meta });
                    }} placeholder={"Namn " + (i + 1)} />);
                }
            }
        }

        return <div>
            <Grid container className={classes.root} spacing={2} style={{ width: '100%', margin: 0, padding: 10, paddingTop: 0 }}>
                <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                        <TextField
                            id="numItems"
                            type="number"
                            label="Antal"
                            value={this.state.numItems}
                            onChange={this.onChange("numItems")}
                            InputProps={{
                                inputProps: { min: 1 }
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="product">Produkt</InputLabel>
                        <Select id="product" value={this.state.productId ?? ''} onChange={this.onChange("productId")}>
                            {this.state.products && this.state.products.map((product) => {
                                return <MenuItem value={product.id}>{product.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="variant">Variant</InputLabel>
                        <Select id="variant" value={this.state.variantId ?? ''} onChange={this.onChange("variantId")}>
                            {this.state.products && this.state.products.filter(x => x.id == this.state.productId).length > 0 && this.state.products.filter(x => x.id == this.state.productId)[0].variants.map((variant) => {
                                return <MenuItem value={variant.Id}>{variant.Name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="pricegroup">Prisgrupp</InputLabel>
                        <Select id="pricegroup" value={this.state.priceGroupId ?? ''} onChange={this.onChange("priceGroupId")}>
                            {this.state.selectedProduct && this.state.products.filter(x => x.id == this.state.productId).length > 0 && this.state.selectedProduct.variants.filter(x => x.Id == this.state.variantId)[0].priceGroups.map((priceGroup) => {
                                return <MenuItem value={priceGroup.id}>{priceGroup.Name + " - " + priceGroup.price + "kr"}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                {this.state.selectedProduct && this.state.products.filter(x => x.id == this.state.productId).length > 0 && this.state.selectedProduct.variants.filter(x => x.Id == this.state.variantId)[0].occations &&
                    <Grid item xs={12}>
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="occation">Tillfälle</InputLabel>
                            <Select id="occation" value={this.state.occationId ?? ''} onChange={this.onChange("occationId")}>
                                {this.state.selectedProduct && this.state.selectedProduct.variants.filter(x => x.Id == this.state.variantId)[0].occations.map((occation) => {
                                    return <MenuItem value={occation.id}>{occation.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                }
                {skiPassNameFields && skiPassNameFields.length > 0 &&
                    <Grid item xs={12}>
                        <InputLabel htmlFor="name">Spårkort registrerat på</InputLabel>
                        {skiPassNameFields}
                    </Grid>
                }
                {skiPassValidFields && skiPassValidFields.length > 0 &&
                    <Grid item xs={12}>
                        <InputLabel htmlFor="name">Spårkort giltighet</InputLabel>
                        {skiPassValidFields}
                    </Grid>
                }
                {this.state.loading &&
                    <Grid item xs={12}>
                        <LinearProgress color="primary" />
                    </Grid>
                }
                <Button variant="contained" onClick={this.save}>Spara</Button>
                <Button variant="contained" onClick={() => this.props.cancel()}>Avbryt</Button>
            </Grid>
        </div>;
    }
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withStyles(useStyles)(EditOrderItem);