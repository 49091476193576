import { Grid, Button, Card, CardActions, CardContent, CardHeader, MenuItem, FormControlLabel, Checkbox, Dialog, DialogContent, DialogActions, DialogTitle, IconButton, Box } from "@material-ui/core";
import { FieldArray, FieldArrayRenderProps, Formik, FormikProps } from "formik";
import moment from "moment";
import React from "react";
import { B2bCompanyProduct, B2bCompanyProductExtraField } from "../../../model/B2bCompany";
import { StartGroup } from "../../../model/StartGroup";
import FormDateField from "../../Common/FormDateField";
import FormSelectField from "../../Common/FormSelectField";
import FormTextField from "../../Common/FormTextField";
import editB2bCompanyProductsValidationSchema from "./EditB2bCompanyProductValidationSchema";
import DeleteIcon from '@material-ui/icons/Delete';
import FormReadonlyField from "../../Common/FormReadOnlyField";

interface IProps {
    productIdx: number;
    product: B2bCompanyProduct;
    onSave: (product: B2bCompanyProduct) => void;
    onClose: () => void;
    onDelete: () => void;
    startGroups?: StartGroup[];
}

class EditB2bCompanyProductDialog extends React.Component<IProps> {

    private readonly fieldTypes = ["choice"];

    constructor(props: IProps) {
        super(props);
    }

    render(): JSX.Element {
        const { product } = this.props;

        return (<Formik
            initialValues={product}
            validationSchema={editB2bCompanyProductsValidationSchema}
            onReset={() => {
                this.props.onClose();
            }}
            onSubmit={(product: B2bCompanyProduct) => {
                this.props.onSave(product);
            }}
        >
            {formik => {
                return this.renderDialog(formik);
            }}
        </Formik>)
    }

    private renderDialog = (formik: FormikProps<B2bCompanyProduct>): JSX.Element => {
        const { values, isValid, dirty, handleSubmit, handleReset, isSubmitting } = formik;
        const isSaveDisabled = !dirty || !isValid || isSubmitting;
        const { startGroups } = this.props;
        const hasStartGroups = startGroups && startGroups.length > 0;

        return (
            <Dialog
                open={true}
                disableBackdropClick
                disableEscapeKeyDown
                fullWidth={true}
                maxWidth="lg"
            >
                <DialogTitle>
                    {this.cardTitle()}
                </DialogTitle>
                <DialogContent dividers>
                    <Card style={{ marginTop: 10 }}>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={4} >
                                    <FormTextField {...formik}
                                        propName={`name`}
                                        label="Namn"
                                        valueGetter={() => values.name} />
                                </Grid>
                                <Grid item xs={8}>
                                    <FormTextField {...formik}
                                        propName={`description`}
                                        label="Beskrivning"
                                        valueGetter={() => values.description} />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormDateField {...formik}
                                        propName={`validFrom`}
                                        label="Giltig från"
                                        valueFormatter={() => this.formatDate(values.validFrom)} />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormDateField {...formik}
                                        propName={`validTo`}
                                        label="Giltig t.o.m."
                                        valueFormatter={() => this.formatDate(values.validTo)} />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormTextField {...formik}
                                        type="number"
                                        propName={`maxNumRegistrations`}
                                        label="Max antal"
                                        valueGetter={() => values.maxNumRegistrations ?? ""} />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormReadonlyField {...formik}
                                        propName={`numRegistred`}
                                        label="Antal anmälda" />
                                </Grid>
                                {hasStartGroups &&
                                    <Grid item xs={4}>
                                        <FormSelectField {...formik}
                                            propName={`enforcedStartGroupId`}
                                            label="Forcerad startgrupp"
                                            valueGetter={() => values.enforcedStartGroupId ?? ""}
                                        >
                                            {startGroups.map((x, sIdx) => {
                                                return <MenuItem key={sIdx} value={x.id}>{x.name}</MenuItem>;
                                            })}
                                        </FormSelectField>
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <Card>
                                        <CardHeader title="Extra fält"></CardHeader>
                                        <FieldArray
                                            name={`extraFields`}
                                            render={arrayHelpers => (
                                                <>
                                                    {!!(values.extraFields && values.extraFields.length) && <CardContent>
                                                        {values.extraFields && values.extraFields.map((extraField, eIdx) => {
                                                            return this.renderExtraField(formik, extraField, eIdx, arrayHelpers);
                                                        })}
                                                    </CardContent>
                                                    }
                                                    <CardActions>
                                                        <Button
                                                            variant="contained"
                                                            onClick={() => { this.addExtraField(arrayHelpers); }}
                                                        >Lägg till extra fält</Button>
                                                    </CardActions>
                                                </>
                                            )} />
                                    </Card>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={() => this.props.onDelete()}
                    >Ta bort produkt</Button>
                    <Button
                        variant="contained"
                        onClick={handleReset}
                    >Avbryt</Button>
                    <Button
                        color="secondary"
                        variant="contained"
                        disabled={isSaveDisabled}
                        onClick={() => handleSubmit()}
                    >Stäng</Button>
                </DialogActions>
            </Dialog>
        )
    }

    private renderExtraField(
        formik: FormikProps<B2bCompanyProduct>,
        field: B2bCompanyProductExtraField,
        extraFieldIdx: number,
        arrayHelpers: FieldArrayRenderProps): JSX.Element {

        const { setFieldValue } = formik;

        const validValuesGetter = (): string => {
            if (!field.validValues) {
                return "";
            }

            const arr = JSON.parse(field.validValues);
            return arr.join("\n");
        };

        const validValuesSetter = (str: string): void => {
            const arr = str.split("\n");
            setFieldValue(`extraFields[${extraFieldIdx}].validValues`, JSON.stringify(arr));
        };

        const isNew = !!field.id;
        const isLocked = !!formik.values.numRegistred && isNew;

        return (
            <Card style={extraFieldIdx === 0 ? { marginTop: 0 } : { marginTop: 10 }} key={extraFieldIdx}>
                <CardContent>
                    <Grid container key={extraFieldIdx} spacing={2}>
                        <Grid item xs={3} >
                            <FormTextField {...formik}
                                disabled={isLocked}
                                propName={`extraFields[${extraFieldIdx}].name`}
                                label="Namn"
                                valueGetter={() => field.name ?? ""} />
                        </Grid>
                        <Grid item xs={8} >
                            <FormTextField {...formik}
                                propName={`extraFields[${extraFieldIdx}].description`}
                                label="Beskrivning"
                                valueGetter={() => field.description ?? ""} />
                        </Grid>
                        <Grid item xs={1}>
                            <Box display="flex" justifyContent="flex-end">
                                <IconButton
                                    disabled={isLocked}
                                    onClick={() => { arrayHelpers.remove(extraFieldIdx); }}>
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        </Grid>
                        <Grid item xs={3} >
                            <FormControlLabel
                                label="Använd som Team"
                                control={
                                    <Checkbox
                                        checked={field.useAsTeamInEntry ?? false}
                                        onChange={(ev) => { setFieldValue(`extraFields[${extraFieldIdx}].useAsTeamInEntry`, ev.target.checked) }} />
                                } />
                        </Grid>
                        <Grid item xs={3}>
                            <FormSelectField {...formik}
                                disabled={isLocked}
                                propName={`extraFields[${extraFieldIdx}].type`}
                                label="Typ"
                                valueGetter={() => field.type ?? ""}
                            >
                                {this.fieldTypes.map((fieldType, key) => {
                                    return <MenuItem key={key} value={fieldType}>{fieldType}</MenuItem>;
                                })}
                            </FormSelectField>
                        </Grid>
                        {field.type === "choice" &&
                            <Grid item xs={6} >
                                <FormTextField {...formik}
                                    propName={`extraFields[${extraFieldIdx}].validValues`}
                                    label="Värden"
                                    rows={4}
                                    multiline={true}
                                    valueGetter={() => { return validValuesGetter(); }}
                                    onChange={() => (e: any) => {
                                        validValuesSetter(e.target.value);
                                    }} />
                            </Grid>
                        }
                    </Grid>
                </CardContent>
            </Card>
        )
    };

    private cardTitle = (): string => {
        const { product } = this.props;

        let desc = product.name ? product.name : "Produkt";

        if (product.description) {
            desc += " - ";
            desc += (product.description.length > 40) ? product.description.slice(0, 40 - 1) + '...' : product.description;
        }

        return desc;
    };

    private formatDate = (dateStr: string) => {
        return dateStr ? moment(dateStr).format("YYYY-MM-DD") : "";
    };

    private addExtraField = (arrayHelpers: FieldArrayRenderProps) => {
        arrayHelpers.push({});
    };
}

export default EditB2bCompanyProductDialog;
