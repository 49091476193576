/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import React from 'react';
import {
    Theme, createStyles, WithStyles, Button, withStyles, Dialog, DialogContent, CircularProgress, DialogTitle, DialogActions
} from '@material-ui/core';
import { Formik } from 'formik';
import { Product } from '../../../model/Product';
import { Entry } from '../../../model/Entry';
import { ApiBackend } from '../../../providers/apibackend';
import ManageOrder from '../../Orders/ManageOrder';
import validationSchema from './EntryValidationSchema';
import EditEntryFormContent from './EditEntryFormContent';
import EditPersonDialog from '../Person/EditPersonDialog';

interface Props {
    entryId: string;
    onSave: (entry: Entry) => void;
    onAbortEdit: () => void;
}

interface IState {
    loading: boolean;
    entry: Entry;
    entryProduct: Product;
    showOrder: boolean;
    showPerson: boolean;
}

class EditEntryDialog extends React.Component<WithStyles & Props, IState> {
    private readonly api: ApiBackend;

    constructor(props: WithStyles & Props) {
        super(props);
        this.state = { entry: null, loading: true, entryProduct: null, showOrder: false, showPerson: false };
        this.api = new ApiBackend();
    }

    async componentDidMount() {
        const entry = await this.api.getEntry(this.props.entryId);
        const product = await this.api.getProduct(entry.productId);
        this.setState({ entry: entry, loading: false, entryProduct: product });
    }

    render(): JSX.Element {
        return <div>
            {this.renderEditEntry()}
        </div>;
    }

    renderEditEntry(): JSX.Element {
        const { loading, entry, showOrder, showPerson, entryProduct } = this.state;
        const { classes, onAbortEdit, onSave } = this.props;

        return <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            fullWidth={true}
            maxWidth="lg"
            aria-labelledby="edit-entry-title"
            open={true}
        >
            <DialogTitle id="edit-entry-title">
                {
                    loading ? "Laddar" :
                        (<>
                            {this.cardHeader()}
                            <Button style={{ position: 'absolute', right: 8, top: 8 }}
                                variant="contained"
                                onClick={() => {
                                    this.setState({ showOrder: true });
                                }}>
                                Visa order
                            </Button>
                        </>)
                }
            </DialogTitle>

            {loading &&
                (<>
                    <DialogContent dividers>
                        <CircularProgress color='secondary' />
                    </DialogContent>
                </>)
            }
            {entry &&
                <Formik
                    initialValues={entry}
                    validationSchema={validationSchema}
                    onReset={(values): void => {
                        this.setState({ entry: values });
                        onAbortEdit();
                    }}
                    onSubmit={async (values, { resetForm }) => {
                        const res = await this.api.saveEntry(values);
                        if (res) {
                            resetForm({ values: values });
                            onSave(entry);
                        }
                    }}
                >
                    {formik => {
                        const { isValid, dirty, handleSubmit, handleReset, isSubmitting } = formik;
                        const isSaveDisabled = !dirty || !isValid || isSubmitting;
                        return (<form autoComplete="off" onSubmit={handleSubmit}>
                            <DialogContent dividers>
                                <EditEntryFormContent {...formik} 
                                    classes={classes} 
                                    entryProduct={entryProduct}
                                    onShowPerson={() => {
                                        this.setState({ showPerson: true });
                                    }}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button type="submit" color="secondary" disabled={isSaveDisabled} variant="contained">Spara</Button>
                                <Button style={{ marginLeft: 10 }} color="secondary" variant="contained" onClick={handleReset}>Avbryt</Button>
                            </DialogActions>
                        </form>)
                    }}
                </Formik>
            }
            {showOrder &&
                <ManageOrder orderId={entry.orderId} close={
                    () => {
                        this.setState({ showOrder: false });
                    }} 
                />
            }
            {showPerson &&
                    <EditPersonDialog
                        personId={entry.personId}
                        onAbortEdit={() => {
                            this.setState({ showPerson: false });
                        }}
                        onSave={() => {
                            this.setState({ showPerson: false });
                        }}
                    />
                }
        </Dialog>;
    }

    private cardHeader = (): string => {
        const { entry, entryProduct } = this.state;

        return `Hantera anmälan: ${entry.firstName} ${entry.lastName} - ${entryProduct.name} / ${entryProduct.variants?.find(x => x.Id === entry.variantId)?.Name}`;
    };
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
}
);

export default withStyles(useStyles)(EditEntryDialog);
