/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

 import React, { ChangeEvent, Fragment } from 'react'
 import FirebaseContext from '../providers/Firebase/context';
 import { Card, CardHeader, CardContent, Theme, WithStyles, withStyles, Button, createStyles, Grid, CardActionArea, TableContainer, Paper, Table, TableHead, TableRow, TableBody, Hidden, TableCell, Dialog, DialogTitle, DialogActions, LinearProgress } from '@material-ui/core';
 import { withRouter, RouteComponentProps } from 'react-router-dom';
 import { ApiBackend } from '../providers/apibackend';

 import EditIcon from '@material-ui/icons/Edit';
 import DeleteIcon from '@material-ui/icons/Delete';
import { CronJob } from '../model/CronJob';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
 
 interface State {
     loading : boolean;
     jobs: CronJob[],
     newJob : CronJob,
     editJob : CronJob,
     deleteConfirmation : CronJob;
     showDeleteConfirmation: boolean;
 
 }
 
 class ManageCronJobs extends React.Component<RouteComponentProps & WithStyles, State> {
     static contextType = FirebaseContext;
     constructor(props : RouteComponentProps & WithStyles)
     {
         super(props);
         this.state = {loading: true, jobs : null, newJob: null, editJob:null, deleteConfirmation: null, showDeleteConfirmation:false};
     }
 
     componentDidMount() {
         this.setState({loading:true});
         let back = new ApiBackend();
         back.listCronJobs().then((jobs) => {
             this.setState({ jobs: jobs, loading:false });
         });
     }
 
     addJob = () => {
         //this.props.history.push("/jobs/create");
         let j = {id: null, type:'mikadownload',data : {url:'', name:''} } as CronJob;
         this.setState({newJob: j});
     }
 
     editJob = (job : CronJob) => () =>{
         //this.props.history.push("/jobs/" + job.id);
         this.setState({editJob: job});
     }
     deleteJob = (job : CronJob) => () =>{
         this.setState({deleteConfirmation: job, showDeleteConfirmation:true});
     }
 
     render() {
         let { classes } = this.props as any;
         return <Fragment><Grid container className={classes.root} spacing={2}>
             <Grid item xs={12}>
                 
                 <Card>
 
                     <CardHeader className={classes.cardHeader} title="Schemalagda jobb" />
                     <CardContent>
                     {this.state.loading &&
                         <LinearProgress color="secondary"/>
                         }
                         <TableContainer component={Paper}>
                             <Table size="small" aria-label="a dense table">
                                 <TableHead>
                                     <TableRow>
                                         <StyledTableCell>Typ</StyledTableCell>
                                         <StyledTableCell>Url</StyledTableCell>
                                         <StyledTableCell>Namn</StyledTableCell>
                                         <StyledTableCell>Senat kört</StyledTableCell>
                                         <StyledTableCell></StyledTableCell>
                                     </TableRow>
                                 </TableHead>
                                 <TableBody>
                                     {this.state && this.state.jobs && this.state.jobs.map((job) => {
                                         return <TableRow>
                                             <StyledTableCell>{job.type}</StyledTableCell>
                                             <StyledTableCell>{job.data.url}</StyledTableCell>
                                             <StyledTableCell>{job.data.name}</StyledTableCell>
                                             <StyledTableCell>{job.lastRun ? moment(job.lastRun).format("YYYY-MM-DD HH:mm:ss") : 'aldrig kört'}</StyledTableCell>
                                             <StyledTableCell align="right">
                                                 <EditIcon onClick={this.editJob(job)} className={classes.icon} style={{cursor:'pointer'}}  />
                                             <DeleteIcon onClick={this.deleteJob(job)} className={classes.icon} style={{cursor:'pointer'}} /></StyledTableCell>
                                         </TableRow>
                                     })}
                                 </TableBody>
                             </Table></TableContainer>
                     </CardContent>
                     <CardActionArea>
                         <Button onClick={this.addJob}>Lägg till jobb</Button>
                     </CardActionArea>
                 </Card>
             </Grid>
         </Grid>
         {this.state && this.state.showDeleteConfirmation && this.renderConfirmationDialog()}
         {this.state && this.state.newJob && this.renderAddJob()}
         {this.state && this.state.editJob && this.renderEditJob()}
         </Fragment>;
     }

     onChange = (target: string, field: string) => (ev: React.ChangeEvent<HTMLInputElement>) => {
         let o = target == "new" ? this.state.newJob : this.state.editJob;

         if (field.indexOf(".") > 0) {
             let p = field.split(".");
             (o as any)[p[0]][p[1]] = ev.target.value;
         }
         else {
             (o as any)[field] = ev.target.value;
         }
         if (target == "new") {
             this.setState({
                 newJob: o
             });
         }
         else {
             this.setState({
                 editJob: o
             });
         }
     }

     
     renderAddJob() {
         return this.renderJobDialog('new',this.state.newJob);
     }
     renderEditJob() {
        return this.renderJobDialog('edit',this.state.editJob);
     }

     renderJobDialog(target: string, object: CronJob) {
         let {classes}= this.props;
         return <Dialog
         disableBackdropClick
         disableEscapeKeyDown
         maxWidth="xs"
         aria-labelledby="confirmation-dialog-title"
         open={true}
     ><Grid container className={classes.root} spacing={2}>
         <Grid item xs={12}>
             <Card>
                 <CardHeader className={classes.cardHeader} title={target=='new' ? "Skapa nytt jobb" : "Editera jobb"} />
                 <CardContent>
                     <form className={classes.form} noValidate autoComplete="off">
                         <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                             <Grid item xs={12}>
                             <FormControl style={{marginBottom:5}}><InputLabel id="select-label">Typ</InputLabel>
                             <Select  labelId="select-label" value={object.type} variant="filled" onChange={this.onChange(target,'type')}>
                                 <MenuItem value="mikadownload">Hämta fil från MIKA ftp</MenuItem>
                                 </Select></FormControl>
                             </Grid>
                             <Grid item xs={12} sm={6}>
                                 <TextField style={{ flex: 1, margin: '0 3px' }} id="standard-basic" value={object.data.url} onChange={this.onChange(target,'data.url')} label="URL" fullWidth />
                             </Grid>
                             <Grid item xs={12} sm={6}>
                                 <TextField style={{ flex: 1, margin: '0 3px' }} id="standard-basic" value={object.data.name} onChange={this.onChange(target,'data.name')} label="Namn" fullWidth />
                             </Grid>
                         </Grid>
                     </form>
                 </CardContent>
             </Card>
         </Grid>
     </Grid> <DialogActions>
                 <Button variant="contained" autoFocus onClick={this.handleAddCancel} color="primary">
                     Avbryt
           </Button>
                 <Button variant="contained" onClick={target=='new' ? this.createJob : this.updateJob} color="primary">
                     {target=='new' ?  "Skapa" : "Spara"}
           </Button>
             </DialogActions></Dialog>;
     }

     createJob = () => {
         this.setState({loading: true})
         let back = new ApiBackend();
         back.addCronJob(this.state.newJob).then(()  =>
         {
            this.setState({newJob:null});
            back.listCronJobs().then((jobs) => {
                this.setState({loading:false, jobs: jobs})
            });
         })
     }
     updateJob = () => {
        this.setState({loading: true})
        let back = new ApiBackend();
        back.updateCronJob(this.state.editJob).then(()  =>
        {
           this.setState({editJob:null});
           back.listCronJobs().then((jobs) => {
               this.setState({loading:false, jobs: jobs})
           });
        })
    }

     handleAddCancel = () => {
         this.setState({newJob: null, editJob: null});
     }


     renderConfirmationDialog() {
         return <Dialog
             disableBackdropClick
             disableEscapeKeyDown
             maxWidth="xs"
             aria-labelledby="confirmation-dialog-title"
             open={true}
         >
             <DialogTitle id="confirmation-dialog-title">Är du säker på att du vill ta bort jobbet?</DialogTitle>
             <DialogActions>
                 <Button variant="contained" autoFocus onClick={this.handleDeleteCancel} color="primary">
                     Avbryt
           </Button>
                 <Button variant="contained" onClick={this.handleDeleteOk} color="primary">
                     Ja, ta bort
           </Button>
             </DialogActions>
         </Dialog>;
     }
     handleDeleteCancel = () => {
         this.setState({ deleteConfirmation: null, showDeleteConfirmation: false });
     }
 
     handleDeleteOk = () => {
         this.setState({loading:true});
         var back = new ApiBackend();
         back.deleteCronJob(this.state.deleteConfirmation).then((res) => {
             if (res){
                 back.listCronJobs().then((jobs) => {
                     this.setState({deleteConfirmation: null, showDeleteConfirmation: false, jobs: jobs, loading:false });
                 });
             }
             else 
             {
                 alert("Kunde inte ta bort arrangemang!");
             }
         })
         
     }
 }
 
 
 
 const useStyles = ({ palette, spacing }: Theme) => createStyles({
     cardHeader: {
         background: palette.secondary.main,
         color: palette.secondary.contrastText,
         padding: 3
     },
     photo: {
         height: '30px',
         verticalAlign: 'middle',
         borderRadius: '10px'
     },
     root: {
 
     },
     form: {
         '& > *': {
             margin: spacing(1),
             width: '25ch',
         },
         '& label.Mui-focused': {
             color: palette.secondary.main,
         },
         '& .MuiInput-underline:after': {
             borderBottomColor: palette.secondary.main,
         },
     }
 }
 );
 
 const tableHeadStyles = ({ palette, spacing }: Theme) => createStyles({
     head: {
         background: palette.primary.main,
         color: palette.primary.contrastText,
     }
 });
 
 const StyledTableCell = withStyles(tableHeadStyles)(TableCell);
 
 export default withRouter(withStyles(useStyles)(ManageCronJobs));
 