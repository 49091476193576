/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

 import React, { Fragment } from 'react';
 import { Theme, createStyles, Grid, WithStyles, Card, CardHeader, CardContent, TextField, Button, withStyles, CardActionArea, LinearProgress, Container, Table, TableContainer, TableRow, Paper, TableCell, TableBody, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
 import { ApiBackend } from '../../providers/apibackend';
 import uuid from 'react-uuid';
 import { CodeHolder } from '../../model/CodeHolder';
 import { Code } from '../../model/Code';
 import TableHead from '@material-ui/core/TableHead/TableHead';
 import EditIcon from '@material-ui/icons/Edit';
 import DeleteIcon from '@material-ui/icons/Delete';
import { timeHours } from 'd3-time';
 
 
 interface Props {
     code : CodeHolder;
     newCode: (code: Code) => void;
     removeCode: (code: Code) => void;
     updateCode : (code: Code) => void;
 }
 interface State {
     showDeleteConfirmation: boolean;
     deleteConfirmation : Code;
     addCodes : boolean;
     addNumCodes : number;
     addCodeMaxActivations: number;
     addCodeString : string;
     addEditCode: Code;
 }
 
 
 /** 
  * Lists codes for Code
  */
 class ListCodes extends React.Component<WithStyles & Props, State> {
 
     state = { haveChanges: false, showDeleteConfirmation:false,deleteConfirmation:null as Code,addEditCode:null as Code,
     addCodes: false,
 addNumCodes:1,
 addCodeMaxActivations: 10,
 addCodeString:""};
 
     constructor(props: WithStyles & Props) {
         super(props);
     }
 
     editCode= (code: Code) => () =>{
         this.setState({addEditCode: code},() => {
             var el = document.getElementById("editCodeField");
             if (el)
             {
                 el.focus();
             }
         });
     }
     removeCode= (idx : number) => () => {
        this.setState({deleteConfirmation: this.props.code.Codes[idx], showDeleteConfirmation:true});
     }
 
     addCode = () => {
         this.setState({addCodes: true,addCodeString: this.createRandomCode()});
 
     }
 
     createCodes = () => {
        if (this.state.addNumCodes == 1)
         {
             this.props.newCode({
                    id: uuid(),
                    code: this.state.addCodeString,
                    maxUsages: this.state.addCodeMaxActivations,
                    numUsages:0
                });
         }
         else 
         {
             for (let i = 0; i < this.state.addNumCodes; i++)
             {
                this.props.newCode({
                    id: uuid(),
                    code: this.createRandomCode(),
                    maxUsages: this.state.addCodeMaxActivations,
                    numUsages:0
                });
    
             }
         }
         this.setState({addCodes: false});
     }
 
     onChangeStateProp = (prop : string) => (ev : React.ChangeEvent<HTMLInputElement>) => 
     {
         let o = {} as any;
         o[prop] = ev.target.value;
         this.setState(o);
     }
     changeEditField = (prop: string) =>  (ev : React.ChangeEvent<HTMLInputElement>) => {
         let o = this.state.addEditCode as any;
         o[prop] = ev.target.value;
         this.setState({addEditCode: o});
     }

     saveEdits = () => {
         this.props.updateCode(this.state.addEditCode);
         this.setState({addEditCode: null});
     }
 
     render() {
         let {classes} = this.props;
         return <Fragment><Grid container className={classes.root} spacing={2}>
             <Grid item xs={12}>
                 <Card>
                     <CardHeader className={classes.cardHeader} title="Aktiveringskoder" />
                     <CardContent>
                         <TableContainer component={Paper}>
                             <Table size="small">
                                 <TableHead>
                                     <TableRow>
                                         <StyledTableCell>Kod</StyledTableCell>
                                         <StyledTableCell>Max användningar</StyledTableCell>
                                         <StyledTableCell>Antal använda</StyledTableCell>
                                         <StyledTableCell></StyledTableCell>
                                     </TableRow>
                                 </TableHead>
                                 <TableBody>
                                     {this.props.code.Codes && this.props.code.Codes.map((code, idx) => {
                                         return <TableRow>
                                             <StyledTableCell>{code.code}</StyledTableCell>
                                             {this.state.addEditCode && this.state.addEditCode.id==code.id ? <TextField id="editCodeField" onBlur={this.saveEdits} value={this.state.addEditCode.maxUsages} onChange={this.changeEditField("maxUsages")}/>:
                                                     <StyledTableCell>{code.maxUsages}</StyledTableCell>
                                             }
                                             <StyledTableCell>{code.numUsages}</StyledTableCell>
                                             <StyledTableCell align="right">
                                                 <EditIcon onClick={this.editCode(code)} className={classes.icon} style={{ cursor: 'pointer' }} />
                                                 <DeleteIcon onClick={this.removeCode(idx)} className={classes.icon} style={{ cursor: 'pointer' }} />
                                             </StyledTableCell>
                                         </TableRow>
                                     })}
                                 </TableBody>
                             </Table></TableContainer>
 
                             {this.state && this.state.addCodes &&
                             <Grid item xs={12}>
                                 <Card style={{ marginTop: 10 }}>
                                     <CardHeader className={classes.cardHeader} title={"Lägg till aktiveringskoder"} />
                                     <CardContent>
                                         <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                                             <Grid item xs={12} sm={this.state.addNumCodes == 1 ? 4 : 6}>
                                                 <TextField type="number"  inputProps={{ min: 1 }} value={this.state.addNumCodes} label="Antal koder" onChange={this.onChangeStateProp('addNumCodes')} fullWidth />
                                             </Grid>
                                             {this.state.addNumCodes == 1 &&
                                                 <Grid item xs={12} sm={4}>
                                                     <TextField value={this.state.addCodeString} label="Kodsträng" onChange={this.onChangeStateProp('addCodeString')} fullWidth />
                                                 </Grid>
                                             }
                                             <Grid item xs={12} sm={this.state.addNumCodes == 1 ? 4 : 6}>
                                                 <TextField type="number"  inputProps={{ min: 1 }} value={this.state.addCodeMaxActivations} onChange={this.onChangeStateProp('addCodeMaxActivations')} label="Giltig antal gånger" fullWidth />
                                             </Grid>
                                         </Grid>
                                     </CardContent>
                                     <CardActionArea>
                                         <Button variant="contained" onClick={this.createCodes}>{this.state.addNumCodes == 1 ? "Skapa kod" : "Skapa koder"}</Button>
                                         <Button variant="contained" onClick={() => {this.setState({addCodes:false})}}>Avbryt</Button>
                                     </CardActionArea>
                                 </Card>
                             </Grid>
                             }
                     </CardContent>
                     <CardActionArea>
                         {!this.state.addCodes &&
                                <Button onClick={this.addCode}>Lägg till kod(er)</Button>
                         }
                     </CardActionArea>
                 </Card>
             </Grid>
         </Grid>
             {this.state && this.state.showDeleteConfirmation &&
                 this.renderConfirmationDialog()
             }
         </Fragment>
 
     }
     
     createRandomCode() {
         let randowCodes = [
             'A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z',
             '1', '2', '3', '4', '5', '6', '7', '8', '9', '0',
         ];
         let code = "";
         for (let i = 0; i < 5 ; i++)
         {
             code += randowCodes[Math.floor(Math.random() * randowCodes.length)];
 
         }
 
         return "VL-" + code;
     }
 
     renderConfirmationDialog() {
         return <Dialog
             disableBackdropClick
             disableEscapeKeyDown
             maxWidth="xs"
             aria-labelledby="confirmation-dialog-title"
             open={true}
         >
             <DialogTitle id="confirmation-dialog-title">Är du säker på att du vill ta bort aktiveringskoden?</DialogTitle>
             <DialogActions>
                 <Button variant="contained" autoFocus onClick={this.handleDeleteCancel} color="primary">
                     Avbryt
           </Button>
                 <Button variant="contained" onClick={this.handleDeleteOk} color="primary">
                     Ja, ta bort
           </Button>
             </DialogActions>
         </Dialog>;
     }
     handleDeleteCancel = () => {
         this.setState({ deleteConfirmation: null, showDeleteConfirmation: false });
     }
 
     handleDeleteOk = () => {
         this.props.removeCode(this.state.deleteConfirmation);
         this.setState({deleteConfirmation: null, showDeleteConfirmation: false});
         /*var back = new ApiBackend();
         back.deleteCode(this.props.code.id, this.state.deleteConfirmation).then(() => {
             this.props.codesChanged();
             this.setState({loading:false,deleteConfirmation: null, showDeleteConfirmation: false});
         })*/
     }
 }
 
 
 const tableHeadStyles = ({ palette, spacing }: Theme) => createStyles({
     head: {
         background: palette.primary.main,
         color: palette.primary.contrastText,
     }
 });
 
 const StyledTableCell = withStyles(tableHeadStyles)(TableCell);
 
 
 const useStyles = ({ palette, spacing }: Theme) => createStyles({
     cardHeader: {
         background: palette.secondary.main,
         color: palette.secondary.contrastText,
         padding: 3
     },
     photo: {
         height: '30px',
         verticalAlign: 'middle',
         borderRadius: '10px'
     },
     icon: {
         verticalAlign: 'middle',
         cursor: 'pointer'
     },
     form: {
         '& > *': {
             margin: spacing(1),
             width: '25ch',
         },
         '& label.Mui-focused': {
             color: palette.secondary.main,
         },
         '& .MuiInput-underline:after': {
             borderBottomColor: palette.secondary.main,
         },
     }
 }
 );
 
 export default withStyles(useStyles)(ListCodes);
 