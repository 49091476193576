/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import React from 'react';
import { Theme, createStyles, WithStyles, Button, withStyles, Dialog, DialogContent, CircularProgress, DialogTitle, DialogActions } from '@material-ui/core';
import { Formik } from 'formik';
import { ApiBackend } from '../../../providers/apibackend';
import { Person } from '../../../model/Person';
import validationSchema from './PersonValidationSchema';
import EditPersonFormContent from './EditPersonFormContent';

interface IProps {
    personId?: string;
    onSave: (person: Person) => void;
    onAbortEdit: () => void;
}

interface IState {
    loading: boolean;
    person: Person;
}

class EditPersonDialog extends React.Component<WithStyles & IProps, IState> {
    private readonly api: ApiBackend;

    constructor(props: WithStyles & IProps) {
        super(props);
        const person = this.props.personId ? null : { country: "SWE" } as Person;
        this.state = { person: person, loading: person ? false : true };
        this.api = new ApiBackend();

        if (this.props.personId) {
            this.api.getPerson(this.props.personId).then((person) => {
                this.setState({ person: person, loading: false });
            });
        }
    }

    render() {
        return <div>
            {this.renderEditPerson()}
        </div>;
    }

    renderEditPerson() {
        const title = this.state.person?.id ? "Hantera person" : "Lägg till person";
        const { loading, person } = this.state;
        const { classes, onAbortEdit, onSave } = this.props;

        return <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            fullWidth={true}
            maxWidth="lg"
            aria-labelledby="edit-person-title"
            open={true}
        >
            <DialogTitle id="edit-person-title">{loading ? "Laddar" : title}</DialogTitle>

            {loading &&
                (<>
                    <DialogContent dividers>
                        <CircularProgress color='secondary' />
                    </DialogContent>
                </>)
            }
            {person &&
                <Formik
                    initialValues={person}
                    validationSchema={validationSchema}
                    onReset={(values): void => {
                        this.setState({ person: values });
                        onAbortEdit();
                    }}
                    onSubmit={async (values, { resetForm }) => {
                        const person = await this.save(values);
                        resetForm({ values: person });
                        onSave(person);
                    }}
                >
                    {formik => {
                        const { isValid, dirty, handleSubmit, handleReset, isSubmitting } = formik;
                        const isSaveDisabled = !dirty || !isValid || isSubmitting;
                        return (<form autoComplete="off" onSubmit={handleSubmit}>
                            <DialogContent dividers>
                                <EditPersonFormContent {...formik} classes={classes} ></EditPersonFormContent>
                            </DialogContent>
                            <DialogActions>
                                <Button type="submit" color="secondary" disabled={isSaveDisabled} variant="contained">Spara</Button>
                                <Button style={{ marginLeft: 10 }} color="secondary" variant="contained" onClick={handleReset}>Avbryt</Button>
                            </DialogActions>
                        </form>)
                    }}
                </Formik>
            }
        </Dialog>;
    }

    private save = (values: Person): Promise<Person> => {
        let savePromise: Promise<Person>;
        if (this.state.person.id) {
            savePromise = this.api.savePerson(values);
        } else {
            savePromise = this.api.addPerson(values);
        }

        return savePromise;
    };
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withStyles(useStyles)(EditPersonDialog);
