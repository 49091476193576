import React from "react";
import { TextField } from "@material-ui/core";
import { FormikErrors, FormikTouched, getIn } from "formik";

interface IProps {
    disabled?: boolean;
    propName: string;
    label: string;
    type?: string;
    errors: FormikErrors<any>;
    touched: FormikTouched<any>;
    values: any;
    handleBlur: {
        (e: React.FocusEvent<any>): void;
        <T = string | any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
    };
    handleChange: {
        (e: React.ChangeEvent<any>): void;
        <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
    };
    valueGetter?: () => any;
    onChange?: (handler: any) => (e: any) => void;
}

class FormSelectField extends React.Component<IProps> {

    render(): JSX.Element {
        const { children, propName, label, type, values, touched, errors, handleChange, handleBlur, valueGetter, onChange, disabled } = this.props;

        const nestedProd = propName.indexOf(".") > -1;
        const errorFn = () => {
            if (!nestedProd) {
                return Boolean(touched[propName] && errors[propName]);
            }

            return Boolean(
                getIn(touched, propName) &&
                getIn(errors, propName)
            )
        };

        const helperFn = () => {
            if (!nestedProd) {
                return touched[propName] && errors[propName];
            }

            return (
                getIn(touched, propName) &&
                getIn(errors, propName)
            )
        };

        const changeImpl = onChange ? onChange(handleChange) : handleChange;

        return (
            <TextField
                id={propName}
                name={propName}
                select
                style={{ flex: 1, margin: '0 3px' }}
                type={type}
                label={label}
                InputLabelProps={{ shrink: true }}
                value={valueGetter ? valueGetter() : values[propName] || ""}
                fullWidth
                onChange={changeImpl}
                onBlur={handleBlur}
                error={errorFn()}
                helperText={helperFn()}
                disabled={disabled}
            >
                {children}
            </TextField>
        )
    }
}

export default FormSelectField;
