/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import React, { ChangeEvent, Fragment } from 'react'
import FirebaseContext from '../../providers/Firebase/context';
import { Card, CardHeader, CardContent, Theme, withStyles, Button, createStyles, Grid, TableCell, Dialog, DialogTitle, DialogActions, LinearProgress, TextField, Typography, Input, InputLabel, Select, MenuItem, Chip, Checkbox } from '@material-ui/core';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { ApiBackend } from '../../providers/apibackend';
import { DataGrid, ColDef, ValueGetterParams, ValueFormatterParams } from '@material-ui/data-grid';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Profile } from '../../model/Profile';

interface State {
    loading : boolean;
    users: Profile[],
    displayUsers : Profile[];
    search:string;
    deleteConfirmation : Profile;
    showDeleteConfirmation: boolean;
    displayUser : Profile;
}

class ManageUsers extends React.Component<RouteComponentProps, State> {
    static contextType = FirebaseContext;
    constructor(props : RouteComponentProps)
    {
        super(props);
        this.state = {loading: true, users : null, deleteConfirmation: null, showDeleteConfirmation:false, search:"", displayUsers: null,
        displayUser: null};
    }

    componentDidMount() {
        this.refresh();
    }

    onChange = (prop: string) =>  (ev : React.ChangeEvent<HTMLInputElement>) => {
        let n = {} as any;
        n[prop] = ev.target.value;
        this.setState(n, () => {
            this.refresh();
        });
    }

    onSearchChange = (ev : React.ChangeEvent<HTMLInputElement>)=>{

        this.setState({search: ev.target.value, loading:true},() => {
            this.refresh();
        });

    }

    refresh = () => {
        let back = new ApiBackend();
        this.setState({loading:true});
        back.listAllProfiles(this.state.search).then((users) => {
            this.setState({ displayUsers: users, loading:false});
        });
    }

    onCheckChange = (userId: string, role : string) => async (ev : React.ChangeEvent<HTMLInputElement>) =>
    {
        let user = this.state.displayUsers.filter(x=>x.id == userId)[0];
        if (ev.target.checked)
        {
            if (!user.accessRoles)
            {
                user.accessRoles = [role];
            }
            else 
            {
                user.accessRoles.push(role);
            }
        }
        else 
        {
            if (user.accessRoles)
            {
                user.accessRoles = user.accessRoles.filter(x=>x != role);
            }
        }
        this.setState({loading: true});
        let back = new ApiBackend();
        await back.updateProfileRoles(user.id, user.accessRoles);
        this.setState({loading:false});
    }

    render() {
        let { classes } = this.props as any;
        let rolColW = 75;

        let roles = [
            {name : "Administratör", id: 'admin'},
            {name : "Hantera produkter", id: 'manageproducts'},
            {name : "Hantera orders", id: 'manageorders'},
            {name : "Hantera rabattkoder", id: 'managecodes'},
            {name : "QR-Scanner", id: 'qrscan'},
            {name : "Hantera evenemang", id: 'manageevents'},
            {name : "Hantera anmälningar", id: 'manageentries'},
            {name : "Hantera företag", id: 'manageb2b'},
            {name : "Hantera kampanjer", id: 'managecampaigns'}
        ]

        const columns: ColDef[] = [
            { field: 'firstName', headerName: 'Namn', width: 120, valueGetter: (params) => {return params.data.firstName + " " + params.data.lastName}},
            { field: 'email', headerName: 'E-Post', width: 150}
        ];

        for (let i = 0; i < roles.length; i++)
        {
            let role = roles[i].id;
            let name = roles[i].name;
            columns.push( { field: 'email', headerName: name, width: rolColW,
            renderCell: (params) => {return <Checkbox key={"chk_" + params.data.id + "_" + role}  checked={params.data.accessRoles && params.data.accessRoles.indexOf(role) >=0} onChange={this.onCheckChange(params.data.id+'',role)} />}});
        }

        return <Fragment><Grid container className={classes.root} spacing={2}>
             <Grid item xs={12}>
             <Card>
                <CardHeader className={classes.cardHeader} title={<Fragment><Typography variant="h5" style={{display:'inline'}}>Filtrera </Typography></Fragment>} />
                <CardContent>
                <Grid container className={classes.root} spacing={2} >
                    <Grid item xs={12}>
                        <TextField autoComplete="off" style={{ flex: 1, margin: '0 3px' }} id="standard-basic" label="Namn / Epost / Telefon" value={this.state.search} onChange={this.onSearchChange} fullWidth />
                    </Grid>
                </Grid>
                </CardContent>
             </Card>
             </Grid>
            
            <Grid item xs={12}>
                
                <Card>

                    <CardHeader className={classes.cardHeader} title={<Fragment><Typography variant="h5" style={{display:'inline'}}>Användare </Typography>
                    <RefreshIcon style={{display:'inline', verticalAlign:'middle', cursor:'pointer'}} onClick={this.refresh}/></Fragment>} />
                    <CardContent>
                    {this.state.loading &&
                        <LinearProgress color="secondary"/>
                        }                        
                         <div style={{ height: 400, width: '100%' }}>
                        
                         <DataGrid  disableSelectionOnClick={true} loading={this.state.loading} rows={ this.state.displayUsers ?? []} columns={columns} pageSize={10} />
                        
                        </div>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
        {this.state && this.state.showDeleteConfirmation && this.renderConfirmationDialog()}
        
        </Fragment>;
    }
   
    renderConfirmationDialog() {
        return <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">Är du säker på att du vill ta bort användaren?</DialogTitle>
            <DialogActions>
                <Button variant="contained" autoFocus onClick={this.handleDeleteCancel} color="primary">
                    Avbryt
          </Button>
                <Button variant="contained" onClick={this.handleDeleteOk} color="primary">
                    Ja, ta bort
          </Button>
            </DialogActions>
        </Dialog>;
    }
    handleDeleteCancel = () => {
        this.setState({ deleteConfirmation: null, showDeleteConfirmation: false });
    }

    handleDeleteOk = () => {
        this.setState({loading:true});
    }
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
}
);

export default withRouter(withStyles(useStyles)(ManageUsers));