import Button from "@material-ui/core/Button/Button";
import Grid from "@material-ui/core/Grid/Grid";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import React from "react";
import { Product } from "../../model/Product";
import { ProductVariant } from "../../model/ProductVariant";
import { ApiBackend } from "../../providers/apibackend";

interface IProps {
    product: Product;
    productVariant: ProductVariant;
    isFormDirty: boolean;
}

interface IState {
    loading: boolean;
}

class ManageRace extends React.Component<IProps, IState> {
    state: IState;

    private readonly api: ApiBackend;

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: false
        };

        this.api = new ApiBackend();
    }

    render(): JSX.Element {
        const { isFormDirty } = this.props;
        const { loading } = this.state;

        return (<>
            {loading &&
                <LinearProgress variant="indeterminate" color="secondary" style={{ width: "100%" }} />
            }
            <Grid item xs={12} spacing={2}>
                <Button variant="contained" disabled={isFormDirty || loading} style={{ marginLeft: 10 }} onClick={this.calculateAgeCategories}>
                    Uppdatera ålderskategori för deltagare
                </Button>
            </Grid>
        </>);
    }

    private calculateAgeCategories = async () => {
        const { product, productVariant } = this.props;

        this.setState({ loading: true });

        const res = await this.api.updateAgeClassForParticipants(product.id, productVariant.Id);
        if (!res) {
            alert("Någonting gick fel vid uppdatering av ålderskategori för deltagare");
        }

        this.setState({ loading: false });
    };
}

export default ManageRace;
