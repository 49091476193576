import { Profile } from "../model/Profile";
import Axios, {  } from "axios";
import Firebase from "./Firebase/firebase";
import { Event, IEventWithProducts } from "../model/Event";
import { CodeHolder } from "../model/CodeHolder";
import { Code } from "../model/Code";
import { ListOrder, Order } from "../model/Order";
import { CompanyEntry, Entry, IListEntriesResponse } from "../model/Entry";
import { EntryStatistics } from "../model/EntryStatistics";
import { Product } from "../model/Product";
import moment from "moment";
import { OrderContact } from "../model/OrderContact";
import { Message } from "../model/Message";
import { OrderStatistics } from "../model/OrderStatistics";
import { Payment } from "../model/Payment";
import { ProductToDeliver } from "../model/ProductToDeliver";
import { PointOfSale } from "../model/PointOfSale";
import { OrderItem } from "../model/OrderItem";
import { Orderoverview } from "../model/Orderoverview";
import { CronJob } from "../model/CronJob";
import { Reservation } from "../model/Reservation";
import { RaceEntryOverview } from "../model/RaceEntryOverview";
import { IListPersonsResponse, Person } from "../model/Person";
import { CustomField } from "../model/CustomField";
import { Postcode } from "../model/Postcode";
import { B2bCompany } from "../model/B2bCompany";
import { Campaign, CampaignType } from "../model/Campaign";
import { Club } from "../model/Club";

export class ApiBackend {
    static config: config = CONFIG;
    static firebase: Firebase;
    static async initialize(firebase: Firebase) {
        ApiBackend.firebase = firebase;
    }

    static getProductTypes(): { name: string, code: string }[] {
        return [{ name: 'Bussbiljett', code: 'busticket' },
        { name: 'Spårkort', code: 'skipass' },
        { name: 'Lopp', code: 'race' },
        { name: 'Motionsförsäkring', code: 'insurance' },
        { name: 'Vallaservice', code: 'skiwax' },
        { name: 'Massage', code: 'massage' },
        { name: 'Annat', code: 'other' }
        ];
    }

    static getRaceTypes(): { name: string, code: string }[] {
        return [{ name: 'Individuell', code: 'individual' },
        { name: 'Team', code: 'team' },
        { name: 'Stafett', code: 'relay' }
        ];
    }

    static getTimingSuppliers(): { name: string, code: string }[] {
        return [{ name: 'EST', code: 'est' },
        { name: 'Annan', code: 'other' }
        ];
    }

    static getCampaignTypes(): { name: string, code: string }[] {
        return [
            {
                name: 'Vid köp av produkt', 
                code: CampaignType.Code4Product
            }
        ];
    }

    static getProductQrCodeUrl(productid: string) : string {
        return ApiBackend.config.backendBaseUrl + "binary/products/" + productid+"/qrcode";
    }

    static getPdfReceiptUrl(orderid: string) : string {
        return ApiBackend.config.backendBaseUrl + "binary/orders/" + orderid+"/pdf";
    }

    downloadAccountingXlsx(year : number, month: number, exportVerDate : string, exportRowComment: string, pointOfSale: string) : Promise<void> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "binary/accounting?year=" + year + "&month=" + month + "&verdate=" + exportVerDate + "&rowcomment="+exportRowComment + "&pointofsale=" + pointOfSale,
            {
            responseType: 'arraybuffer',
            headers: {
                Authorization: token
            }})
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                let y = year +"";
                let m = month+"";
                if (+month < 9)
                {
                    y = (+year-1)+"";
                    m = (+month+4) + "";
                }
                else 
                {
                    m = (+month-8)+"";
                }
                link.setAttribute('download', 'accounting_kontrollen_'+y+'_'+m+'.xlsx');
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => console.log(error));
        });
    }

    downloadAccountingDailyReport(date : string) : Promise<void> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "binary/accountingdaily?date=" + date,
            {
            responseType: 'arraybuffer',
            headers: {
                Authorization: token
            }})
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'kassarapport_vl_'+date+'.xlsx');
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => console.log(error));
        });
    }

    getProfile(): Promise<Profile> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "account", {
                headers: {
                    Authorization: token
                }
            }).then((resp) => {
                let profile = new Profile(resp.data);
                return profile;
            });
        }
        ).catch((err) => {
            return null;
        });
    }

    updateProfileRoles(userId : string, roles : string[]) : Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "account/" + userId + "/roles",roles, {
                headers: {
                    Authorization: token
                }
            }).then((resp) => {
                return resp.data;
            });
        }
        ).catch((err) => {
            return null;
        });
    }

    updateProfile(profile: Profile): Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "account", profile,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return false;
                });
        });
    }

    listAllProfiles(search : string) : Promise<Profile[]>{
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "account/accounts?s=" + search,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return false;
                });
        });
    }

    /**
     * Fetch all discount codes from backend, requires firebath JWT
     */
    listCodes(includeArchived:boolean): Promise<CodeHolder[]> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "codes" + (includeArchived ? "?includeArchived=true":""),
            {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return null;
            });
        });
    }

    listClubs(includeArchived:boolean): Promise<Club[]> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "entries/clubs" + (includeArchived ? "?includeArchived=true":""),
            {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return null;
            });
        });
    }

    saveClub(club: Club): Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "entries/clubs/" + club.id,
            club,
            {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return null;
            });
        });
    }

    getCode(codeHolderId: string): Promise<CodeHolder> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "codes/" + codeHolderId,
            {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return null;
            });
        });
    }

    listEvents(): Promise<Event[]> {
        return Axios.get(ApiBackend.config.backendBaseUrl + "events",
        ).then((res) => {
            return res.data;
        }).catch((err) => {
            return null;
        });
    }

    static listOrdersCancelToken = Axios.CancelToken.source();
    listOrders(filter: string = null, fromDate : Date, toDate : Date, statuses: string[]): Promise<ListOrder[]> {

        let sfilter = "?fromDate="+moment(fromDate).format("YYYY-MM-DD") + "&toDate=" + moment(toDate).format("YYYY-MM-DD") + "&statuses="+statuses.join(',')+ "&s="+filter;

        if (ApiBackend.listOrdersCancelToken != null)
        {
            console.log("cancelled");
            ApiBackend.listOrdersCancelToken.cancel();
        }

        ApiBackend.listOrdersCancelToken = Axios.CancelToken.source();

        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "orders" + sfilter,
            {
                cancelToken: ApiBackend.listOrdersCancelToken.token,
                headers: {
                    Authorization: token
                }
            }).then((res) => {
                ApiBackend.listOrdersCancelToken = null;
                return res.data;
            }).catch((err) => {
                ApiBackend.listOrdersCancelToken = null;
                return null;
            });
        });
    }

    addOrderPayment(payment : {orderId: string, paymentType: "PAYMENT" | "REVERSAL", amount: number, pointOfSaleId: string, description: string}) : Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.put(ApiBackend.config.backendBaseUrl + "orders/" + payment.orderId + "/payments", payment,
            {
                headers: {
                    Authorization: token
                }
            }).then((res) => {
                return res.data;
            }).catch((err) => {
                return null;
            });
        });
    }
    
    listPointOfSales(): Promise<PointOfSale[]> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "orders/pos",
            {
                headers: {
                    Authorization: token
                }
            }).then((res) => {
                return res.data;
            }).catch((err) => {
                return null;
            });
        });
    }

    addOrUpdatePointOfSales(pos : PointOfSale): Promise<PointOfSale> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "orders/pos",pos,
            {
                headers: {
                    Authorization: token
                }
            }).then((res) => {
                return res.data;
            }).catch((err) => {
                return null;
            });
        });
    }

    deletePointOfSale(pos : PointOfSale): Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.delete(ApiBackend.config.backendBaseUrl + "orders/pos/" + pos.id,
            {
                headers: {
                    Authorization: token
                }
            }).then((res) => {
                return res.data;
            }).catch((err) => {
                return null;
            });
        });
    }

    listProductsToDeliver(filter: string = null, productId: string, productVariantIds: string[], includeDelivered: boolean): Promise<ProductToDeliver[]> {

        let sfilter = "?productId=" + productId + "&productVariantIds=" + productVariantIds.join(',') + "&s="+filter + "&includeDelivered=" + (includeDelivered ? "true":"false");

        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "orders/deliver" + sfilter,
            {
                headers: {
                    Authorization: token
                }
            }).then((res) => {
                return res.data;
            }).catch((err) => {
                return null;
            });
        });
    }

    deliverProduct(orderId : string, productId: string, productVariantId: string, productVariantPriceGroupId: string, numItems : number) : Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.put(ApiBackend.config.backendBaseUrl + "orders/deliver",{orderId: orderId, productId: productId, productVariantId: productVariantId, productVariantPriceGroupId: productVariantPriceGroupId, numDelivered: numItems},
            {
                headers: {
                    Authorization: token
                }
            }).then((res) => {
                return res.data;
            }).catch((err) => {
                return null;
            });
        });
    }

    createOrder(order: Order) : Promise<Order> {
        return Axios.put(ApiBackend.config.backendBaseUrl + "checkout/order",order).then((res : {data: {order: Order}}) => {
            if (res && res.data)
            {
                return res.data.order;
            }
            else {
                return null;
            }
        }).catch((err) => {
            console.log(err);
            return null;
        });

    }

    retreiveOrder(id: string): Promise<Order> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "checkout/order/" + id,
            {
                headers: {
                    Authorization: token
                }
            }).then((res) => {
                return res.data.order;
            }).catch((err) => {
                return null;
            });
        });
    }

    getOrderCommunicationHistory(orderid: string): Promise<Message[]> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "orders/" + orderid + "/messages",
            {
                headers: {
                    Authorization: token
                }
            }).then((res) => {
                return res.data;
            }).catch((err) => {
                return null;
            });
        });
    }

    getOrderPaymentHistory(orderid: string): Promise<Payment[]> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "orders/" + orderid + "/payments",
            {
                headers: {
                    Authorization: token
                }
            }).then((res) => {
                return res.data;
            }).catch((err) => {
                return null;
            });
        });
    }

    updateOrderContact(orderid: string, contact : OrderContact): Promise<Order> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "orders/" + orderid + "/contact",contact,
            {
                headers: {
                    Authorization: token
                }
            }).then((res) => {
                return res.data;
            }).catch((err) => {
                return null;
            });
        });
    }

    updateOrderItems(orderid: string, items : OrderItem[]): Promise<Order> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "orders/" + orderid + "/items",items,
            {
                headers: {
                    Authorization: token    
                }
            }).then((res) => {
                return res.data;
            }).catch((err) => {
                return null;
            });
        });
    }

    getOrderOverview(productId: string) : Promise<Orderoverview> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "orders/overview/" + productId,
            {
                headers: {
                    Authorization: token
                }
            }).then((res) => {
                return res.data;
            }).catch((err) => {
                return null;
            });
        });
    }

    listEntries(filter: string = null, skip: number = null, take: number = null): Promise<IListEntriesResponse | null> {       
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            let queryParams = "";
            if (filter) {
                queryParams += `?s=${filter}`;
            }

            if (skip !== null) {
                queryParams += (queryParams ? "&" : "?");
                queryParams += `begin=${skip}`;
            }

            if (take !== null) {
                queryParams += (queryParams ? "&" : "?");
                queryParams += `limit=${take}`;
            }

            return Axios.get(ApiBackend.config.backendBaseUrl + "entries" + queryParams,
            {
                headers: {
                    Authorization: token
                }
            }).then((res) => {
                return res.data;
            }).catch((err) => {
                return null;
            });
        });
    }

    downloadEntriesAsCSV(eventId: string) : Promise<void>{
        console.log("downloading: " + eventId);
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "binary/events/" + eventId + "/entries" ,{},
            {
            responseType: 'arraybuffer',
            headers: {
                Authorization: token
            }})
            .then((response) => {
                console.log(response.data);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href=url;
                link.setAttribute('download', 'entries_' + eventId + '_'+(new Date().toISOString()) + '.csv');
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => console.log(error));
        });

    }

    listPersons(filter: string = null, skip: number = null, take: number = null): Promise<IListPersonsResponse | null> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            let queryParams = "";
            if (filter) {
                queryParams += `?s=${filter}`;
            }

            if (skip !== null) {
                queryParams += (queryParams ? "&" : "?");
                queryParams += `begin=${skip}`;
            }

            if (take !== null) {
                queryParams += (queryParams ? "&" : "?");
                queryParams += `limit=${take}`;
            }

            return Axios.get(ApiBackend.config.backendBaseUrl + "entries/persons" + queryParams,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return null;
                });
        });
    }

    getPerson(id: string): Promise<Person> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "entries/persons/" + id,
            {
                headers: {
                    Authorization: token
                }
            }).then((res) => {
                return res.data;
            }).catch((err) => {
                return null;
            });
        });
    }

    savePerson(person: Person): Promise<Person> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "entries/persons/" + person.id, person,
            {
                headers: {
                    Authorization: token
                }
            }).then((res) => {
                return res.data;
            }).catch((err) => {
                return null;
            });
        });
    }

    addPerson(person: Person): Promise<Person> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.put(ApiBackend.config.backendBaseUrl + "entries/persons/", person,
            {
                headers: {
                    Authorization: token
                }
            }).then((res) => {
                return res.data;
            }).catch((err) => {
                return null;
            });
        });
    }

    listB2bCompanies(includeArchived = false): Promise<B2bCompany[] | null> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            const showArchived = includeArchived ? "1" : "0";
            return Axios.get(`${ApiBackend.config.backendBaseUrl}b2b/companies?showarchived=${showArchived}`,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return null;
                });
        });
    }

    listCompanyProductEntries(companyId: string, companyProductId: string): Promise<{customHeaders: { key: string; name: string; }[]; entries: CompanyEntry[]}> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(`${ApiBackend.config.backendBaseUrl}b2b/companies/${companyId}/entries?companyProductId=${companyProductId}`,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return null;
                });
        });
    }

    getB2bCompany(id: string): Promise<B2bCompany> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "b2b/companies/" + id,
            {
                headers: {
                    Authorization: token
                }
            }).then((res) => {
                if (res && res.data && !res.data.products) {
                    res.data.products = [];
                }

                return res.data;
            }).catch((err) => {
                return null;
            });
        });
    }

    saveB2bCompany(company: B2bCompany): Promise<B2bCompany | string> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "b2b/companies/" + company.id, company,
            {
                headers: {
                    Authorization: token
                }
            }).then((res) => {
                return res.data;
            }).catch((err) => {
                if (err.response.status === 400) {
                    return err.response.data;
                }
                return null;
            });
        });
    }

    createB2bCompany(companyName: string): Promise<B2bCompany> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.put(ApiBackend.config.backendBaseUrl + "b2b/companies/", { name: companyName },
            {
                headers: {
                    Authorization: token
                }
            }).then((res) => {
                return res.data;
            }).catch((err) => {
                return null;
            });
        });
    }

    getEntryStatistics() : Promise<EntryStatistics> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "statistics/entries",
            {
                headers: {
                    Authorization: token
                }
            }).then((res) => {
                return res.data;
            }).catch((err) => {
                return null;
            });
        });
    }

    getOrderStatistics() : Promise<OrderStatistics> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "statistics/orders",
            {
                headers: {
                    Authorization: token
                }
            }).then((res) => {
                return res.data;
            }).catch((err) => {
                return null;
            });
        });
    }

    getEvent(eventId: string): Promise<Event> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "events/"+eventId,
            {
                headers: {
                    Authorization: token
                }
            }).then((res) => {
                return res.data;
            }).catch((err) => {
                return null;
            });
        });
    }

    createOrUpdateEvent(event: IEventWithProducts): Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "events", event, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    addCode(codeHolderId: string, code : Code) : Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "codes/" + codeHolderId + "/codes", code, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    updateCode(codeHolderId: string, code : Code) : Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "codes/" + codeHolderId + "/codes/" + code.id, code, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    deleteCode(codeHolderId: string, code : Code) : Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.delete(ApiBackend.config.backendBaseUrl + "codes/" + codeHolderId + "/codes/" + code.id, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    deleteEvent(event : Event) : Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.delete(ApiBackend.config.backendBaseUrl + "events/" + event.id, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    createOrUpdateCode(code : CodeHolder) : Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "codes/",code, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    generateCodes(codeHolderId : string, numCodes : number, maxActivations: number) : Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "codes/" + codeHolderId + "/codes/generate",{numCodes: numCodes, maxActivations: maxActivations}, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    deleteCodeHolder(code : CodeHolder) : Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.delete(ApiBackend.config.backendBaseUrl + "codes/" + code.id, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }
   
    deleteProduct(product : Product) : Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.delete(ApiBackend.config.backendBaseUrl + "products/" + product.id, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    getProduct(id: string) : Promise<Product> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "products/" + id, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    getProductsForEvent(eventId: string) : Promise<Product[]> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(`${ApiBackend.config.backendBaseUrl}events/${eventId}/products`, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    updateAgeClassForParticipants(productId: string, variantId: string): Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(`${ApiBackend.config.backendBaseUrl}products/${productId}/${variantId}/updateageclass`, null, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    getEntryOverview(eventId: string) : Promise<RaceEntryOverview> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "entries/overview/" + eventId, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    getEntry(entryId: string) : Promise<Entry> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "entries/" + entryId, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    saveEntry(entry: Entry): Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "entries/", entry,
            {
                headers: {
                    Authorization: token
                }
            }).then((res) => {
                return res.data;
            }).catch((err) => {
                return null;
            });
        });
    }

    listProducts(includeArchived: boolean = false) : Promise<Product[]> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "products/?showarchived=" + (includeArchived ? "1" : "0"), {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }
    
    listCustomFields() : Promise<CustomField[]> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "products/customfields/", {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    addCustomField(field : CustomField) : Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.put(ApiBackend.config.backendBaseUrl + "products/customfields/", field, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    updateCustomField(field : CustomField) : Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "products/customfields/" + field.id, field, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    deleteCustomField(fieldId : string) : Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.delete(ApiBackend.config.backendBaseUrl + "products/customfields/" + fieldId, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    listReservations(includeArchived: boolean = false) : Promise<Reservation[]> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "entries/reservations", {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    createReservation(reservation: Reservation) : Promise<Reservation> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.put(ApiBackend.config.backendBaseUrl + "entries/reservations",reservation, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    updateReservation(reservation: Reservation) : Promise<Reservation> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "entries/reservations/" + reservation.id,reservation, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    deleteReservation(reservation: Reservation) : Promise<Reservation> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.delete(ApiBackend.config.backendBaseUrl + "entries/reservations/" + reservation.id, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    createProduct(product : Product) : Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.put(ApiBackend.config.backendBaseUrl + "products/",product, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    updateProduct(product : Product) : Promise<boolean> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "products/" + product.id ,product, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    resendOrderconfirmation(orderId : string) {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "orders/" + orderId + "/sendconfirmation",null, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    listCronJobs() {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + "cronjobs", {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    addCronJob(job: CronJob) {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.put(ApiBackend.config.backendBaseUrl + "cronjobs", job , {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    updateCronJob(job: CronJob) {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + "cronjobs/" + job.id, job , {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    deleteCronJob(job: CronJob) {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.delete(ApiBackend.config.backendBaseUrl + "cronjobs/"+ job.id , {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    static getPostCodeCancelToken = Axios.CancelToken.source();
    getPostCode(postcode: string): Promise<Postcode> {  
        ApiBackend.getPostCodeCancelToken = Axios.CancelToken.source();
        const url = `${ApiBackend.config.backendBaseUrl}externaldata/postcodes/${postcode}/SE`;
        return Axios.get(url, { cancelToken: ApiBackend.getPostCodeCancelToken.token })
            .then((res) => {
                ApiBackend.getPostCodeCancelToken = null;
                return res.data;
            }).catch(() => {
                ApiBackend.getPostCodeCancelToken = null;
                return null;
            });
    }

    getGeneral(path : string) {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(ApiBackend.config.backendBaseUrl + path , {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    postGeneral(path : string, data: any) {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.post(ApiBackend.config.backendBaseUrl + path,data, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    putGeneral(path : string, data: any) {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.put(ApiBackend.config.backendBaseUrl + path,data, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    deleteGeneral(path : string) {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.delete(ApiBackend.config.backendBaseUrl + path, {
                headers: {
                    Authorization: token
                }
            }
            ).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }

    listCampaigns(): Promise<Campaign[] | null> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(`${ApiBackend.config.backendBaseUrl}campaigns`,
                {
                    headers: {
                        Authorization: token
                    }
                }).then((res) => {
                    return res.data;
                }).catch((err) => {
                    return null;
                });
        });
    }

    getCampaign(id: string): Promise<Campaign> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.get(`${ApiBackend.config.backendBaseUrl}campaigns/${id}`,
            {
                headers: {
                    Authorization: token
                }
            }).then((res) => {
                return res.data;
            }).catch((err) => {
                return null;
            });
        });
    }

    saveOrUpdateCampaign(campaign: Campaign): Promise<Campaign> {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.put(`${ApiBackend.config.backendBaseUrl}campaigns`, campaign,
            {
                headers: {
                    Authorization: token
                }
            }).then((res) => {
                return res.data;
            }).catch((err) => {
                return null;
            });
        });
    }

    addCampaignCodes(campaignId: string, codes: string[]) {
        return ApiBackend.firebase.auth().currentUser.getIdToken().then(token => {
            return Axios.put(`${ApiBackend.config.backendBaseUrl}campaigns/${campaignId}/codes`, codes,
            {
                headers: {
                    Authorization: token
                }
            }).then((res) => {
                return res.data;
            }).catch((err) => {
                return false;
            });
        });
    }
}

interface config {
    backendBaseUrl: string;
}
