import React from "react";
import { TextField } from "@material-ui/core";

interface IProps {
    propName: string;
    label?: string;
    values: any;
    valueFormatter?: (value: any) => string;
}

class FormReadonlyField extends React.Component<IProps> {

    render(): JSX.Element {
        const { propName, label, values, valueFormatter } = this.props;
        return (
            <TextField
                id={propName}
                disabled
                style={{ flex: 1, margin: '0 3px' }}
                type="text"
                label={label}
                InputLabelProps={{ shrink: true }}
                value={valueFormatter ? valueFormatter(values[propName]) : values[propName]}
                fullWidth
            />
        )
    }
}

export default FormReadonlyField;
