export class CustomField {
    id: string;
    name: string;
    label : string;
    label_en: string;
    description: string;
    description_en: string;
    type: string;
    validation_type: string;
    validvalues: string[];
    handleAsClubName: boolean;
}   