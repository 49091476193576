import {  Button, Card, CardContent, CardHeader, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress, TextField, Theme, Typography, withStyles, WithStyles } from '@material-ui/core';
import React, { Fragment } from 'react';
import { Order } from '../../model/Order';
import { OrderContact } from '../../model/OrderContact';
import { ApiBackend } from '../../providers/apibackend';
import EditIcon from '@material-ui/icons/Edit';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import Modal from '@material-ui/core/Modal/Modal';



interface Props {
    order: Order;
    editMode?: boolean;
    hideSave?: boolean;
    dataChanged: () => void;
}
interface State {
    editOrderContact : boolean;
    contact : OrderContact;
    saving: boolean;
}

class ManageOrderContact extends React.Component<Props & WithStyles,State>
{
    constructor(props: Props & WithStyles)
    {
        super(props);
        this.state={editOrderContact: this.props.editMode ?? false, contact: props.order.contact, saving: false}
    }

    changeContactVal = (field : string) => (ev : React.ChangeEvent<HTMLInputElement>) => {
        var o = this.state.contact as any;
        o[field] = ev.target.value;
        this.setState({contact: o});
    }
    saveOrderContact = () => {
        let back = new ApiBackend();
        this.setState({saving:true});
        back.updateOrderContact(this.props.order.id, this.state.contact).then(order => {
            this.setState({saving:false});
            this.props.dataChanged();
        });
    }

    render () {
        let {classes}= this.props;
        let o = this.props.order;

        return <Card>
        <CardHeader className={classes.cardHeader} title={<Fragment>Beställare <EditIcon onClick={() => {this.setState({editOrderContact: true});}} /></Fragment>} />
        <CardContent>
        {this.state.saving ? <Modal
                        open={true}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >
                        <CircularProgress color="primary" />
                    </Modal> : null}

            {this.state.editOrderContact ? 
            <Grid container spacing={2}>
                <Grid item xs={4}>Namn</Grid>
                <Grid item xs={4}><TextField value={o.contact.firstName ?? ''} onChange={this.changeContactVal('firstName')} label="Förnamn"/></Grid>
                <Grid item xs={4}><TextField value={o.contact.lastName ?? ''} onChange={this.changeContactVal('lastName')} label="Efternamn"/></Grid>
                <Grid item xs={4}>Address</Grid>
                <Grid item xs={8}><TextField value={o.contact.address} onChange={this.changeContactVal('address')} label="Address"/></Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}><TextField value={o.contact.postalcode} onChange={this.changeContactVal('postalcode')} label="Postnummer"/></Grid>
                <Grid item xs={4}><TextField value={o.contact.city}  onChange={this.changeContactVal('city')} label="Ort"/></Grid>
                <Grid item xs={4}>E-mail</Grid>
                <Grid item xs={8}><TextField value={o.contact.email} onChange={this.changeContactVal('email')} label="E-post"/></Grid>
                <Grid item xs={4}>Tel.</Grid>
                <Grid item xs={8}><TextField value={o.contact.phone} onChange={this.changeContactVal('phone')} label="Tel."/></Grid>
               {!this.props.hideSave && <Grid item xs={12}><Button variant="contained" onClick={this.saveOrderContact}>Spara</Button></Grid>}
            </Grid>
            : 
            <Grid container spacing={2}>
            <Grid item xs={4}>Namn</Grid>
            <Grid item xs={8}>{o.contact.firstName + " " + o.contact.lastName}</Grid>
            <Grid item xs={4}>Address</Grid>
            <Grid item xs={8}>{o.contact.address}</Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>{o.contact.postalcode}</Grid>
            <Grid item xs={4}>{o.contact.city}</Grid>
            <Grid item xs={4}>E-mail</Grid>
            <Grid item xs={8}>{o.contact.email}</Grid>
            <Grid item xs={4}>Tel.</Grid>
            <Grid item xs={8}>{o.contact.phone}</Grid>
           
        </Grid>
        }
        </CardContent>
    </Card>
    }
}
const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
}
);

export default withStyles(useStyles)(ManageOrderContact);
