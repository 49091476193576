import { Grid, TextField, FormControlLabel, Checkbox } from "@material-ui/core";
import { ExpandLess, ExpandMore, ThumbUpSharp } from "@material-ui/icons";
import DeleteIcon from '@material-ui/icons/Delete';
import moment from "moment";
import React, { Fragment } from "react";
import { ProductVariantPriceGroup } from "../../model/ProductVariantPriceGroup";

interface IProps {
    editLocale: string;
    forRace: boolean;
    priceGroups: ProductVariantPriceGroup[];
    onChange: (priceGroups: ProductVariantPriceGroup[]) => void;
}

class EditPriceGroups extends React.Component<IProps> {
    private readonly defaultDate: string;

    constructor(props: IProps) {
        super(props);
        
        this.defaultDate = new Date().toISOString().split("T")[0];
    }

    render() {
        const { forRace } = this.props;

        if (forRace) {
            return this.renderForRace();
        }

        return this.renderDefault();
    }

    private renderForRace(): JSX.Element {
        const { priceGroups } = this.props;

        return (<>
            {priceGroups.map((pg, idx) => {
                const style = { borderTop: idx > 0 ? '3px solid #2772b6' : '', marginTop: idx > 0 ? 10 : 0 };

                return <Fragment key={pg.id}>
                    <Grid item xs={2} style={style}>
                        <TextField value={(this.props.editLocale == "EN" ? pg.Name_En : pg.Name) ?? ''} label="Namn" onChange={this.handleCulturePropValueChanged(idx, "Name")} fullWidth />
                    </Grid>
                    <Grid item xs={2} style={style}>
                        <TextField value={(this.props.editLocale == "EN" ? pg.LongName_En : pg.LongName) ?? ''} label={"Långt namn"} onChange={this.handleCulturePropValueChanged(idx, "LongName")} fullWidth />
                    </Grid>
                    <Grid item xs={4} style={style}>
                        <TextField value={(this.props.editLocale == "EN" ? pg.Description_En : pg.Description) ?? ''} label={"Beskrivning"} onChange={this.handleCulturePropValueChanged(idx, "Description")} fullWidth />
                    </Grid>
                    <Grid item xs={2} style={style}>
                        <TextField type="number" value={pg.price ?? ''} label={"Pris"} onChange={this.handlePropNumericValueChanged(idx, "price")} fullWidth />
                    </Grid>
                    <Grid container xs={2} style={style} justify="flex-end">
                        <DeleteIcon style={{ cursor: 'pointer' }} onClick={this.handleDelete(idx)} />
                        {idx > 0 &&
                            <ExpandLess style={{ cursor: 'pointer' }} onClick={this.handleMoveUp(idx)} />
                        }
                        {idx < priceGroups.length - 1 &&
                            <ExpandMore style={{ cursor: 'pointer' }} onClick={this.handleMoveDown(idx)} />
                        }
                    </Grid>
                    <Grid item xs={2}>
                        <TextField type="date"
                            InputProps={{ inputProps: { min: "2020-05-01", max: "9999-12-31" } }}
                            value={pg.validFrom && moment(pg.validFrom).isValid() ? moment(pg.validFrom).format("YYYY-MM-DD") : this.defaultDate}
                            label={"Öppen från"}
                            InputLabelProps={{ shrink: true }}
                            onChange={this.handlePropValueChanged(idx, "validFrom")}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField type="date"
                            InputProps={{ inputProps: { min: "2020-05-01", max: "9999-12-31" } }}
                            value={pg.validTo && moment(pg.validTo).isValid() ? moment(pg.validTo).format("YYYY-MM-DD") : ""}
                            label={"Öppen till"}
                            InputLabelProps={{ shrink: true }}
                            onChange={this.handlePropValueChanged(idx, "validTo")}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField type="number" InputProps={{ inputProps: { min: 0, max: 200 } }} value={pg.maxAge ?? ''} label={"Max ålder"} onChange={this.handlePropNumericValueChanged(idx, "maxAge")} fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControlLabel label="Publikt synlig"
                            control={
                                <Checkbox checked={pg.isPublic}onChange={this.handlePropCheckedChanged(idx, "isPublic")} />
                            }
                        />
                    </Grid>
                    <Grid item xs={2}></Grid>
                </Fragment>
            })}
        </>);
    }

    private renderDefault(): JSX.Element {
        const { priceGroups } = this.props;

        return (<>
            {priceGroups.map((pg, idx) => {
                const style = { borderTop: idx > 0 ? '3px solid #2772b6' : '', marginTop: idx > 0 ? 10 : 0 };

                return <Fragment key={pg.id}>
                    <Grid item xs={2} style={style}>
                        <TextField value={(this.props.editLocale == "EN" ? pg.Name_En : pg.Name) ?? ''} label={"Namn"} onChange={this.handleCulturePropValueChanged(idx, "Name")} fullWidth />
                    </Grid>
                    <Grid item xs={2} style={style}>
                        <TextField value={(this.props.editLocale == "EN" ? pg.LongName_En : pg.LongName) ?? ''} label={"Långt namn"} onChange={this.handleCulturePropValueChanged(idx, "LongName")} fullWidth />
                    </Grid>
                    <Grid item xs={3} style={style}>
                        <TextField value={(this.props.editLocale == "EN" ? pg.Description_En : pg.Description) ?? ''} label={"Beskrivning"} onChange={this.handleCulturePropValueChanged(idx, "Description")} fullWidth />
                    </Grid>
                    <Grid item xs={2} style={style}>
                        <TextField value={pg.type ?? ''} label="Typ" onChange={this.handlePropValueChanged(idx, "type")} fullWidth />
                    </Grid>
                    <Grid item xs={1} style={style}>
                        <TextField type="number" value={pg.price ?? ''} label="Pris" onChange={this.handlePropNumericValueChanged(idx, "price")} fullWidth />
                    </Grid>
                    <Grid container style={style} justify="flex-end">
                        <DeleteIcon style={{ cursor: 'pointer' }} onClick={this.handleDelete(idx)} />
                        {idx > 0 &&
                            <ExpandLess style={{ cursor: 'pointer' }} onClick={this.handleMoveUp(idx)} />
                        }
                        {idx < priceGroups.length - 1 &&
                            <ExpandMore style={{ cursor: 'pointer' }} onClick={this.handleMoveDown(idx)} />
                        }
                    </Grid>
                    <Grid item xs={2}>
                        <TextField type="date"
                            InputProps={{ inputProps: { min: "2020-05-01", max: "9999-12-31" } }}
                            value={pg.validFrom && moment(pg.validFrom).isValid() ? moment(pg.validFrom).format("YYYY-MM-DD") : this.defaultDate}
                            label={"Öppen från"}
                            InputLabelProps={{ shrink: true }}
                            onChange={this.handlePropValueChanged(idx, "validFrom")}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField type="date"
                            InputProps={{ inputProps: { min: "2020-05-01", max: "9999-12-31" } }}
                            value={pg.validTo && moment(pg.validTo).isValid() ? moment(pg.validTo).format("YYYY-MM-DD") : ""}
                            label={"Öppen till"}
                            InputLabelProps={{ shrink: true }}
                            onChange={this.handlePropValueChanged(idx, "validTo")}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControlLabel label={"Enbart online"}
                            control={
                                <Checkbox checked={pg.validOnlineOnly} onChange={this.handlePropCheckedChanged(idx, "validOnlineOnly")} />
                            } 
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel label={"Enbart POS"}
                            control={
                                <Checkbox checked={pg.validPOSOnly} onChange={this.handlePropCheckedChanged(idx, "validPOSOnly")} />
                            } 
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControlLabel label="Publikt synlig"
                            control={
                                <Checkbox checked={pg.isPublic} onChange={this.handlePropCheckedChanged(idx, "isPublic")} />
                            } 
                        />
                    </Grid>
                </Fragment>
            })}
        </>);
    }

    private handlePropValueChanged = (idx: number, propName: string) => (ev: React.ChangeEvent<HTMLInputElement>): void => {
        const { priceGroups, onChange } = this.props;
        (priceGroups[idx] as any)[propName] = ev.target.value;
        onChange(priceGroups);
    };

    private handlePropNumericValueChanged = (idx: number, propName: string) => (ev: React.ChangeEvent<HTMLInputElement>): void => {
        const { priceGroups, onChange } = this.props;
        (priceGroups[idx] as any)[propName] = +ev.target.value;
        onChange(priceGroups);
    };

    private handlePropCheckedChanged = (idx: number, propName: string) => (ev: React.ChangeEvent<HTMLInputElement>): void => {
        const { priceGroups, onChange } = this.props;
        (priceGroups[idx] as any)[propName] = ev.target.checked;
        onChange(priceGroups);
    };

    private handleCulturePropValueChanged = (idx: number, propName: string) => (ev: React.ChangeEvent<HTMLInputElement>): void => {
        const { priceGroups, onChange } = this.props;

        if (this.props.editLocale == "EN") {
            propName += "_En";
        }

        (priceGroups[idx] as any)[propName] = ev.target.value;
        onChange(priceGroups);
    };

    private handleDelete = (idx: number) => (): void => {
        const { priceGroups, onChange } = this.props;

        if (priceGroups.length === 1) {
            alert("Det måste finnas minst en prisgrupp!");
            return;
        }

        priceGroups.splice(idx, 1);
        onChange(priceGroups);
    };

    private handleMoveUp = (idx: number) => (): void => {
        const { priceGroups, onChange } = this.props;

        if (idx > 0) {
            const rem = priceGroups.splice(idx, 1);
            priceGroups.splice(idx - 1, 0, rem[0]);
            onChange(priceGroups);
        }
    };

    private handleMoveDown = (idx: number) => (): void => {
        const { priceGroups, onChange } = this.props;

        if (idx < priceGroups.length - 1) {
            const rem = priceGroups.splice(idx, 1);
            priceGroups.splice(idx + 1, 0, rem[0]);

            onChange(priceGroups);
        }
    };
}

export default EditPriceGroups;
