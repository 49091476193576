/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import React, { ChangeEvent, Fragment } from 'react'
import FirebaseContext from '../../providers/Firebase/context';
import { Card, CardHeader, CardContent, Theme, withStyles, Button, createStyles, Grid, TableCell, Dialog, DialogTitle, DialogActions, LinearProgress, TextField, Typography, Input, InputLabel, Select, MenuItem, Chip } from '@material-ui/core';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { ApiBackend } from '../../providers/apibackend';
import { ListOrder, Order, OrderStatuses } from '../../model/Order';
import { DataGrid, ColDef, ValueGetterParams, ValueFormatterParams } from '@material-ui/data-grid';
import moment from 'moment';
import RefreshIcon from '@material-ui/icons/Refresh';
import FormControl from '@material-ui/core/FormControl/FormControl';
import ManageOrder from './ManageOrder';
import GetAppIcon from '@material-ui/icons/GetApp';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DialogContent from '@material-ui/core/DialogContent';
import AccountingExportDialog from './AccountingExportDialog';
import { OrderContact } from '../../model/OrderContact';
import ManageOrderContact from './ManageOrderContact';

interface State {
    loading : boolean;
    orders: ListOrder[],
    displayOrders : ListOrder[];
    search:string;
    deleteConfirmation : Order;
    showDeleteConfirmation: boolean;
    searchFromDate : Date;
    searchToDate : Date;
    searchStatus : {name: string, value: string}[];
    displayOrder : Order;
    showExport : boolean;
    newOrder: Order;
}

class ManageOrders extends React.Component<RouteComponentProps, State> {
    static contextType = FirebaseContext;
    //orderStatuses = [{name: "Ny", value: "NEW"}, {name: "Ej Betald", value:'PAYMENT_INITIALIZED'}, {name: "Klar", value: "Completed"},{name: "Återbetald", value: "REVERSED"},{name: "Borttagen", value: "DELETED"}];
    constructor(props : RouteComponentProps)
    {
        super(props);
        let fromDate = new Date();
        fromDate.setDate(fromDate.getDate()-150);
        this.state = {newOrder:null,loading: true, orders : null, deleteConfirmation: null, showDeleteConfirmation:false, search:"", displayOrders: null,
    searchFromDate : fromDate, searchToDate: new Date(2030,11,31), searchStatus: [OrderStatuses[2]], displayOrder: null, showExport:false};
    }

    componentDidMount() {
        this.refresh();
    }

    editEvent = (order : Order) => () =>{
        this.props.history.push("/orders/manage/" + order.id);
    }
    deleteEvent = (order : Order) => () =>{
        this.setState({deleteConfirmation: order, showDeleteConfirmation:true});
    }
    getSearchResults(search: string, orders: ListOrder[]) : ListOrder[] {
        if (search == null || search == "")
            return orders;
        return orders.filter(x=>x.contact.firstName.toLowerCase().indexOf(search.toLowerCase()) >= 0 || x.contact.lastName.toLowerCase().indexOf(search.toLowerCase()) >= 0 || x.contact.email.toLowerCase().indexOf(search.toLowerCase()) >=0  || x.Status.toLowerCase().startsWith(search.toLowerCase()));
    }

    onChange = (prop: string) =>  (ev : React.ChangeEvent<HTMLInputElement>) => {
        let n = {} as any;
        n[prop] = ev.target.value;
        this.setState(n, () => {
            this.refresh();
        });
    }

    onSearchChange = (ev : React.ChangeEvent<HTMLInputElement>)=>{

        this.setState({search: ev.target.value, loading:true},() => {
            this.refresh();
        });

    }
    refresh = () => {
        let back = new ApiBackend();
        this.setState({loading:true});
        back.listOrders(this.state.search, this.state.searchFromDate, this.state.searchToDate, this.state.searchStatus.map(x=>x.value)).then((orders) => {
            this.setState({ displayOrders: orders, loading:false});
        });
    }

    downloadResults = () => {

        this.setState({showExport: true});

    }

    changeSearchStatus = (ev : React.ChangeEvent<{value: {name: string ,value: string}[]}>) => {
        this.setState({
            searchStatus : ev.target.value
        }, () => {
            this.refresh();
        });
    }

    openOrder = (order: Order) => () => {
        this.setState({
            displayOrder: order
        })
    }

    newOrder = () => {
        let o = new Order();
        o.contact = new OrderContact();
        o.items=[];
        o.Status="NEW";
        console.log(JSON.stringify(o));
        this.setState({
            newOrder: o
        });
    }
    createNewOrder = () => {
        let back = new ApiBackend();
        back.createOrder(this.state.newOrder).then((order: Order) => {
            this.setState({newOrder: null, displayOrder: order});
        });
    }

   

    render() {
        let { classes } = this.props as any;
        const columns: ColDef[] = [
            { field: 'publicId', headerName: 'ID', width: 120, 
            
            renderCell: (params : ValueFormatterParams) => { 
                return <Typography style={{textDecoration: 'underline', cursor:'pointer'}} onClick={this.openOrder(params.data as any)}>{params.data.publicId}</Typography> }},
            { field: 'Created', type:"datetime", headerName: 'Skapad', width: 170, valueGetter: (params) => {return moment(params.data.OrderDate).format("YYYY-MM-DD HH:mm")} },
            { field: 'contact.name', headerName: 'Namn', width: 170, valueGetter: (params) => {return params.data.contact.firstName + " " + (params.data.contact.lastName ?? '')}},
            { field: 'contact.email', headerName: 'Email', width: 170, valueGetter: (params) => {return params.data.contact.email} },
            { field: 'totalSum', headerName: 'Ordersumma', width: 130, valueGetter: (params) => {return params.data.totalSum + " kr"} },
            {
              field: 'Status',
              headerName: 'Status',
              width: 130,
              valueGetter: (params) => { return Order.translateStatus(params.data.Status);}
            },
            { field: 'LastUpdate', type:"datetime", headerName: 'Ändrad', width: 170, valueGetter: (params) => {return moment(params.data.LastUpdate).format("YYYY-MM-DD HH:mm")} },
          ];


        return <Fragment><Grid container className={classes.root} spacing={2}>
             <Grid item xs={12}>
             <Card>
                <CardHeader className={classes.cardHeader} title={<Fragment><Typography variant="h5" style={{display:'inline'}}>Filtrera </Typography></Fragment>} />
                <CardContent>
                <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={3}>
                        <FormControl>
                            <InputLabel htmlFor="fromDate">Från datum</InputLabel>
                            <Input type="date" value={moment(this.state.searchFromDate).format("YYYY-MM-DD")} onChange={this.onChange("searchFromDate")} 
                            id="fromDate" aria-describedby="order from date" />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl>
                            <InputLabel htmlFor="toDate">Till datum</InputLabel>
                            <Input type="date" id="toDate" aria-describedby="order to date" value={moment(this.state.searchToDate).format("YYYY-MM-DD")} onChange={this.onChange("searchToDate")}  />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl>
                            <InputLabel htmlFor="my-input">Orderstatus</InputLabel>
                            <Select multiple={true} value={this.state.searchStatus} 
                            onChange={this.changeSearchStatus}
                            
                            renderValue={(selected) => (
                                    <div className={classes.chips}>
                                    {(selected as {name : string, value: string}[]).map((value) => (
                                        <Chip key={value.value} label={value.name} className={classes.chip}/>
                                    ))}
                                    </div>
                                )}>
                                    {OrderStatuses.map((s) => {
                                        return <MenuItem value={s as any} style={{fontWeight : this.state.searchStatus.filter(x=>x.value == s.value).length > 0 ? 'bold' : 'normal'}}>{s.name}</MenuItem>;
                                    })}
                                </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField autoComplete="off" style={{ flex: 1, margin: '0 3px' }} id="standard-basic" label="Namn / Epost / Telefon / Produkt / Id / Betalningsref" value={this.state.search} onChange={this.onSearchChange} fullWidth />
                    </Grid>
                </Grid>
                </CardContent>
             </Card>
             </Grid>
            
            <Grid item xs={12}>
                
                <Card>

                    <CardHeader className={classes.cardHeader} title={<Fragment><Typography variant="h5" style={{display:'inline'}}>Orders </Typography>
                    <RefreshIcon style={{display:'inline', verticalAlign:'middle', cursor:'pointer'}} onClick={this.refresh}/>
                    <GetAppIcon style={{display:'inline',cursor:'pointer', verticalAlign:'middle'}} onClick={this.downloadResults}/>
                    <AddCircleIcon style={{display:'inline',cursor:'pointer', verticalAlign:'middle'}} onClick={this.newOrder}/></Fragment>} />
                    <CardContent>
                    {this.state.loading &&
                        <LinearProgress color="secondary"/>
                        }                        
                         <div style={{ height: 400, width: '100%' }}>
                        
                         <DataGrid loading={this.state.loading} rows={ this.state.displayOrders ?? []} columns={columns} pageSize={10} />
                        
                        </div>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
        {this.state && this.state.showDeleteConfirmation && this.renderConfirmationDialog()}
        
        {this.state && this.state.displayOrder && this.renderOrderDialog()}

        {this.state && this.state.showExport && this.renderExportDialog()}
        {this.state && this.state.newOrder && this.renderNewOrderDialog()}

        </Fragment>;
    }

    renderExportDialog() {
        return <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">Exportera</DialogTitle>
            <DialogContent>
                <AccountingExportDialog close={() => this.setState({showExport:false})}/>
            </DialogContent>
        </Dialog>;
    }
    renderNewOrderDialog() {
        return <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">Ny order</DialogTitle>
            <DialogContent>
                <ManageOrderContact hideSave={true} editMode={true} order={this.state.newOrder} dataChanged={()=>{}}/>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" autoFocus onClick={() => {this.setState({newOrder:null})}} color="primary">
                    Avbryt
          </Button>
                <Button variant="contained" onClick={this.createNewOrder} color="primary">
                    Skapa
          </Button>
            </DialogActions>
        </Dialog>;
    }

    renderOrderDialog() {
        return <ManageOrder orderId={this.state.displayOrder.id} close={this.closeOrderDialog}/>
    }
    closeOrderDialog = () => {
        this.setState({displayOrder : null});
    }

    renderConfirmationDialog() {
        return <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">Är du säker på att du vill ta bort ordern?</DialogTitle>
            <DialogActions>
                <Button variant="contained" autoFocus onClick={this.handleDeleteCancel} color="primary">
                    Avbryt
          </Button>
                <Button variant="contained" onClick={this.handleDeleteOk} color="primary">
                    Ja, ta bort
          </Button>
            </DialogActions>
        </Dialog>;
    }
    handleDeleteCancel = () => {
        this.setState({ deleteConfirmation: null, showDeleteConfirmation: false });
    }

    handleDeleteOk = () => {
        this.setState({loading:true});
    }
}



const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
}
);

const tableHeadStyles = ({ palette, spacing }: Theme) => createStyles({
    head: {
        background: palette.primary.main,
        color: palette.primary.contrastText,
    }
});

const StyledTableCell = withStyles(tableHeadStyles)(TableCell);

export default withRouter(withStyles(useStyles)(ManageOrders));