import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import  Typography from '@material-ui/core/Typography';
import { OrderItem } from '../../model/OrderItem';
import moment from 'moment';

class Props {
    item : OrderItem;
}

class OrderRowEntry extends React.Component<Props,any>
{
    render() {
        return <Fragment>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={2} style={{paddingTop:0,marginTop:-5}}><Typography variant="body2" style={{margin:0}}></Typography></Grid>
                            <Grid item xs={5} style={{paddingTop:0,marginTop:-5}}><Typography variant="body2" style={{margin:0}}><b>Deltagare: </b>{this.props.item.entry.firstName + " " +this.props.item.entry.lastName}</Typography></Grid>
                            <Grid item xs={2} style={{paddingTop:0,marginTop:-5}}><Typography variant="body2" style={{margin:0}}></Typography></Grid>
                            <Grid item xs={2} style={{paddingTop:0,marginTop:-5}}><Typography variant="body2" style={{margin:0}}></Typography></Grid>
                    {this.props.item.entry && this.props.item.entry.addOns && this.props.item.entry.addOns.map((addon,idx) => {
                        return <Fragment>
                            
                            <Grid item xs={1}></Grid>
                            <Grid item xs={2} style={{paddingTop:0,marginTop:-5}}><Typography variant="body2" style={{margin:0}}><i>1 st</i></Typography></Grid>
                            <Grid item xs={5} style={{paddingTop:0,marginTop:-5}}><Typography variant="body2" style={{margin:0}}><i>{addon.productName + " (" + addon.productVariantName + ")"}</i></Typography></Grid>
                            <Grid item xs={2} style={{paddingTop:0,marginTop:-5}}><Typography variant="body2" style={{margin:0}}><i>{addon.productVariantPrice} kr</i></Typography></Grid>
                            <Grid item xs={2} style={{paddingTop:0,marginTop:-5}}><Typography variant="body2" style={{margin:0}}><i>{addon.productVariantPrice} kr</i></Typography></Grid>
                        </Fragment>;
                    })}
        </Fragment>;

    }
}

export default OrderRowEntry;
