import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import  Typography from '@material-ui/core/Typography';
import { OrderItem } from '../../model/OrderItem';
import moment from 'moment';

class Props {
    item : OrderItem;
}

class OrderRowMetadata extends React.Component<Props,any>
{

    getSkiPassNamesOnItem(item : OrderItem)
    {
        let names : string[] = [];
        for (let i = 0; i < item.numberOfProducts; i++)
        {
            if (item.metadata.filter(x=>x.key=="skipass_name_"+i).length > 0)
            {
                names.push(item.metadata.filter(x=>x.key=="skipass_name_"+i)[0].value);
            }
        }
        return names;
    }

    render() {
        let orderedVariant = this.props.item.product.variants.filter(x=>x.Id==this.props.item.productVariantId)[0];
        let orderedPriceGroup = orderedVariant.priceGroups.filter(x=>x.id==this.props.item.productVariantPriceGroupId)[0];
        let isDaySkiPass = this.props.item.product.type=="skipass" && orderedPriceGroup.Name == "Dagkort";
        let isWeekSkiPass = this.props.item.product.type=="skipass" && orderedPriceGroup.Name == "Veckokort";
        let isSeasonSkiPass = this.props.item.product.type=="skipass" && orderedPriceGroup.Name == "Säsongskort";

        let item = this.props.item;

        return <Fragment>
                    <Grid item xs={1}></Grid>
                    {(item.product.type=="busticket" || item.occationName)  &&
                                 <Grid item xs={11} style={{paddingTop:0,marginTop:-5}}>
                                    <Typography variant="body2" style={{margin:0}}><i>{item.variantName}<br/>
                                    {item.occationName}</i></Typography>
                                </Grid>
                    }
                    {item.product.type=="skipass" && item.metadata && isDaySkiPass &&
                                 <Grid item xs={11} style={{paddingTop:0,marginTop:-5}}>
                                    <Typography variant="body2" style={{margin:0}}><b>Giltigt</b>: {item.metadata.filter(x=>x.key=="skipassvalidfrom")[0].value}</Typography>
                                </Grid>
                    }
                    {item.product.type=="skipass" && item.metadata && isWeekSkiPass &&
                                <Grid item xs={11} style={{paddingTop:0,marginTop:-5}}>
                                <Typography variant="body2" style={{margin:0}}><b>Giltigt</b>: {item.metadata.filter(x=>x.key=="skipassvalidfrom")[0].value} - {item.metadata.filter(x=>x.key=="skipassvalidto")[0].value}</Typography>
                            </Grid>
                    }
                    {item.product.type=="skipass" && item.metadata && (isWeekSkiPass || isSeasonSkiPass) &&
                            <Grid item xs={11} style={{paddingTop:0,marginTop:-5}}>
                                <Typography variant="body2" style={{margin:0}}>
                                    <b>Registrerat på</b>: {this.getSkiPassNamesOnItem(item).join(", ")}
                                </Typography>
                            </Grid>
                    }
        </Fragment>;

    }
}

export default OrderRowMetadata;