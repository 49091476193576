/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import React from 'react';
import { withRouter, RouteChildrenProps } from 'react-router-dom';
import { Theme, createStyles, Grid, WithStyles, Card, CardHeader, CardContent, TextField, Button, withStyles, CardActionArea, Select, FormControl, MenuItem } from '@material-ui/core';
import moment from 'moment';
import { ApiBackend } from '../../providers/apibackend';
import { Product } from '../../model/Product';
import Radio from '@material-ui/core/Radio';
import { PointOfSale } from '../../model/PointOfSale';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import {Order} from '../../model/Order';
import LinearProgress from '@material-ui/core/LinearProgress';

interface props {
    order: Order;
    close: () => void;
}
interface State {
    paymentType: "PAYMENT" | "REVERSAL"; 
    sum : number;
    loading : boolean;
    pointOfSales : PointOfSale[];
    pointOfSale : string;
}

/** Add Product -- Create a product
 */
class AddPaymentDialog extends React.Component<WithStyles & props, State> {
    constructor(props: any) {
        super(props);
        this.state = {paymentType: 'PAYMENT', sum : 0,pointOfSales: null, pointOfSale : "payex", loading: true}
        this.updatePointOfSales();
    }

    updatePointOfSales() {
        let back = new ApiBackend();
        back.listPointOfSales().then(x=>{
            this.setState({pointOfSales: x, loading: false});
        })
    }

    onChange = (prop : string) => (ev : React.ChangeEvent<HTMLInputElement>) => {
        var e = {} as any;
        e[prop] = ev.target.value;
        this.setState(e);
    }

    addPayment = () => {
            if (this.state.sum <= 0)
            {
                alert("Betald summa måste vara större än 0!");
                return;
            }
            else if (this.state.paymentType == "PAYMENT" && this.state.pointOfSale == "payex")
            {
                alert("Enbart återbetalningar stöds mot payex!");
                return;
            }

            this.setState({loading: true});
            let back = new ApiBackend();
            back.addOrderPayment({
                orderId: this.props.order.id,
                amount: this.state.sum,
                paymentType: this.state.paymentType,
                pointOfSaleId: this.state.pointOfSale,
                description: null
            }).then(res=>{
                this.setState({loading: false},() => {
                    this.props.close();
                });
            })


    }

    render() {
        let { classes } = this.props;
        return <div>
            <Grid container className={classes.root} spacing={2} style={{width:'100%',margin:0,padding:10,paddingTop:0}}>
                <Grid item xs={12}>
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="paymentType1">Typ av betalning</InputLabel>
                            <Select id="paymentType" value={this.state.paymentType} onChange={this.onChange("paymentType")}>
                                <MenuItem value="PAYMENT">Betalning</MenuItem>
                                <MenuItem value="REVERSAL">Återbetalning</MenuItem></Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="sum">Summa</InputLabel>
                            <Input value={this.state.sum} id="sum" onChange={this.onChange("sum")} />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="pos">Försäljningsställe</InputLabel>
                    <Select id="pos" value={this.state.pointOfSale} onChange={this.onChange("pointOfSale")}>
                        <MenuItem value={"payex"}>Payex</MenuItem>
                        {this.state.pointOfSales && this.state.pointOfSales.map(x=>{
                            return <MenuItem value={x.id}>{x.name}</MenuItem> 
                        })}
                        </Select>
                    </FormControl>
                </Grid>
                {this.state.loading &&
                <Grid item xs={12}>
                    <LinearProgress/>
                </Grid>
                }
                <Button variant="contained" onClick={this.addPayment}>Lägg till</Button>
                <Button variant="contained" onClick={() => this.props.close()}>Avbryt</Button>
            </Grid></div>;
    }
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    },
    formControl: {
        margin: spacing(1),
        minWidth: 200,
      },
      selectEmpty: {
        marginTop: spacing(2),
      },
}
);


export default withStyles(useStyles)(AddPaymentDialog);