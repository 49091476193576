import React from "react";
import { Button, Box, Card, CardActions, CardContent, CardHeader, Popover, WithStyles } from "@material-ui/core";
import { FieldArray, FieldArrayRenderProps, FormikProps } from "formik";
import { B2bCompany, B2bCompanyProduct } from "../../../model/B2bCompany";
import SelectProductPopover from "./Popovers/SelectProductPopover";
import EditB2bCompanyProduct from "./EditB2bCompanyProduct";
import { ApiBackend } from "../../../providers/apibackend";
import { Product } from "../../../model/Product";

interface IState {
	popupOpen: boolean;
	anchorEl: HTMLButtonElement;
	sourceProducts: Product[];
}

class EditB2bCompanyProducts extends React.Component<WithStyles & FormikProps<B2bCompany>, IState> {
	private readonly api: ApiBackend;

	constructor(props: WithStyles & FormikProps<B2bCompany>) {
		super(props);
		this.state = {
			popupOpen: false,
			anchorEl: undefined,
			sourceProducts: []
		};
		this.api = new ApiBackend();
	}

	async componentDidMount(): Promise<void> {
		this.updateAllSourceProducts();
	}

	render(): JSX.Element {
		const { popupOpen, anchorEl } = this.state;
		const { classes } = this.props;
		const products = this.props.values.products;

		return (this.state.sourceProducts &&
			<Card>
				<FieldArray
					name="products"
					render={arrayHelpers => (
						<>
							<CardHeader
								className={classes.cardHeader}
								title="Hantera produkter"
							/>
							<CardContent>
								{products.map((product, idx) => {
									const sourceProduct = this.state.sourceProducts.find((sourceProduct) => sourceProduct.id === product.productId);
									return sourceProduct && <EditB2bCompanyProduct {...this.props}
										key={idx}
										idx={idx}
										product={product}
										sourceProduct={sourceProduct}
										onDelete={(idx: number) => this.handleOnDeleteProduct(idx, arrayHelpers)}
										onSave={(product: B2bCompanyProduct) => this.handleOnSaveProduct(idx, product, arrayHelpers)}
									/>
								})}
							</CardContent>
							<CardActions>
								<Box mb={1}>
									<Button
										variant="contained"
										onClick={this.handleAddProduct}
									>Lägg till produkt</Button>
									<Popover
										open={popupOpen}
										anchorEl={anchorEl}
										onClose={this.handleAddProductClose}
										anchorOrigin={{
											vertical: 'top',
											horizontal: 'center',
										}}
										transformOrigin={{
											vertical: 'bottom',
											horizontal: 'center',
										}}
										PaperProps={{
											style: {
												width: "500px"
											}
										}}>
										<SelectProductPopover
											onChange={(product) => this.handleOnPopoverChange(product, arrayHelpers)}
										/>
									</Popover>
								</Box>
							</CardActions>
						</>
					)} />
			</Card>
		)
	}

	private handleOnDeleteProduct = (idx: number, arrayHelpers: FieldArrayRenderProps) => {
		arrayHelpers.remove(idx);
	};

	private handleOnSaveProduct = (idx: number, product: B2bCompanyProduct, arrayHelpers: FieldArrayRenderProps) => {
		arrayHelpers.replace(idx, product);
	}

	private handleAddProduct = (e: any) => {
		this.setState({ popupOpen: true, anchorEl: e.currentTarget });
	};

	private handleAddProductClose = () => {
		this.setState({ popupOpen: false, anchorEl: undefined });
	};

	private handleOnPopoverChange = async (product: B2bCompanyProduct, arrayHelpers: FieldArrayRenderProps) => {
		const sourceProductExists = this.state.sourceProducts.some((p) => p.id == product.productId);
		if (!sourceProductExists) {
			await this.addSourceProduct(product.productId);
		}

		this.handleAddProductClose();
		arrayHelpers.push(product);
	};

	private updateAllSourceProducts = async (): Promise<void> => {
		const sourceProductIds = this.props.values.products.map((product) => product.productId);
		const uniqueProductIds = sourceProductIds.filter((v, i, a) => a.indexOf(v) === i);
		const uniqueSourceProducts: Product[] = await Promise.all(uniqueProductIds.map(async (productId): Promise<Product> => {
			return await this.api.getProduct(productId);
		}));
		this.setState({
			sourceProducts: uniqueSourceProducts
		});
	}

	private addSourceProduct = async (productId: string): Promise<void> => {
		const newSourceProduct = await this.api.getProduct(productId);
		this.setState(prevState => {
			return {
				...prevState,
				sourceProducts: [
					...prevState.sourceProducts,
					newSourceProduct
				]
			}
		});
	}
}

export default EditB2bCompanyProducts;