/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import * as React from "react";
import * as ReactDOM from "react-dom";
import MainPage from "./components/MainPage";
import { MuiThemeProvider, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

let theme = createMuiTheme({
    palette: {
        primary: {
            light: '#E53037',
            main: '#FFFFFF',
            dark: '#2772b6',
            contrastText: '#000',
        },
        secondary: {
            light: '#FF0000',
            main: '#2772b6',
            dark: '#DDD700',
            contrastText: '#FFF',
        }
        ,
        type: "light"
    }
});
theme = responsiveFontSizes(theme);

ReactDOM.render(
    <MuiThemeProvider theme={theme}>
        <MainPage />
    </MuiThemeProvider>
    , document.getElementById("main")
);