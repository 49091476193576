import {  Card, CardContent, CardHeader, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress, TextField, Theme, Typography, withStyles, WithStyles } from '@material-ui/core';
import React, { Fragment } from 'react';
import { Order } from '../../model/Order';
import { ApiBackend } from '../../providers/apibackend';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';

import { Message } from '../../model/Message';
import Tooltip from '@material-ui/core/Tooltip';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import TextsmsIcon from '@material-ui/icons/Textsms';
import EmailIcon from '@material-ui/icons/Email';
import moment from 'moment';



interface Props {
    orderId: string;
    order: Order;
}
interface State {
    isLoadingMessageHistory: boolean;
    orderMessages: Message[];
}

class OrderMessages extends React.Component<Props & WithStyles,State>
{
    constructor(props: Props & WithStyles)
    {
        super(props);
        this.state={isLoadingMessageHistory: true, orderMessages: null}
    }

    componentDidMount() {
        this.updateData();
    }

    updateData = () => {
        let backend = new ApiBackend();
        backend.getOrderCommunicationHistory(this.props.orderId).then((messages) => {
            this.setState({orderMessages : messages, isLoadingMessageHistory:false});
        });
    }
    
    render () {
        let {classes}= this.props;
        return <Card>
        <CardHeader className={classes.cardHeader} title={"Meddelanden till " + (this.props.order ? this.props.order.contact.firstName : '[Laddar..]')} />
        <CardContent style={{overflowY: 'scroll', maxHeight:'135px', fontSize:'12px'}}>
            <Grid container spacing={2}>
                <Grid item xs={1}></Grid>
                <Grid item xs={4}><b>Mall</b></Grid>
                <Grid item xs={3}><b>Skickat</b></Grid>
                <Grid item xs={3}><b>Läst</b></Grid>
                <Grid item xs={1}></Grid>
                {this.state.isLoadingMessageHistory &&

                <CircularProgress color="secondary"/>
                }
                {this.state.orderMessages && this.state.orderMessages.map((x)=> {
                    return <Fragment>
                        <Grid item xs={1}>{x.type == "SMS" ? <Tooltip title="SMS" aria-label="SMS"><TextsmsIcon/></Tooltip> : null}{x.type == "EMAIL" ? <Tooltip title="E-Post" aria-label="E-Post"><EmailIcon/></Tooltip> : null}</Grid>
                <Grid item xs={4} style={{overflow:'hidden'}}>{x.msgTypeId}</Grid>
                <Grid item xs={3}>{x.sent ? moment(x.sent).format("YYYY-MM-DD HH:mm:ss") : null}</Grid>
                <Grid item xs={3}>{x.read ? moment(x.read).format("YYYY-MM-DD HH:mm:ss") : null}</Grid>
                <Grid item xs={1}>{x.isSoftBounce && 
                    <Tooltip title="Meddelandet har inte kunnat levereras än (softbounce)" aria-label="Meddelandet har inte kunnat levereras än (softbounce)"><WarningIcon style={{color:'orange'}}/></Tooltip>
                }
                {x.isHardBounce && 
                    <Tooltip title="Meddelandet kan inte levereras (hardbounce)" aria-label="Meddelandet kan inte levereras (hardbounce)"><ErrorIcon style={{color:'darkred'}}/></Tooltip>
                }
                </Grid>
                    </Fragment>;
                })}
            </Grid>
        </CardContent>
    </Card>
    }
}
const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
}
);

export default withStyles(useStyles)(OrderMessages);
