import { Box, Grid, IconButton } from "@material-ui/core";
import { FieldArray, FormikProps } from "formik";
import React from "react";
import { B2bCompany } from "../../../model/B2bCompany";
import FormTextField from "../../Common/FormTextField";
import DeleteIcon from '@material-ui/icons/Delete';

class EditB2bCompanyAdmins extends React.Component<FormikProps<B2bCompany>> {

	render(): JSX.Element {
		const rows = this.props.values.admins || [];

		return (
			<FieldArray
				name="admins"
				render={arrayHelpers => (
					<>
						{rows.map((row, idx) => {
							return (
								<Grid container key={idx} spacing={2}>
									<Grid item xs={3}>
										<FormTextField {...this.props}
											propName={`admins[${idx}].firstName`}
											label="Förnamn"
											valueGetter={() => row.firstName} />
									</Grid>
									<Grid item xs={4}>
										<FormTextField {...this.props}
											propName={`admins[${idx}].lastName`}
											label="Efternamn"
											valueGetter={() => row.lastName} />
									</Grid>
									<Grid item xs={4}>
										<FormTextField {...this.props}
											propName={`admins[${idx}].email`}
											label="E-post"
											valueGetter={() => row.email} />
									</Grid>
									<Grid item xs={1}>
										<Box display="flex" justifyContent="flex-end">
											<IconButton
												onClick={() => { arrayHelpers.remove(idx); }}>
												<DeleteIcon />
											</IconButton>
										</Box>
									</Grid>
								</Grid>
							)
						})}
					</>
				)} />
		);
	}
}

export default EditB2bCompanyAdmins;