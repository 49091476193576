import React from "react";
import { Button, Card, CardActions, CardContent, CardHeader, Grid, TextField } from "@material-ui/core";

interface IProps {
    onChange: (companyName: string) => void;
}

interface IState {
    companyName: string;
}

class CreateCompany extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = { companyName: undefined };
    }

    render(): JSX.Element {
        const { companyName } = this.state;
        const saveEnabled = !!companyName;

        return (
            <Card>
                <CardHeader title="Lägg till företag" />
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                autoComplete="off"
                                label="Företagsnamn"
                                style={{ flex: 1, margin: "0 3px" }}
                                InputLabelProps={{ shrink: true }}
                                value={companyName}
                                fullWidth
                                onChange={this.onChange}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    <Button variant="contained" disabled={!saveEnabled} onClick={this.handleSave}>Spara</Button>
                </CardActions>
            </Card>
        )
    }

    private onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ companyName: e.target.value });
    }

    private handleSave = () => {
        const { companyName } = this.state;
        const { onChange } = this.props;

        onChange(companyName);
    };
}

export default CreateCompany;
