import React, { Fragment } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { debounce } from "lodash";
import { Box, Button, Card, CardContent, CardHeader, Checkbox, createStyles, FormControlLabel, Grid, IconButton, LinearProgress, Popover, Theme, Typography, withStyles, WithStyles } from "@material-ui/core";
import { CellParams, ColDef, DataGrid, ValueFormatterParams } from "@material-ui/data-grid";
import { OpenInBrowser, ArrowForward } from "@material-ui/icons";
import RefreshIcon from '@material-ui/icons/Refresh';
import FirebaseContext from "../../../providers/Firebase/context";
import { ApiBackend } from "../../../providers/apibackend";
import { B2bCompany } from "../../../model/B2bCompany";
import CreateCompany from "./Popovers/CreateCompany";

interface IState {
    loading: boolean;
    companies: B2bCompany[];
    popupOpen: boolean;
    anchorEl: HTMLButtonElement;
    showArchived: boolean;
}

type EditMode = "in-app" | "new-tab";

class ManageB2bCompanies extends React.Component<RouteComponentProps & WithStyles, IState> {
    static contextType = FirebaseContext;

    private readonly api: ApiBackend;

    constructor(props: RouteComponentProps & WithStyles) {
        super(props);
        this.state = {
            loading: true,
            companies: null,
            popupOpen: false,
            anchorEl: undefined,
            showArchived: false
        };
        this.api = new ApiBackend();
    }

    componentDidMount() {
        this.refresh();
    }

    render(): JSX.Element {
        const { classes } = this.props;
        const { showArchived, popupOpen, anchorEl } = this.state;

        const columns: ColDef[] = [
            {
                field: '',
                headerName: '',
                width: 150,
                renderCell: (params: ValueFormatterParams) => {
                    return <Fragment>
                        <IconButton color="default" onClick={() => { this.editCompany(params.data as B2bCompany, "new-tab") }}>
                            <OpenInBrowser fontSize="default" />
                        </IconButton>
                        <IconButton color="default" onClick={() => { this.editCompany(params.data as B2bCompany, "in-app") }}>
                            <ArrowForward fontSize="default" />
                        </IconButton>
                    </Fragment>
                }
            },
            {
                field: 'name',
                headerName: 'Namn',
                width: 250,
                cellClassName: 'cursor-pointer'
            },
            {
                field: 'description',
                headerName: 'Beskrivning',
                width: 300
            }
        ];

        return (<>
            <Grid container className={classes.root} spacing={2}>
                <Grid item xs={5}>
                    <FormControlLabel
                        control={
                            <Checkbox value={showArchived}
                                onChange={this.handleShowArchivedChange}
                            />}
                        label="Inkludera arkiverade företag"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader
                            className={classes.cardHeader}
                            title={
                                <Fragment>
                                    <Typography
                                        variant="h5"
                                        style={{ display: 'inline' }}>
                                        Företag
                                    </Typography>
                                    <RefreshIcon
                                        style={{ display: 'inline', verticalAlign: 'middle' }}
                                        onClick={this.refresh}
                                    />
                                </Fragment>
                            }
                        />
                        <CardContent>
                            <Box mb={1}>
                                <Button variant="contained" onClick={this.showCreateCompany}>Lägg till företag</Button>
                                <Popover
                                    open={popupOpen}
                                    anchorEl={anchorEl}
                                    onClose={this.handlePopoverClose}
                                    anchorOrigin={{
                                        vertical: 'center',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    PaperProps={{
                                        style: {
                                            width: "500px"
                                        }
                                    }}
                                >
                                    <CreateCompany onChange={this.onPopoverChange} />
                                </Popover>
                            </Box>

                            {this.state.loading &&
                                <LinearProgress color="secondary" />
                            }

                            <div style={{ height: 400, width: '100%' }}>
                                <DataGrid
                                    loading={this.state.loading}
                                    rows={this.state.companies ?? []}
                                    columns={columns}
                                    pageSize={10}
                                    onCellClick={this.onCellClick}
                                />
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>);
    }

    private handleShowArchivedChange = (ev: any) => {
        this.setState({ showArchived: ev.target.checked }, () => {
            this.refresh();
        });
    };

    private refresh = () => {
        this.setState({ loading: true });
        this.performSearch();
    };

    private showCreateCompany = (e: any) => {
        this.setState({ popupOpen: true, anchorEl: e.currentTarget });
    };

    private handlePopoverClose = () => {
        this.setState({ popupOpen: false, anchorEl: undefined });
    };

    private performSearch = debounce(async () => {
        const { showArchived } = this.state;
        const companies = await this.api.listB2bCompanies(showArchived);
        this.setState({
            companies: companies,
            loading: false
        });
    }, 500);

    private onPopoverChange = (companyName: string) => {
        this.setState({ popupOpen: false, anchorEl: undefined }, async () => {
            const response = await this.api.createB2bCompany(companyName);
            // TODO: Route to new company or simply refresh grid?
            this.refresh();
        });
    };

    private editCompany = (company: B2bCompany, mode: EditMode): void => {
        switch (mode) {
            case "in-app":
                this.props.history.push(`manage/${company.id}`);
                break;
            case "new-tab":
                window.open(`manage/${company.id}`);
                break;
        }
    };

    private onCellClick = (param: CellParams): void => {
        if (param.field === "name") {
            this.editCompany(param.data as B2bCompany, "in-app")
        }
    }
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withRouter(withStyles(useStyles)(ManageB2bCompanies));
