/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import React, { Fragment } from 'react'
import FirebaseContext from '../../../providers/Firebase/context';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ApiBackend } from '../../../providers/apibackend';
import { Product } from '../../../model/Product';
import { CircularProgress, LinearProgress, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { RaceEntryOverview } from '../../../model/RaceEntryOverview';


interface State {
    loading: boolean;
    races: Product[];
    race: RaceEntryOverview;
    raceId: string;
    downloadingCsv: boolean;
}

interface RouteParams {
    id: string;
}

class EntryOverview extends React.Component<RouteComponentProps<RouteParams> & WithStyles, State> {
    static contextType = FirebaseContext;
    
    private readonly api: ApiBackend;

    constructor(props: RouteComponentProps<RouteParams> & WithStyles) {
        super(props);
        this.state = { loading: true, races: null, race: null, raceId: '', downloadingCsv: false };
        this.api = new ApiBackend();
    }

    componentDidMount() {
        this.refresh();
    }

    refresh = () => {
        this.setState({ loading: true });
        this.api.listProducts().then((products) => {
            this.setState({ races: products.filter(x => x.type == "race" && !x.IsArchived), raceId: products[0].id, loading: false });
            this.loadEvent(products[0].id);
        });
    }

    onSelChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        this.loadEvent(ev.target.value);
    }

    loadEvent = (id: string) => {
        this.setState({ loading: true });
        this.api.getEntryOverview(id).then((product) => {
            this.setState({ race: product, raceId: id, loading: false });
        });
    }

    render() {
        return <Fragment><FormControl style={{ marginBottom: 5 }}><InputLabel id="select-label">Välj tävling</InputLabel><Select labelId="select-label" value={this.state.raceId} variant="filled" onChange={this.onSelChange}>
            {this.state.races && this.state.races.map((x) => {
                return <MenuItem value={x.id}>{x.name}</MenuItem>;
            })}
        </Select></FormControl>
            {this.state && this.state.loading &&
                <LinearProgress variant="indeterminate" color="secondary" />
            }
            {this.state && this.state.race &&
                this.renderEntryOverview()
            }
        </Fragment>;
    }

    renderEntryOverview() {
        if (!this.state || !this.state.race) {
            return null;
        }

        const { classes } = this.props;

        return <Card>
            <CardHeader className={classes.cardHeader} title={<Fragment><Typography variant="h5" style={{ display: 'inline' }}>Anmälningsöversikt {this.state.race.name}</Typography></Fragment>} />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={5}>
                        Maximalt antal deltagare:
                    </Grid>
                    <Grid item xs={7}>
                        <b>{this.state.race.maxParticipants}</b>
                    </Grid>

                    <Grid item xs={5}>
                        Antal anmälda deltagare:
                    </Grid>
                    <Grid item xs={7}>
                        <b>{this.state.race.numEntered}</b>
                    </Grid>
                    <Grid item xs={5}>
                        Antal reserverade platser:
                    </Grid>
                    <Grid item xs={7}>
                        <b>{this.state.race.numReserved}</b>
                    </Grid>
                    <Grid item xs={5}>
                        Platser kvar
                    </Grid>
                    <Grid item xs={7}>
                        <b>{this.state.race.numRemaining}</b>
                    </Grid>

                    <Grid item xs={2} style={{ fontWeight: 'bold', borderTop: '1px solid black' }}>
                        Startled
                    </Grid>
                    <Grid item xs={2} style={{ fontWeight: 'bold', borderTop: '1px solid black' }}>
                        Max platser
                    </Grid>
                    <Grid item xs={2} style={{ fontWeight: 'bold', borderTop: '1px solid black' }}>
                        Säljbara platser
                    </Grid>
                    <Grid item xs={2} style={{ fontWeight: 'bold', borderTop: '1px solid black' }}>
                        Antal deltagare
                    </Grid>
                    <Grid item xs={2} style={{ fontWeight: 'bold', borderTop: '1px solid black' }}>
                        Antal reserverade
                    </Grid>
                    <Grid item xs={2} style={{ fontWeight: 'bold', borderTop: '1px solid black' }}>
                        Antal lediga platser
                    </Grid>

                    {this.state.race.startGroups && this.state.race.startGroups.map((group) => {
                        return <Fragment>
                            <Grid item xs={2}>
                                {group.name}
                            </Grid>
                            <Grid item xs={2}>
                                <b>{group.maxParticipants}</b>
                            </Grid>
                            <Grid item xs={2}>
                                <b>{group.numSellable}</b>
                            </Grid>
                            <Grid item xs={2}>
                                <b>{group.numParticipants}</b>
                            </Grid>
                            <Grid item xs={2}>
                                <b>{group.numReserved}</b>
                            </Grid>
                            <Grid item xs={2}>
                                <b>{group.numFreePlaces}</b>
                            </Grid>
                        </Fragment>;
                    })}

                    {this.state.raceId && <Grid item xs={12}>
                        <Button variant="contained" color="primary" onClick={() => { this.downloadAsCsv(); }}>Ladda ner anmälningar som CSV{this.state.downloadingCsv && <CircularProgress />}</Button>
                    </Grid>}
                </Grid>
            </CardContent></Card>
    }

    async downloadAsCsv() {
        this.setState({ downloadingCsv: true });
        this.api.downloadEntriesAsCSV(this.state.raceId).then(() => {
            this.setState({ downloadingCsv: false });
        });
    }

    renderChoiceList() {
        return <Fragment>
            <Typography variant="h4">Välj evenemang</Typography>
            {this.state && this.state.races && this.state.races.map(x => {
                return <Button onClick={() => { this.props.history.push("/entries/overview/" + x.id) }} variant="contained" style={{ margin: 3 }}>{x.name}</Button>;
            })}
        </Fragment>;
    }
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    gridheader: {
        background: '#eee',
        padding: 10
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withStyles(useStyles)(withRouter(EntryOverview));
