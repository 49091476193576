export class Person {
    id: string;
    externalId: string;
    firstName: string;
    lastName:string;
    email:string;
    birthDay: string;
    gender: 'male' | 'female';
    nationality : string;
    phoneNumber: string;
    mobileNumber: string;
    address: string;
    address_line2: string;
    postCode: string;
    city: string;
    status: string;
    country: string;
    county: string;
    ExternalId : string;
    vasaId : string;
    fisCode : string;
    excludeWeb : boolean;
    deceased: boolean
    veteranId: string;
}

export interface IListPersonsResponse {
    persons: Person[];
    count: number;
}