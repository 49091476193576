/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import React, { ChangeEvent, Fragment } from 'react'
import FirebaseContext from '../../providers/Firebase/context';
import { Card, CardHeader, CardContent, Theme, WithStyles, withStyles, Button, createStyles, Grid, CardActionArea, TableContainer, Paper, Table, TableHead, TableRow, TableBody, Hidden, TableCell, Dialog, DialogTitle, DialogActions, LinearProgress } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ApiBackend } from '../../providers/apibackend';
import { Event } from '../../model/Event';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { CodeHolder } from '../../model/CodeHolder';
import moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

interface State {
    loading : boolean;
    deleteConfirmation : CodeHolder;
    showDeleteConfirmation: boolean;
    codes : CodeHolder[];
    includeArchived : boolean;


}

class ManageCodes extends React.Component<RouteComponentProps, State> {
    static contextType = FirebaseContext;
    constructor(props : RouteComponentProps)
    {
        super(props);
        this.state = {loading: true, codes: null, deleteConfirmation: null, showDeleteConfirmation:false, includeArchived: false};
    }

    componentDidMount() {
        this.setState({loading:true});
        let back = new ApiBackend();
        back.listCodes(this.state.includeArchived).then((codes) => {
            this.setState({codes : codes, loading:false});
        });
    }

    addCode = () => {
        this.props.history.push("/codes/create");
    }

    editCode = (code : CodeHolder) => () =>{
        this.props.history.push("/codes/manage/" + code.id);
    }
    deleteCode = (code : CodeHolder) => () =>{
        this.setState({deleteConfirmation: code, showDeleteConfirmation:true});
    }

    toggleArchived = () => {
        this.setState({includeArchived: !this.state.includeArchived, loading:true}, () => {
            let back = new ApiBackend();
            back.listCodes(this.state.includeArchived).then((codes) => {
                this.setState({codes : codes, loading:false});
            });
        });
    }

    render() {
        let { classes } = this.props as any;
        return <Fragment>
           
            <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
           
                <Card>
                    <CardHeader className={classes.cardHeader} title="Koder" />
                    <CardContent>
                    <FormControlLabel
            control={<Checkbox checked={this.state.includeArchived} onChange={this.toggleArchived} name="includearchived" />}
            label="Inkludera arkiverade"
          />
                    {this.state.loading &&
                        <LinearProgress color="secondary"/>
                        }
                        <TableContainer component={Paper}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Namn</StyledTableCell>
                                        <StyledTableCell>Giltig från</StyledTableCell>
                                        <StyledTableCell>Giltig till</StyledTableCell>
                                        <StyledTableCell>Nyttjande</StyledTableCell>
                                        <StyledTableCell>Skapad</StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state && this.state.codes && this.state.codes.map((code) => {
                                        return <TableRow>
                                            <StyledTableCell><a href="#" onClick={this.editCode(code)}>{code.name}</a></StyledTableCell>
                                            <StyledTableCell>{moment(code.ValidFrom).format("YYYY-MM-DD")}</StyledTableCell>
                                            <StyledTableCell>{moment(code.ValidTo).format("YYYY-MM-DD")}</StyledTableCell>
                                            <StyledTableCell>{code.NumUsed ?? 0}/{code.NumAvailable ?? 0}</StyledTableCell>
                                            <StyledTableCell>{moment(code.Created).format("YYYY-MM-DD")}</StyledTableCell>
                                            <StyledTableCell align="right">
                                                <EditIcon onClick={this.editCode(code)} className={classes.icon} style={{cursor:'pointer'}}  />
                                            <DeleteIcon onClick={this.deleteCode(code)} className={classes.icon} style={{cursor:'pointer'}} /></StyledTableCell>
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table></TableContainer>
                    </CardContent>
                    <CardActionArea>
                        <Button onClick={this.addCode}>Lägg till kod</Button>
                    </CardActionArea>
                </Card>
            </Grid>
        </Grid>
        {this.state && this.state.showDeleteConfirmation && this.renderConfirmationDialog()}
        </Fragment>;
    }

    renderConfirmationDialog() {
        return <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">Är du säker på att du vill ta bort koden?</DialogTitle>
            <DialogActions>
                <Button variant="contained" autoFocus onClick={this.handleDeleteCancel} color="primary">
                    Avbryt
          </Button>
                <Button variant="contained" onClick={this.handleDeleteOk} color="primary">
                    Ja, ta bort
          </Button>
            </DialogActions>
        </Dialog>;
    }
    handleDeleteCancel = () => {
        this.setState({ deleteConfirmation: null, showDeleteConfirmation: false });
    }

    handleDeleteOk = () => {
        this.setState({loading:true});
        var back = new ApiBackend();
        back.deleteCodeHolder(this.state.deleteConfirmation).then((res) => {
            if (res){
                back.listCodes(this.state.includeArchived).then((codes) => {
                    this.setState({deleteConfirmation: null, showDeleteConfirmation: false, codes: codes, loading:false });
                });
            }
            else 
            {
                alert("Kunde inte ta bort arrangemang!");
            }
        })
        
    }
}



const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
}
);

const tableHeadStyles = ({ palette, spacing }: Theme) => createStyles({
    head: {
        background: palette.primary.main,
        color: palette.primary.contrastText,
    }
});

const StyledTableCell = withStyles(tableHeadStyles)(TableCell);

export default withRouter(withStyles(useStyles)(ManageCodes));