/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

 import React, { Fragment } from 'react';
 import { Theme, createStyles, Grid, WithStyles, Card, CardHeader, CardContent, TextField, Button, withStyles, CardActionArea, LinearProgress, Container, Table, TableContainer, TableRow, Paper, TableCell, TableBody, Dialog, DialogActions, DialogTitle, DialogContent, Select, MenuItem, FormControl, InputLabel, Checkbox, Typography, FormControlLabel } from '@material-ui/core';
 import { ApiBackend } from '../../providers/apibackend';
 import { CodeHolder } from '../../model/CodeHolder';
 import TableHead from '@material-ui/core/TableHead/TableHead';
 import EditIcon from '@material-ui/icons/Edit';
 import DeleteIcon from '@material-ui/icons/Delete';
import { CodeProductImplementation } from '../../model/CodeProductImplementation';
import { Product } from '../../model/Product';
import uuid from 'react-uuid';

 
 
 interface Props {
     code : CodeHolder;
     products : Product[];
     save : (implementation : CodeProductImplementation) => void;
     delete : (implementation : CodeProductImplementation) => void;
 }
 interface State {
     loading : boolean;
     haveChanges:boolean;
     deleteConfirmation : CodeProductImplementation;
     addEdit: CodeProductImplementation;
     curProduct : Product;
 }
 
 
 class CodeProducts extends React.Component<WithStyles & Props, State> {
 
     state = { loading:true, haveChanges: false,
         deleteConfirmation:null as CodeProductImplementation, addEdit:null as CodeProductImplementation
        ,curProduct: null as Product};
 
     constructor(props: WithStyles & Props) {
         super(props);
     }
 
     onChangeStateProp = (prop : string) => (ev : React.ChangeEvent<HTMLInputElement>) => 
     {
         let o = {} as any;
         o[prop] = ev.target.value;
         this.setState(o);
     }
     changeEditField = (prop: string) =>  (ev : React.ChangeEvent<HTMLInputElement>) => {
         let o = this.state.addEdit as any;
         o[prop] = ev.target.value;
         if (prop =="productId")
         {
             let p = this.props.products.filter(x=>x.id==ev.target.value)[0];
             o["productVariantId"] = p.variants[0].Id;
             o["startGroup"] = null;
             if (this.state.addEdit.type=="pricegroup")
             {
                o["productVariantPriceGroupId"] = p.variants[0].priceGroups[0].id;
             }
             this.setState({addEdit: o, curProduct: null, loading:p.type=="race"});
             if (p.type=="race")
             {
                let back = new ApiBackend();
                back.getProduct(ev.target.value).then((x)=>{
                    this.setState({loading:false, curProduct: x});
                })
             }
         } 
         else if (prop =="productVariantId")
         {
             let p = this.props.products.filter(x=>x.id==this.state.addEdit.productId)[0];
             let variant = p.variants.filter(x=>x.Id==ev.target.value)[0];
              o["productVariantPriceGroupId"] = variant.priceGroups[0].id;
              this.setState({addEdit: o});
         }
         else 
         {
            this.setState({addEdit: o});
         }
        
     }
     changeChkEditField = (prop: string) =>  (ev : React.ChangeEvent<HTMLInputElement>) => {
        let o = this.state.addEdit as any;
        o[prop] = ev.target.checked;
        this.setState({addEdit: o});
    }
    
     getProductName(id: string){
        let p = this.props.products.filter(x=>x.id == id);
        if (p && p.length > 0)
        {
            return p[0].name;
        }
        return "";
     }
     getProductVariantName(productId: string, id: string){
        let p = this.props.products.filter(x=>x.id == productId);
        if (p && p.length > 0)
        {
            let pv = p[0].variants.filter(x=>x.Id==id);
            if (pv && pv.length > 0)
            {
                return pv[0].Name;
            }
        }
        return "";
     }
     getProductVariantPriceGroupName(productId: string, variantId: string, id: string){
        let p = this.props.products.filter(x=>x.id == productId);
        if (p && p.length > 0)
        {
            let pv = p[0].variants.filter(x=>x.Id==variantId);
            if (pv && pv.length > 0)
            {
                let pg = pv[0].priceGroups.filter(x=>x.id == id);
                if (pg && pg.length > 0)
                {
                    return pg[0].Name;
                }
            }
        }
        return "";
     }

     getImplementationValue(impl : CodeProductImplementation)
     {
         if (impl.type == "pricegroup")
         {
             return this.getProductVariantPriceGroupName(impl.productId,impl.productVariantId,impl.productVariantPriceGroupId);
         }
         else if (impl.type == "percent")
         {
            return impl.value + "%";
         }
         else 
         {
             return impl.value + "kr";
         }

     }
     edit = (impl : CodeProductImplementation) => () => {
         let ps = this.props.products.filter(x=>x.id==impl.productId);
         if (ps.length > 0 && ps[0].type=="race")
         {
            this.setState({addEdit: impl,loading:true,curProduct:null});
            let api = new ApiBackend();
            api.getProduct(impl.productId).then((x)=>{
                this.setState({curProduct: x, loading:false});
            })
         }
         else 
         {
            this.setState({addEdit: impl,curProduct:null});
         }
     }

     remove = (impl : CodeProductImplementation) => () => {
         this.setState({deleteConfirmation: impl});
     }
 
     render() {
         let {classes} = this.props;
         return <Fragment><Grid container className={classes.root} spacing={2}>
             <Grid item xs={12}>
                 <Card>
                     <CardHeader className={classes.cardHeader} title="Giltig för produkter" />
                     <CardContent>
                         <TableContainer component={Paper}>
                             <Table size="small">
                                 <TableHead>
                                     <TableRow>
                                         <StyledTableCell>Produkt</StyledTableCell>
                                         <StyledTableCell>Produktvariant</StyledTableCell>
                                         <StyledTableCell>Typ</StyledTableCell>
                                         <StyledTableCell>Värde</StyledTableCell>
                                         <StyledTableCell>Funkar i fulla lopp</StyledTableCell>
                                         <StyledTableCell>Reserverade platser</StyledTableCell>
                                         <StyledTableCell></StyledTableCell>
                                     </TableRow>
                                 </TableHead>
                                 <TableBody>
                                     {this.props.code.Implementations && this.props.code.Implementations.map((implementation, idx) => {
                                         return <TableRow>
                                             <StyledTableCell>{this.getProductName(implementation.productId)}</StyledTableCell>
                                             <StyledTableCell>{this.getProductVariantName(implementation.productId, implementation.productVariantId)}</StyledTableCell>
                                             <StyledTableCell>{implementation.type}</StyledTableCell>
                                             <StyledTableCell>{this.getImplementationValue(implementation)}</StyledTableCell>
                                             <StyledTableCell>{implementation.allowsEntryToFullRace ? "Ja" : "Nej"}</StyledTableCell>
                                             <StyledTableCell>{implementation.reservedPlaces ? implementation.reservedPlaces : ''}</StyledTableCell>
                                             <StyledTableCell align="right">
                                                 <EditIcon onClick={this.edit(implementation)} className={classes.icon} style={{ cursor: 'pointer' }} />
                                                 <DeleteIcon onClick={this.remove(implementation)} className={classes.icon} style={{ cursor: 'pointer' }} />
                                             </StyledTableCell>
                                         </TableRow>
                                     })}
                                 </TableBody>
                             </Table></TableContainer>

                     </CardContent>
                     <CardActionArea>
                         
                             <Button  onClick={() => {
                                 let impl = {} as CodeProductImplementation;
                                 impl.productId = this.props.products[0].id;
                                 impl.productVariantId=this.props.products[0].variants[0].Id;
                                 impl.productVariantPriceGroupId=this.props.products[0].variants[0].priceGroups[0].id;
                                 impl.startGroup='';
                                 impl.type="pricegroup";
                                 this.edit(impl)();
                             }}>Lägg till implementation</Button>
                         
                     </CardActionArea>
                 </Card>
             </Grid>
         </Grid>
         {this.state && this.state.addEdit &&
                this.renderEditImplementation()
            }
             {this.state && this.state.deleteConfirmation &&
                 this.renderConfirmationDialog()
             }
         </Fragment>
 
     }
     handleEditCancel =() => {
         this.setState({addEdit: null});
     }
     handleEditOk = () => {
        let ae = this.state.addEdit;
        this.props.save(ae);
         this.setState({addEdit:null});
     }

     renderEditImplementation() {
         let impl = this.state.addEdit;
         let product = null;
         let variant = null;
         if (impl.productId)
         {
            let prs = this.props.products.filter(x=>x.id==impl.productId);
            if (prs && prs.length > 0)
            {
                product = prs[0];
            }
         }
         if (impl.productVariantId && product)
         {
             let vars = product.variants.filter(x=>x.Id == impl.productVariantId);
             if (vars && vars.length > 0)
             {
                 variant = vars[0];
             }
         }

         return <Dialog
         disableBackdropClick
         disableEscapeKeyDown
         maxWidth="lg"
         aria-labelledby="confirmation-dialog-title"
         open={true}
     >
         <DialogTitle id="confirmation-dialog-title">{impl.id != null ? "Editera Implementation" : "Lägg till Implementation"}</DialogTitle>
         <DialogContent dividers>
             <Grid container>
             <Grid item xs={12}>
                 <Typography variant="body2"><b>Gäller för:</b></Typography>
             </Grid>
                 <Grid item xs={product.type=="race" ? 4:6}>
                     <FormControl>
                         <InputLabel>Produkt</InputLabel>
                            <Select label="Produkt" value={impl.productId} onChange={this.changeEditField('productId')}>
                                {this.props.products.map(pr => {
                                    return <MenuItem value={pr.id}>{pr.name}</MenuItem>
                                })}
                            </Select>
                    </FormControl>
                 </Grid>
                 <Grid item xs={product.type=="race" ? 4:6}>
                     {product && 
                     <FormControl>
                     <InputLabel>Produktvariant</InputLabel>
                    <Select label="Produktvariant" value={impl.productVariantId} onChange={this.changeEditField('productVariantId')}>
                        {product.variants.map(v => {
                            return <MenuItem value={v.Id}>{v.Name}</MenuItem>
                        })}
                    </Select>
                    </FormControl>
                    }
                 </Grid>
                 {product.type=="race" && this.state.curProduct &&
                 <Grid item xs={4}>
                         <FormControl>
                             <InputLabel shrink>Startled</InputLabel>
                             <Select displayEmpty label="Startled" value={impl.startGroup || ''} onChange={this.changeEditField('startGroup')}>
                                 <MenuItem value=''>Alla</MenuItem>
                                 {this.state.curProduct && this.state.curProduct.startGroups && this.state.curProduct.startGroups.map((sg) => {
                                     return <MenuItem value={sg.id}>{sg.name}</MenuItem>
                                 })}
                             </Select>
                         </FormControl>
                     </Grid>
                }
                 <Grid item xs={12} style={{marginTop:30, borderTop:'1px solid grey'}}>
                 <Typography variant="body2"><b>Värde:</b></Typography>
             </Grid>
                 <Grid item xs={6}>
                   
                     <FormControl>
                     <InputLabel>Typ av kod</InputLabel>
                    <Select label="Typ" value={impl.type} onChange={this.changeEditField('type')}>
                            <MenuItem value="value">Värde (kr)</MenuItem>
                            <MenuItem value="percent">Procent</MenuItem>
                            <MenuItem value="pricegroup">Prisgrupp</MenuItem>
                    </Select>
                    </FormControl>
                 </Grid>
                 <Grid item xs={6}>
                    {impl.type == "value" &&
                    <TextField fullWidth value={impl.value} label="Värde (kr)" placeholder="Värde (kr)" onChange={this.changeEditField('value')} />
                    }
                    {impl.type == "percent" &&
                    <TextField fullWidth value={impl.value} label="Värde (%)" placeholder="Värde (%)" onChange={this.changeEditField('value')} />
                    }
                    {impl.type == "pricegroup" &&                    
                    <FormControl>
                    <InputLabel>Prisgrupp</InputLabel>
                    <Select label="Prisgrupp" value={impl.productVariantPriceGroupId || ''} onChange={this.changeEditField('productVariantPriceGroupId')}>
                        {variant && variant.priceGroups&&variant.priceGroups.map(pg => 
                        {
                            return <MenuItem value={pg.id}>{pg.Name}</MenuItem>
                        })}
                    </Select>
                    </FormControl>
                    }                       
                     </Grid>  
                     <Grid item xs={6}>
                             <FormControlLabel label={"Kan användas i fulla lopp"}
                             control={<Checkbox value={impl.allowsEntryToFullRace} onChange={this.changeChkEditField('allowsEntryToFullRace')} />
                                }/>
                         </Grid>
                         <Grid item xs={6}>
                                 <TextField fullWidth value={impl.reservedPlaces} onChange={this.changeEditField('reservedPlaces')} label="Reservera antal platser" placeholder="Reservera antal platser"/>
                         </Grid>
            </Grid>
         </DialogContent>
         <DialogActions>
             <Button variant="contained" autoFocus onClick={this.handleEditCancel} color="primary">
                 Avbryt
       </Button>
             <Button variant="contained" onClick={this.handleEditOk} color="primary">
                 {this.props.code.id != null ? "Spara" : "Lägg till"}
       </Button>
         </DialogActions>
     </Dialog>;
     }
     
     createRandomCode() {
         let randowCodes = [
             'A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z',
             '1', '2', '3', '4', '5', '6', '7', '8', '9', '0',
         ];
         let code = "";
         for (let i = 0; i < 5 ; i++)
         {
             code += randowCodes[Math.floor(Math.random() * randowCodes.length)];
 
         }
 
         return "VL-" + code;
     }
 
     renderConfirmationDialog() {
         return <Dialog
             disableBackdropClick
             disableEscapeKeyDown
             maxWidth="xs"
             aria-labelledby="confirmation-dialog-title"
             open={true}
         >
             <DialogTitle id="confirmation-dialog-title">Är du säker på att du vill ta bort implementationen?</DialogTitle>
             <DialogActions>
                 <Button variant="contained" autoFocus onClick={this.handleDeleteCancel} color="primary">
                     Avbryt
           </Button>
                 <Button variant="contained" onClick={this.handleDeleteOk} color="primary">
                     Ja, ta bort
           </Button>
             </DialogActions>
         </Dialog>;
     }
     handleDeleteCancel = () => {
         let ae = this.state.deleteConfirmation;
         this.props.delete(ae);
         this.setState({ deleteConfirmation: null });
     }
 
     handleDeleteOk = () => {
        let ae = this.state.deleteConfirmation;
        this.props.delete(ae);
         this.setState({deleteConfirmation:null});
     }
 }
 
 
 const tableHeadStyles = ({ palette, spacing }: Theme) => createStyles({
     head: {
         background: palette.primary.main,
         color: palette.primary.contrastText,
     }
 });
 
 const StyledTableCell = withStyles(tableHeadStyles)(TableCell);
 
 
 const useStyles = ({ palette, spacing }: Theme) => createStyles({
     cardHeader: {
         background: palette.secondary.main,
         color: palette.secondary.contrastText,
         padding: 3
     },
     photo: {
         height: '30px',
         verticalAlign: 'middle',
         borderRadius: '10px'
     },
     icon: {
         verticalAlign: 'middle',
         cursor: 'pointer'
     },
     form: {
         '& > *': {
             margin: spacing(1),
             width: '25ch',
         },
         '& label.Mui-focused': {
             color: palette.secondary.main,
         },
         '& .MuiInput-underline:after': {
             borderBottomColor: palette.secondary.main,
         },
     }
 }
 );
 
 export default withStyles(useStyles)(CodeProducts);
 