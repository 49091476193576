/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import React from 'react';
import { withRouter, RouteChildrenProps } from 'react-router-dom';
import { Theme, createStyles, Grid, WithStyles, Card, CardHeader, CardContent, TextField, Button, withStyles, CardActionArea, FormControl } from '@material-ui/core';
import { ApiBackend } from '../../providers/apibackend';
import uuid from 'react-uuid';
import { Product } from '../../model/Product';
import { ProductVariant } from '../../model/ProductVariant';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import {Event} from '../../model/Event';

interface State {
    product: Product;
    loading: boolean;
    events : Event[];
}

/** Add Product -- Create a product
 */
class AddProduct extends React.Component<WithStyles & RouteChildrenProps, State> {

    state = { loading: true,events: null as Event[],  
        product: { EventId: null as string, IsArchived: false, id: uuid(),publicId:'', name: '', description :'', name_en:'', description_en : '', variants: [] as ProductVariant[], type:'other',active:false, PaymentRefPrefix:null as string, symplifyJourneyForTicket : null as string} as Product};

    constructor(props: any) {
        super(props);
    }

    componentDidMount(){
        let back = new ApiBackend();
        back.listEvents().then((events) => {
            
            let p = this.state.product;
            p.EventId = events && events.length > 0 ? events[0].id : null;
            this.setState({ events: events, loading:false, product: p });
        });
    }

    onChange = (prop: string) => (ev : React.ChangeEvent<HTMLInputElement>) => 
    {
        var e = this.state.product as any;
        e[prop] = ev.target.value;
        this.setState({product: e});
    }

    createProduct = () => {
        let back = new ApiBackend();
        back.createProduct(this.state.product).then((res) => {
            if (res)
            {
                this.props.history.push('/products/manage');            
            }
            else 
            {
                alert("Kunde inte skapa Product!");
            }
        });
    }

    render() {
        let { classes } = this.props;

        return <div>
            <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader className={classes.cardHeader} title="Lägg till produkt" />
                        <CardContent>
                            <form className={classes.form} noValidate autoComplete="off">
                                <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField style={{ flex: 1, margin: '0 3px' }} id="standard-basic" label="Namn" value={this.state.product.name} onChange={this.onChange('name')} fullWidth />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                    <FormControl className={classes.formControl} style={{margin:0}}>
                                            <InputLabel shrink>
                                                Produkttyp
                                            </InputLabel>
                                            <Select native value={this.state.product.type} onChange={this.onChange('type')} label="Produkttyp">
                                                {ApiBackend.getProductTypes().map((item) => {
                                                    return <option value={item.code}>{item.name}</option>
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField style={{ flex: 1, margin: '0 3px' }} id="standard-basic" value={this.state.product.description} onChange={this.onChange('description')} label="Beskrivning" fullWidth />
                                    </Grid>
                                     <Grid item xs={12} sm={6}>
                                    <FormControl className={classes.formControl} style={{margin:0}}>
                                            <InputLabel shrink>
                                                Tillhör Evenemang
                                            </InputLabel>
                                            <Select native value={this.state.product.EventId} onChange={this.onChange('EventId')} label="Evenemang">
                                                {this.state && this.state.events && this.state.events.map((event) => {
                                                    return <option value={event.id}>{event.name}</option>
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    
                                </Grid>
                            </form>
                        </CardContent>
                        <CardActionArea>
                            <Button onClick={this.createProduct}>Skapa produkt</Button>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid></div>;
    }
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
}
);


export default withStyles(useStyles)(withRouter(AddProduct));