import { FormControlLabel, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Select from '@material-ui/core/Select';
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import React, { Fragment } from 'react';
import { OrderItemOverview, Orderoverview } from '../../model/Orderoverview';
import { Product } from '../../model/Product';
import { ProductVariant } from '../../model/ProductVariant';
import { ProductOccation } from '../../model/ProductVariantOccation';
import { ProductVariantPriceGroup } from '../../model/ProductVariantPriceGroup';
import { ApiBackend } from '../../providers/apibackend';

interface state {
    products: Product[];
    selectedProduct : Product;
    selectedProductId: string;
    orderoverview: Orderoverview;    
    loading: boolean;
    autoUpdate : boolean;
    filterOccationFromDate : string;
    filterOccationToDate : string;
}

class OrderOverview extends React.Component<WithStyles,state> {

    m_timeout : NodeJS.Timeout;

    constructor(props : WithStyles) 
    {
        super(props);
        this.state={loading:false, products: null, selectedProduct : null, selectedProductId: null, orderoverview: null, autoUpdate: false, 
            filterOccationFromDate : moment().format("YYYY-MM-DD"), filterOccationToDate: moment().add(1,'M').format("YYYY-MM-DD")};
        let back = new ApiBackend();
        back.listProducts().then(async (products) =>{
            
            let product = await back.getProduct(products[0].id);
            let overview = await back.getOrderOverview(products[0].id);
            this.setState({products: products, selectedProduct: product, selectedProductId: product.id, orderoverview: overview, loading:false});
        });
    }

    componentDidMount() { 
        this.m_timeout = setTimeout(() => {this.handleRefresh();},30000);
    }
    componentWillUnmount() {
        clearTimeout(this.m_timeout);
    }

    handleRefresh = () => {
        if (this.state.autoUpdate)
        {
            this.refresh();
        }
        this.m_timeout = setTimeout(() => {this.handleRefresh();},30000);
    }

    onChangeProduct = async (ev: React.ChangeEvent<HTMLSelectElement>) => {
            this.setState({loading:true});
            let back = new ApiBackend();
            let productId = ev.target.value;
            let product = await back.getProduct(productId);
            let overview = await back.getOrderOverview(productId);
            this.setState({selectedProduct: product, selectedProductId: productId, orderoverview: overview, loading:false});
    }

    refresh = async () => {
        this.setState({loading:true});
            let back = new ApiBackend();
            let overview = await back.getOrderOverview(this.state.selectedProductId);
            this.setState({orderoverview: overview, loading:false});
    }

    toggleAutoUpdate = () => {
        this.setState({autoUpdate: !this.state.autoUpdate});
    }

    onChange = (prop : string) => (ev : React.ChangeEvent<HTMLInputElement>) => {
        let o = {} as any;
        o[prop] = ev.target.value;
        this.setState(o);
    }

    render() {
        let {classes} = this.props;

        let overviewGridItems : {item: OrderItemOverview, variant: ProductVariant, occ : ProductOccation, pg : ProductVariantPriceGroup}[] = [];
        if (this.state && this.state.selectedProduct)
        {
            this.state.selectedProduct.variants.map((variant) => {
                variant.priceGroups.map((pg) => {
                if (variant.occations)
                {
                    variant.occations.map((occ) => {
                        let items = this.state.orderoverview.items.filter(x=>x.variantId == variant.Id && x.priceGroupId == pg.id && x.occationId==occ.id);
                        let item = items != null && items.length > 0 ? items[0] : null;
                        overviewGridItems.push({item: item, variant: variant,pg:pg,occ:occ});
                    });
                }
                else 
                {
                    let items = this.state.orderoverview.items.filter(x=>x.variantId == variant.Id && x.priceGroupId == pg.id);
                    let item = items != null && items.length > 0 ? items[0] : null;
                    overviewGridItems.push({item: item, variant: variant,pg:pg,occ:null});
                }
            });
           
        })}
        overviewGridItems.sort((x,y) => {
            if (x.occ && y.occ) { 
                return x.occ.time < y.occ.time ? -1 : 1;
            } else { 
                return x.variant.Name < y.variant.Name ? -1 : 1;
            }
        });


        return <Fragment><Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
                {this.state.loading &&
                    <LinearProgress color="secondary" />
                }
                <Card>
                    <CardHeader className={classes.cardHeader} title={<Fragment><Typography variant="h5" style={{ display: 'inline' }}>Översikt försäljning</Typography></Fragment>} />
                    <CardContent>
                        {this.state && this.state.products && <Grid container className={classes.root} spacing={2}>
                            <Grid item xs={6}><Select native value={this.state.selectedProductId} onChange={this.onChangeProduct}>
                                {this.state.products.map(x=> {
                                    return <option value={x.id}>{x.name}</option>;
                                })}
                                </Select>
                                </Grid>
                                <Grid item xs={6}><Button variant="contained" onClick={this.refresh} style={{marginLeft: 1}}>Uppdatera</Button>
                                <FormControlLabel
                                    control={
                                    <Switch style={{marginLeft: 5}}
                                        checked={this.state.autoUpdate}
                                        onChange={this.toggleAutoUpdate}
                                        name="autoupdate"
                                        color="secondary"
                                    />
                                    }
                                    label="Auto uppdatering"
                                /></Grid>
                                <Grid item xs={12}><Typography variant="body2"><b>Filtrera tillfälle</b></Typography>
                                <TextField type="date" value={this.state.filterOccationFromDate} onChange={this.onChange("filterOccationFromDate")}/>
                                
                                <TextField type="date" value={this.state.filterOccationToDate} onChange={this.onChange("filterOccationToDate")}/></Grid>
                                <Grid item xs={5}><Typography variant="body2"><b>Variant</b></Typography></Grid>
                                                <Grid item xs={5}><Typography variant="body2"><b>Tillfälle</b></Typography></Grid>
                                                <Grid item xs={1}><Typography variant="body2"><b>Köpta</b></Typography></Grid>
                                                <Grid item xs={1}><Typography variant="body2"><b>Incheckade</b></Typography></Grid>
                                {overviewGridItems.map((item) => {
                                    if (!item.occ || (
                                        moment(item.occ.time).isAfter(moment(this.state.filterOccationFromDate)) &&
                                        moment(item.occ.time).isBefore(moment(this.state.filterOccationToDate +" 23:59:59"))))
                                    {
                                        
                                     return <Fragment>
                                     <Grid item xs={5}><Typography variant="body2">{item.variant.Name}-{item.pg.Name}</Typography></Grid>
                                     <Grid item xs={5}><Typography variant="body2">{item.occ ? item.occ.name : ""}</Typography></Grid>
                                     <Grid item xs={1}><Typography variant="body2">{item.item && item.item.numPurchased ? item.item.numPurchased : 0}</Typography></Grid>
                                     <Grid item xs={1}><Typography variant="body2">{item.item && item.item.numDelivered ? item.item.numDelivered : 0}</Typography></Grid>
                                     </Fragment>;
                                    }
                                })}     
                        </Grid>}
                        
                    </CardContent>
                </Card>
            </Grid></Grid></Fragment>
    }
}



const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
}
);
export default withStyles(useStyles)(OrderOverview);