/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

 import React, { ChangeEvent, Fragment } from 'react'
 import FirebaseContext from '../providers/Firebase/context';
 import { Card, CardHeader, CardContent, Theme, WithStyles, withStyles, Button, createStyles, Grid, CardActionArea, TableContainer, Paper, Table, TableHead, TableRow, TableBody, Hidden, TableCell, Dialog, DialogTitle, DialogActions, LinearProgress, Typography, MenuItem, Select, FormControlLabel, FormControl, InputLabel, Checkbox } from '@material-ui/core';
 import { withRouter, RouteComponentProps } from 'react-router-dom';
 import { ApiBackend } from '../providers/apibackend';

 import EditIcon from '@material-ui/icons/Edit';
 import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import { Product } from '../model/Product';
import { PointOfSale } from '../model/PointOfSale';
import { Settings } from '@material-ui/icons';
import { POSSetting } from '../model/POSSetting';



 interface State {
     loading : boolean;
     products : Product[];
     settings: POSSetting[],
     posPlaces: PointOfSale[];
     editSetting : POSSetting,
     deleteConfirmation : POSSetting;
     selProdAdd : string;
 
 }
 
 class ManagePOSPlaces extends React.Component<RouteComponentProps & WithStyles, State> {
     static contextType = FirebaseContext;
     constructor(props : RouteComponentProps & WithStyles)
     {
         super(props);
         this.state = {selProdAdd: null,posPlaces: null, loading: true, settings : null, editSetting:null, deleteConfirmation: null, products: null};
     }
 
     componentDidMount = async () =>  {
         this.setState({loading:true});
         let back = new ApiBackend();
         let setts = await back.getGeneral("pos");
         let prods = await back.listProducts();
         let posPlaces = await back.listPointOfSales();
         prods.sort((x,y) => x.name > y.name ? 1:-1);
         posPlaces.sort((x,y) => x.name > y.name ? 1:-1);
         Promise.all([setts,prods]).then(() => {
             this.setState({settings: setts, products:prods, posPlaces: posPlaces, loading:false});
         })
     }
 
     addSetting = () => {
         
         let j = {Id: null, Name:''} as POSSetting;
         this.setState({editSetting: j});
     }
 
     editSetting = (setting : POSSetting) => () =>{
         //this.props.history.push("/jobs/" + job.id);
         this.setState({editSetting: setting});
     }
     deleteSetting = (setting : POSSetting) => () =>{
         this.setState({deleteConfirmation: setting});
     }
 
     render() {
         let { classes } = this.props as any;
         return <Fragment><Grid container className={classes.root} spacing={2}>
             <Grid item xs={12}>
                <Typography variant="body2">Hantering av POS-platser. Konfigurerar POS-klienten med värdet i ID-fältet
                    </Typography>
                 <Card>
 
                     <CardHeader className={classes.cardHeader} title="POS Terminaler" />
                     <CardContent>
                     {this.state.loading &&
                         <LinearProgress color="secondary"/>
                         }
                         <TableContainer component={Paper}>
                             <Table size="small" aria-label="a dense table">
                                 <TableHead>
                                     <TableRow>
                                         <StyledTableCell>Namn</StyledTableCell>
                                         <StyledTableCell>ID</StyledTableCell>
                                         <StyledTableCell>Betalsätt (Kort/Kassa)</StyledTableCell>
                                         <StyledTableCell></StyledTableCell>
                                     </TableRow>
                                 </TableHead>
                                 <TableBody>
                                     {this.state && this.state.settings && this.state.settings.map((setting) => {
                                         let posName = "Swedbank Pay";
                                         if (setting.pointOfSale)
                                         {
                                            let pos = this.state.posPlaces.filter(x=>x.id == setting.pointOfSale);
                                            if (pos && pos.length > 0)
                                            {
                                                posName = pos[0].name;
                                            }
                                        }
                                         return <TableRow>
                                             <StyledTableCell>{setting.Name}</StyledTableCell>
                                             <StyledTableCell>{setting.Id}</StyledTableCell>
                                             <StyledTableCell>{posName}</StyledTableCell>
                                             <StyledTableCell align="right">
                                                 <EditIcon onClick={this.editSetting(setting)} className={classes.icon} style={{cursor:'pointer'}}  />
                                             <DeleteIcon onClick={this.deleteSetting(setting)} className={classes.icon} style={{cursor:'pointer'}} /></StyledTableCell>
                                         </TableRow>
                                     })}
                                 </TableBody>
                             </Table></TableContainer>
                     </CardContent>
                     <CardActionArea>
                         <Button onClick={this.addSetting}>Lägg till POS</Button>
                     </CardActionArea>
                 </Card>
             </Grid>
         </Grid>
         {this.state && this.state.deleteConfirmation && this.renderConfirmationDialog()}
         {this.state && this.state.editSetting && this.renderEditSetting()}
         </Fragment>;
     }

     onChange = (field: string) => (ev: React.ChangeEvent<HTMLInputElement>) => {
         let o = this.state.editSetting;

         if (field.indexOf(".") > 0) {
             let p = field.split(".");
             (o as any)[p[0]][p[1]] = ev.target.value;
         }
         else {
             (o as any)[field] = ev.target.value;
         }
        
        this.setState({
            editSetting: o
        });
     }

     
     renderEditSetting() {
        return this.renderSettingDialog(this.state.editSetting);
     }

     renderSettingDialog(object: POSSetting) {
         let {classes}= this.props;
         return <Dialog
         disableBackdropClick
         disableEscapeKeyDown
         maxWidth="xs"
         aria-labelledby="confirmation-dialog-title"
         open={true}
     ><Grid container className={classes.root} spacing={2}>
         <Grid item xs={12}>
             <Card>
                 <CardHeader className={classes.cardHeader} title={object.Id ? "Editera POS" : "Skapa POS"} />
                 <CardContent>
                     <form className={classes.form} noValidate autoComplete="off">
                         <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                             <Grid item xs={12}>
                                 <TextField style={{ flex: 1, margin: '0 3px' }} id="standard-basic" value={object.Name} onChange={this.onChange('Name')} label="Namn" fullWidth />
                             </Grid>
                             <Grid item xs={12}>
                                 <TextField style={{ flex: 1, margin: '0 3px' }} id="standard-basic" value={object.VAT} onChange={this.onChange('VAT')} label="Momssats (%)" fullWidth />
                             </Grid>
                             <Grid item xs={12}>
                                <FormControlLabel label="Visa checkin fönster" 
                                control={<Checkbox checked={object.showCheckin} onChange={(ev) => {
                                    let o = this.state.editSetting;
                                    o.showCheckin=ev.target.checked;
                                    this.setState({editSetting: o});
                                }}/>}/>
                             </Grid>
                             <Grid item xs={12}>
                                <FormControlLabel label="Visa snabbkassa" 
                                control={<Checkbox checked={object.showCheckout} onChange={(ev) => {
                                    let o = this.state.editSetting;
                                    o.showCheckout=ev.target.checked;
                                    this.setState({editSetting: o});
                                }}/>}/>
                             </Grid>
                             <Grid item xs={12}>
                                 <FormControl>
                                     <InputLabel shrink>Betalsätt (Kort/Kassa)</InputLabel>
                                <Select fullWidth displayEmpty value={object.pointOfSale??""} onChange={this.onChange('pointOfSale')}>
                                    <MenuItem value={""}>Swedbank Pay</MenuItem>
                                     {this.state.posPlaces.map((pos) => {
                                         return <MenuItem value={pos.id}>{pos.name}</MenuItem>;
                                     })}
                                     </Select>
                                 
                                 </FormControl>
                             </Grid>
                             <Grid item xs={12}><b>Produkter i snabbkassa</b></Grid>
                             <Grid item xs={12}>
                                 {object.ProductList && object.ProductList.map((p,idx) => {
                                     let prod = this.state.products.filter(x=>x.id == p);
                                     if (prod && prod.length > 0)
                                     {
                                         return <div style={{padding: "2px 10px",background: "#efefef", marginBottom:2}} >{prod[0].name}
                                         <DeleteIcon style={{float:'right', cursor:'pointer'}} onClick={() => {
                                             let p = this.state.editSetting;
                                             p.ProductList.splice(idx,1);
                                             this.setState({editSetting: p});
                                         }}/></div>;
                                     }
                                     else
                                     {
                                         return <div style={{padding: "2px 10px",background: "#efefef", marginBottom:2}} >Okänd produkt {p}
                                         <DeleteIcon style={{float:'right', cursor:'pointer'}} onClick={() => {
                                             let p = this.state.editSetting;
                                             p.ProductList.splice(idx,1);
                                             this.setState({editSetting: p});
                                         }}/></div>;
                                     }
                                 })
                                 }
                             </Grid>
                             <Grid item xs={12}>
                                 <Select fullWidth value={this.state.selProdAdd} onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                     this.setState({selProdAdd: ev.target.value});
                                 }}>
                                     {this.state.products.map((prod) => {
                                         return <MenuItem value={prod.id}>{prod.name}</MenuItem>;
                                     })}
                                     </Select>
                                 <Button onClick={() => {
                                     let p = this.state.editSetting;
                                     if (!p.ProductList)
                                     {
                                         p.ProductList=[];
                                     }
                                     p.ProductList.push(this.state.selProdAdd);
                                     this.setState({editSetting: p});

                                     }}>Lägg till</Button>
                             </Grid>
                         </Grid>
                         
                     </form>
                 </CardContent>
             </Card>
         </Grid>
     </Grid> <DialogActions>
                 <Button variant="contained" autoFocus onClick={this.handleAddCancel} color="primary">
                     Avbryt
           </Button>
                 <Button variant="contained" onClick={this.updateSetting} color="primary">
                     {object.Id ?  "Spara" : "Skapa"}
           </Button>
             </DialogActions></Dialog>;
     }

     updateSetting = () => {
        this.setState({loading: true})
        let back = new ApiBackend();
        back.putGeneral("pos",this.state.editSetting).then(()  =>
        {
           this.setState({editSetting:null});
           back.getGeneral("pos").then((settings) => {
               this.setState({loading:false, settings: settings})
           });
        })
    }

     handleAddCancel = () => {
         this.setState({editSetting: null});
     }


     renderConfirmationDialog() {
         return <Dialog
             disableBackdropClick
             disableEscapeKeyDown
             maxWidth="xs"
             aria-labelledby="confirmation-dialog-title"
             open={true}
         >
             <DialogTitle id="confirmation-dialog-title">Är du säker på att du vill ta bort inställningen?</DialogTitle>
             <DialogActions>
                 <Button variant="contained" autoFocus onClick={this.handleDeleteCancel} color="primary">
                     Avbryt
           </Button>
                 <Button variant="contained" onClick={this.handleDeleteOk} color="primary">
                     Ja, ta bort
           </Button>
             </DialogActions>
         </Dialog>;
     }
     handleDeleteCancel = () => {
         this.setState({ deleteConfirmation: null});
     }
 
     handleDeleteOk = () => {
         this.setState({loading:true});
         var back = new ApiBackend();
         back.deleteGeneral("pos/" + this.state.deleteConfirmation.Id).then((res) => {
             if (res){
                 back.getGeneral("pos").then((settings) => {
                     this.setState({deleteConfirmation: null, settings: settings, loading:false });
                 });
             }
             else 
             {
                 alert("Kunde inte ta bort!");
             }
         })
         
     }
 }
 
 
 
 const useStyles = ({ palette, spacing }: Theme) => createStyles({
     cardHeader: {
         background: palette.secondary.main,
         color: palette.secondary.contrastText,
         padding: 3
     },
     photo: {
         height: '30px',
         verticalAlign: 'middle',
         borderRadius: '10px'
     },
     root: {
 
     },
     form: {
         '& > *': {
             margin: spacing(1),
             width: '25ch',
         },
         '& label.Mui-focused': {
             color: palette.secondary.main,
         },
         '& .MuiInput-underline:after': {
             borderBottomColor: palette.secondary.main,
         },
     }
 }
 );
 
 const tableHeadStyles = ({ palette, spacing }: Theme) => createStyles({
     head: {
         background: palette.primary.main,
         color: palette.primary.contrastText,
     }
 });
 
 const StyledTableCell = withStyles(tableHeadStyles)(TableCell);
 
 export default withRouter(withStyles(useStyles)(ManagePOSPlaces));
 