import * as yup from "yup";

// Tillfälligt lånat från https://github.com/dockwa/simple-react-validator
const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

const validationSchema = yup.object().shape({
    firstName: yup.string()
        .required("Obligatoriskt"),
    lastName: yup.string()
        .required("Obligatoriskt"),
    email: yup.string()
        .email("Felaktig epost-adress")
        .required("Obligatoriskt"),
    phoneNumber: yup.string()
        .matches(phoneRegExp, "Felaktigt telefonnummer")
        .when("mobileNumber", {
            is: (mobileNumber: string) => !mobileNumber || mobileNumber.length === 0,
            then: yup.string().required("Obligatoriskt").nullable(),
            otherwise: yup.string().nullable()
        }),
    mobileNumber: yup.string()
        .matches(phoneRegExp, "Felaktigt telefonnummer")
        .when("phoneNumber", {
            is: (phoneNumber: string) => !phoneNumber || phoneNumber.length === 0,
            then: yup.string().required("Obligatoriskt").nullable(),
            otherwise: yup.string().nullable()
        }),
}, [ [ "mobileNumber", "phoneNumber" ] ]);

export default validationSchema;
