import { Grid, MenuItem, WithStyles } from "@material-ui/core";
import { FormikErrors, FormikTouched } from "formik";
import moment from "moment";
import React from "react";
import { Entry } from "../../../model/Entry";
import { Product } from "../../../model/Product";
import FormCbxField from "../../Common/FormCbxField";
import FormReadonlyField from "../../Common/FormReadOnlyField";
import FormSelectField from "../../Common/FormSelectField";
import FormTextField from "../../Common/FormTextField";
import EntryPersonCard from "./EntryPersonCard";

interface IProps {
    errors: FormikErrors<Entry>;
    touched: FormikTouched<Entry>;
    values: Entry;
    entryProduct: Product;
    handleBlur: {
        (e: React.FocusEvent<any>): void;
        <T = string | any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
    };
    handleChange: {
        (e: React.ChangeEvent<any>): void;
        <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
    };
    onShowPerson: () => void;
}

class EditEntryFormContent extends React.Component<WithStyles & IProps> {

    render(): JSX.Element {
        const { classes, entryProduct } = this.props;
        const isDisqualified = this.props.values.dsq;        

        const dateValueFormatter = (isoDate: string): string => {
            return moment(isoDate).format("YYYY-MM-DD HH:mm");
        }

        return (
            <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                <Grid item xs={4}>
                    <FormTextField {...this.props}
                        propName="bibtext"
                        label="Nummerlapp"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormSelectField {...this.props}
                        propName="startgroup"
                        label="Startgrupp"
                    >
                        {entryProduct.startGroups.map((x, idx) => {
                            return <MenuItem key={idx} value={x.id}>{x.name}</MenuItem>;
                        })}
                    </FormSelectField>
                </Grid>
                <Grid item xs={4}>
                    <FormTextField {...this.props}
                        propName="clubName"
                        label="Klubb"
                    />
                </Grid>


                <Grid item xs={4}>
                    <FormTextField {...this.props}
                        propName="prePackingGroup"
                        label="Packningsgrupp"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormTextField {...this.props}
                        disabled
                        propName="ageClassKey"
                        label="Åldersklass"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormTextField {...this.props}
                        propName="team"
                        label="Lag"
                    />
                </Grid>


                <Grid item xs={4}>
                    <FormCbxField {...this.props}
                        // disabled
                        propName="dsq"
                        label="Diskvalificerad"
                    />
                </Grid>
                <Grid item xs={8}>
                    <FormTextField {...this.props}
                        // disabled={!isDisqualified}
                        propName="dsqReason"
                        label="Anledning"
                    />
                </Grid>

                <Grid item xs={4}>
                    <FormReadonlyField {...this.props}
                        valueFormatter={dateValueFormatter}
                        propName="created"
                        label="Skapad datum"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormReadonlyField {...this.props}
                        valueFormatter={dateValueFormatter}
                        propName="updated"
                        label="Uppdaterad datum"
                    />
                </Grid>

                <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12}>
                        <EntryPersonCard {...this.props} expanded={true} />
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default EditEntryFormContent;
