import { Theme, Card, CardActions, CardContent, CardHeader, createStyles, Grid, MenuItem, Table, TableCell, TableContainer, TableHead, TableRow, withStyles, WithStyles, TableBody } from "@material-ui/core";
import { FieldArray, FormikProps } from "formik";
import React from "react";
import { Campaign, CampaignProduct } from "../../model/Campaign";
import { Product } from "../../model/Product";
import { ApiBackend } from "../../providers/apibackend";
import FormSelectField from "../Common/FormSelectField";
import FormTextField from "../Common/FormTextField";
import DeleteIcon from '@material-ui/icons/Delete';
import FormCbxField from "../Common/FormCbxField";
import FormDateField from "../Common/FormDateField";
import ProductVariantPriceGroupSelector, { IProductVariantPriceGroupSelectorResult } from "../Common/ProductVariantPriceGroupSelector";
import moment from 'moment';

interface IProps {
    products: Product[];
}

class EditCampaignFormContent extends React.Component<WithStyles & IProps & FormikProps<Campaign>> {

    private getProductName(id: string) {
        let p = this.props.products.filter(x => x.id == id);
        if (p && p.length > 0) {
            return p[0].name;
        }
        return "";
    }

    private getProductVariantName(productId: string, id: string) {
        let p = this.props.products.filter(x => x.id == productId);
        if (p && p.length > 0) {
            let pv = p[0].variants.filter(x => x.Id == id);
            if (pv && pv.length > 0) {
                return pv[0].Name;
            }
        }
        return "";
    }

    private getProductVariantPriceGroupName(productId: string, variantId: string, id: string) {
        let p = this.props.products.filter(x => x.id == productId);
        if (p && p.length > 0) {
            let pv = p[0].variants.filter(x => x.Id == variantId);
            if (pv && pv.length > 0) {
                let pg = pv[0].priceGroups.filter(x => x.id == id);
                if (pg && pg.length > 0) {
                    return pg[0].Name;
                }
            }
        }
        return "";
    }

    private getNonArchivedProducts() : Product[] {
        const products = this.props.products.filter(product => !product.IsArchived);
        
        return products;
    }

    render(): JSX.Element {
        const { classes } = this.props;
        const campaign = this.props.values;

        return (
            <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                <Grid item xs={12}>
                    <FormTextField {...this.props}
                        propName="name"
                        label="Namn"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormTextField {...this.props}
                        multiline={true}
                        propName="description"
                        label="Beskrivning"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormTextField {...this.props}
                        multiline={true}
                        propName="description_en"
                        label="Beskrivning (engelska)"
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormSelectField {...this.props}
                        propName="type"
                        label="Typ"
                    >
                        {ApiBackend.getCampaignTypes().map((campaignType, idx) => (
                            <MenuItem key={idx} value={campaignType.code}>
                                {campaignType.name}
                            </MenuItem>
                        ))}
                    </FormSelectField>
                </Grid>
                <Grid item xs={2}>
                    <FormDateField {...this.props}
                        propName="campaignStart"
                        label="Kampanj aktiv från"
                        valueFormatter={() => campaign.campaignStart ? moment(campaign.campaignStart).format("YYYY-MM-DD") : ''}
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormDateField {...this.props}
                        propName="campaignEnd"
                        label="Kampanj aktiv till"
                        valueFormatter={() => campaign.campaignEnd ? moment(campaign.campaignEnd).format("YYYY-MM-DD") : ''}
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormCbxField {...this.props}
                        propName="isActive"
                        label="Kampanj aktiv"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FieldArray
                        name="products"
                        render={productHelper => {
                            return (
                                <Card>
                                    <CardHeader
                                        className={classes.cardHeader}
                                        title="Gäller för produkter" />
                                    { campaign.products && campaign.products.length > 0 && <CardContent>
                                        <TableContainer>
                                            <Table size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell>Produkt</StyledTableCell>
                                                        <StyledTableCell>Produktvariant</StyledTableCell>
                                                        <StyledTableCell>Prisgrupp</StyledTableCell>
                                                        <StyledTableCell></StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {campaign.products && campaign.products.map((product: CampaignProduct, idx) => {
                                                        return <TableRow key={idx}>
                                                            <StyledTableCell>{this.getProductName(product.productId)}</StyledTableCell>
                                                            <StyledTableCell>{this.getProductVariantName(product.productId, product.productVariantId)}</StyledTableCell>
                                                            <StyledTableCell>{this.getProductVariantPriceGroupName(product.productId, product.productVariantId, product.productVariantPriceGroupId)}</StyledTableCell>
                                                            <StyledTableCell align="right">
                                                                <DeleteIcon onClick={() => { productHelper.remove(idx); } } className={classes.icon} style={{ cursor: 'pointer' }} />
                                                            </StyledTableCell>
                                                        </TableRow>;
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </CardContent> }
                                    <CardActions>
                                        <Grid container className={classes.root} style={{ width: '100%' }}>
                                            <Grid item xs={12}>
                                                <ProductVariantPriceGroupSelector
                                                    headerText="Lägg till produkt"
                                                    buttonText="Lägg till"
                                                    products={this.getNonArchivedProducts()}
                                                    includePriceGroups={true}
                                                    onChange={(result: IProductVariantPriceGroupSelectorResult) => {
                                                        productHelper.push({
                                                            productId: result.product.id,
                                                            productVariantId: result.productVariant.Id,
                                                            productVariantPriceGroupId: result.productVariantPriceGroup.id
                                                        });
                                                    } } />
                                            </Grid>
                                        </Grid>
                                    </CardActions>
                                </Card>
                            );
                        }} />
                </Grid>
            </Grid>
        );
    }
}

const tableHeadStyles = ({ palette }: Theme) => createStyles({
    head: {
        background: palette.primary.main,
        color: palette.primary.contrastText,
    }
});

const StyledTableCell = withStyles(tableHeadStyles)(TableCell);

export default EditCampaignFormContent;