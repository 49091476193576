export class Profile {
    id : string;
    firstName: string;
    lastName : string;
    email: string;
    phone: string;
    addressLine1: string;
    postalCode: string;
    city: string;
    country: string;
    optInMarketing: boolean;
    isComplete: boolean;
    accessRoles : string[];

    constructor(profile : Profile)
    {
        this.id = profile.id;
        this.firstName = profile.firstName;
        this.lastName = profile.lastName;
        this.email = profile.email;
        this.phone = profile.phone;
        this.addressLine1 = profile.addressLine1;
        this.postalCode = profile.postalCode;
        this.city = profile.city;
        this.country = profile.country;
        this.optInMarketing = profile.optInMarketing;
        this.isComplete = profile.isComplete;
        this.accessRoles = profile.accessRoles;
    }

    isInRole(role:string) : boolean {
        return this.accessRoles && (this.accessRoles.includes('admin') || this.accessRoles.includes(role));
    }
}