/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

 import React from 'react';
 import { Theme, createStyles, Grid, WithStyles, Card, CardHeader, CardContent, TextField, Button, withStyles, CardActionArea, LinearProgress, Container, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
 import { ApiBackend } from '../../providers/apibackend';
 import FormControlLabel from '@material-ui/core/FormControlLabel';
 import Checkbox from '@material-ui/core/Checkbox';
import { Club } from '../../model/Club';
import { cloneDeep } from 'lodash'; 
 
 
 interface Props {
    club : Club;
    close: () => void;
 }
 
 interface State {
     club : Club;
     loading : boolean;
     haveChanges:boolean;
 }
 
 
 class EditClub extends React.Component<WithStyles & Props, State> {
 
     state = {
         club: null as Club,
         loading: false,
         haveChanges: false
     };

     constructor(props: WithStyles & Props) {
         super(props);

         this.state = {club: cloneDeep(props.club), loading: false, haveChanges: false};
     }

     onChange = (prop: string) => (ev : React.ChangeEvent<HTMLInputElement>) => 
     {
         var e = this.state.club as any;
         e[prop] = ev.target.value;
         this.setState({club: e,haveChanges:true});
     }
     onChangeChk = (prop: string) => (ev : React.ChangeEvent<HTMLInputElement>) => 
     {
         var e = this.state.club as any;
         e[prop] = ev.target.checked;
         this.setState({club: e,haveChanges:true});
     }

     saveClub = async () => {
        const back = new ApiBackend();
        this.setState({loading:true});
        await back.saveClub(this.state.club);
        this.setState({loading: false});
        this.props.close();
     }

     getDistricts = () => {
        return [{Code: "SDF01-34", Name:"Blekinge Skidförbund"},
        {Code: "SDF02-34", Name:"Bohuslän-Dals Skidförbund"},
        {Code: "SDF03-34", Name:"Dalarnas Skidförbund"},
        {Code: "SDF04-34", Name:"Gotlands Skidförbund"},
        {Code: "SDF05-34", Name:"Gästriklands Skidförbund"},
        {Code: "SDF06-34", Name:"Göteborgs Skidförbund"},
        {Code: "SDF07-34", Name:"Hallands Skidförbund"},
        {Code: "SDF08-34", Name:"Hälsinglands Skidförbund"},
        {Code: "SDF09-34", Name:"Jämtland-Härjedalens Skidförbund"},
        {Code: "SDF10-34", Name:"Medelpads Skidförbund"},
        {Code: "SDF11-34", Name:"Norrbottens Skidförbund"},
        {Code: "SDF13-34", Name:"Skånes Skidförbund"},
        {Code: "SDF14-34", Name:"Smålands Skidförbund"},
        {Code: "SDF15-34", Name:"Stockholms Skidförbund"},
        {Code: "SDF16-34", Name:"Södermanlands Skidförbund"},
        {Code: "SDF17-34", Name:"Upplands Skidförbund"},
        {Code: "SDF18-34", Name:"Värmlands Skidförbund"},
        {Code: "SDF19-34", Name:"Västerbottens Skidförbund"},
        {Code: "SDF20-34", Name:"Västergötlands Skidförbund"},
        {Code: "SDF21-34", Name:"Västmanlands Skidförbund"},
        {Code: "SDF22-34", Name:"Ångermanlands Skidförbund"},
        {Code: "SDF12-34", Name:"Örebro Läns Skidförbund"},
        {Code: "SDF23-34", Name:"Östergötlands Skidförbund"}];
     }
     

     render() {
         let { classes } = this.props;
         return <Grid container className={classes.root} spacing={0}>
         <Grid item xs={12}>            
             <Card>
                 <CardHeader className={classes.cardHeader} title={"Hantera: " + this.state.club.name} />
                 <CardContent>
                 {this.state.loading &&
                         <LinearProgress color="secondary"/>
                         }
                     <form className={classes.form} noValidate autoComplete="off">
                             <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                                 <Grid item xs={6}>
                                     <TextField style={{ flex: 1, margin: '0 3px' }} id="standard-basic" disabled={this.state.club.id != null} label="Namn" value={this.state.club.name} onChange={this.onChange('name')} fullWidth />
                                 </Grid>
                                 <Grid item xs={6} sm={6}>
                                     <TextField type="number" style={{ flex: 1, margin: '0 3px' }} id="standard-basic" disabled={this.state.club.id != null} value={this.state.club.extRef} onChange={this.onChange('extRef')} label="Ext. ref" fullWidth />
                                 </Grid>
                                 <Grid item xs={6} sm={6} >
                                     <TextField style={{ flex: 1, margin: '0 3px' }} id="standard-basic" disabled value={this.state.club.groupkey} onChange={this.onChange('groupkey')} label="Distr. kod" fullWidth />
                                 </Grid>
                                 <Grid item xs={6} sm={6} >
                                 <FormControl fullWidth>
                                    <InputLabel >Distrikt</InputLabel>
                                    <Select disabled={this.state.club.id != null} label="Distrikt" 
                                    value={this.state.club.groupkey ?? ''}
                                    onChange={(ev) => {
                                        let cl = this.state.club;
                                        const distr = this.getDistricts().filter(x=>x.Code == ev.target.value);
                                        if (distr && distr.length > 0)
                                        {
                                            cl.groupkey = distr[0].Code;
                                            cl.groupname = distr[0].Name;
                                        }
                                        this.setState({club: cl});
                                        }}>

                                        <MenuItem value=''></MenuItem>
                                        {this.getDistricts().map((distr,idx) => {
                                            return <MenuItem value={distr.Code} key={distr.Code}>{distr.Name}</MenuItem>;
                                        })}
                                     </Select>
                                     </FormControl>
                                 </Grid>

                                 <Grid item xs={12} sm={6} >
                                     <FormControlLabel
                                         control={<Checkbox checked={this.state.club.isArchived ?? false} onChange={this.onChangeChk('isArchived')} name="Arkiverad" />}
                                         label="Arkiverad"
                                     />
                                 </Grid>
                                 <Grid item xs={12} sm={6} >
                                     <TextField multiline rows={5} style={{ flex: 1, margin: '0 3px' }} id="standard-basic" value={this.state.club.comment ??''} label="Anteckningar" fullWidth />
                                 </Grid>
                         </Grid>
                     </form>
                 </CardContent>
                 <CardActionArea component="a">
                     <Button variant='contained' color="secondary" disabled={!this.state.haveChanges} onClick={this.saveClub}>Spara ändringar</Button>
                     <Button variant='contained' style={{marginLeft: 5}} color="secondary" onClick={() => {this.props.close()}}>Avbryt</Button>
                 </CardActionArea>
             </Card>
             </Grid>
            
     </Grid>;
     }
 }
 
 const useStyles = ({ palette, spacing }: Theme) => createStyles({
     cardHeader: {
         background: palette.secondary.main,
         color: palette.secondary.contrastText,
         padding: 3
     },
     photo: {
         height: '30px',
         verticalAlign: 'middle',
         borderRadius: '10px'
     },
     root: {
 
     },
     form: {
         '& > *': {
             margin: spacing(1),
             width: '25ch',
         },
         '& label.Mui-focused': {
             color: palette.secondary.main,
         },
         '& .MuiInput-underline:after': {
             borderBottomColor: palette.secondary.main,
         },
     }
 }
 );
 
 
 export default withStyles(useStyles)(EditClub);